import { Component,Inject, ViewChild, ElementRef, AfterViewInit,OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import { Observable, of } from 'rxjs';

import { DbconnectService } from 'src/app/dbconnect.service';

import{ jqxSplitterComponent } from 'jqwidgets-ng/jqxsplitter';
import { jqxExpanderComponent } from 'jqwidgets-ng/jqxexpander';
import { jqxTreeComponent } from 'jqwidgets-ng/jqxtree';
import { jqxPanelComponent } from 'jqwidgets-ng/jqxpanel';
import { jqxListBoxComponent } from 'jqwidgets-ng/jqxlistbox';
import { jqxInputComponent } from 'jqwidgets-ng/jqxinput';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxMenuComponent } from 'jqwidgets-ng/jqxmenu';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxSliderComponent } from 'jqwidgets-ng/jqxslider';

import { MsgDialogComponent } from 'src/app/utils/msg-dialog/msg-dialog.component';

import * as myGlobal from '../../global';

@Component({
  selector: 'app-comment-quest-man',
  templateUrl: './comment-quest-man.component.html',
  styleUrls: ['./comment-quest-man.component.scss']
})
export class CommentQuestManComponent implements OnInit {
  @ViewChild('jqxgrid', { static: false }) jqxgrid!: jqxGridComponent;

  questList : any[] = [];

  currQuestId : string = "0"; // gia tri id cua food hien tai

  constructor(private dbConnect : DbconnectService,
    private route: ActivatedRoute,
    private _location: Location,
    private router: Router,
    public dialog: MatDialog
  ) {
    
    
  }

  ngOnInit(): void  {

  }
  ngAfterViewInit(): void 
  {
    this.createButtons();
    this.reloadGridData();
  }

  gridsource: any = {
    datatype: "json",
    datafields: [
        { name: 'cq_id', type: 'string' },
        { name: 'cq_cat_id', type: 'string' },
        { name: 'cq_content', type: 'string' },
        { name: 'answer', type: 'string' },
        { name: 'created_date', type: 'string' },      
        { name: 'updated_date', type: 'string' },              
        { name: 'created_by', type: 'string' },
        { name: 'states', type: 'string' },
        { name: 'approved_date', type: 'string' },
        { name: 'user_id', type: 'string' },
        { name: 'votes', type: 'string' },
        { name: 'stars', type: 'string' },
    ],
    localdata: this.questList,
    // updaterow: (rowid, rowdata, commit): void => {
    //     commit(true);
    // }
  };
  
	getWidth() : any {
		if (document.body.offsetWidth < 850) {
			return '90%';
		}
		
		return 850;
	}

    dataAdapter: any = new jqx.dataAdapter(this.gridsource);
    cellsrenderer = (row: number, columnfield: string, value: string | number, defaulthtml: string, 
              columnproperties: any, rowdata: any): string => {
        
        if (!this.questList[row]["approved_date"]) {
     //   if (value < 20) {
            return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + '; color: #ff0000;">' + value + '</span>';
        }
        else {
            return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + '; color: #008000;">' + value + '</span>';
        }
    };

    gridcolumns: any[] = [
      { text: 'Id', datafield: 'cq_id', width: 50 ,editable:false },
      { text: 'Nhóm', datafield: 'cq_cat_id', width: 50 ,editable:false, cellsrenderer: this.cellsrenderer},
      { text: 'Câu hỏi', datafield: 'cq_content', width: 380 , cellsrenderer: this.cellsrenderer},
      { text: 'Trả lời', datafield: 'answer', width: 320 , cellsrenderer: this.cellsrenderer},      
      { text: 'Ngày tạo', datafield: 'created_date', width: 250 ,editable:false},
      { text: 'Ngày cập nhật', datafield: 'updated_date', width: 150 ,editable:false},
      { text: 'Ngày duyệt', datafield: 'approved_date', width: 180,editable:false , cellsrenderer: this.cellsrenderer},
      { text: 'Người cập nhật', datafield: 'user_id', width: 120 ,editable:false},
      { text: 'Số votes', datafield: 'votes' ,editable:false},
      { text: 'Stars', datafield: 'stars' ,editable:false},

    ];


    columngroups: any[] =
    [
        { text: 'Product Details', align: 'center', name: 'ProductDetails' }
    ];

    
  createButtonsContainers(statusbar: any): void {
    let buttonsContainer = document.createElement('div');
    buttonsContainer.style.cssText = 'overflow: hidden; position: relative; margin: 5px;';
    let addButtonContainer = document.createElement('div');
    let approveButtonContainer = document.createElement('div');
    let deleteButtonContainer = document.createElement('div');
    let reloadButtonContainer = document.createElement('div');
    let searchButtonContainer = document.createElement('div');
    addButtonContainer.id = 'addButton';
    approveButtonContainer.id = 'aproveButton';
    deleteButtonContainer.id = 'deleteButton';
    reloadButtonContainer.id = 'reloadButton';
  //  searchButtonContainer.id = 'searchButton';
    addButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
    approveButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
    deleteButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
    reloadButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
    searchButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
    buttonsContainer.appendChild(addButtonContainer);
    buttonsContainer.appendChild(approveButtonContainer);    
    buttonsContainer.appendChild(deleteButtonContainer);
    buttonsContainer.appendChild(reloadButtonContainer);
  //  buttonsContainer.appendChild(searchButtonContainer);
    statusbar[0].appendChild(buttonsContainer);
  }

  createButtons(): void {
    let addButtonOptions = {
        width: 80, height: 25, value: 'Trả lời',
        imgSrc: '/assets/media/icons/add.png',
        imgPosition: 'center', textPosition: 'center',
        textImageRelation: 'imageBeforeText'
    }
    let addButton = jqwidgets.createInstance('#addButton', 'jqxButton', addButtonOptions);

    let approveButtonOptions = {
      width: 80, height: 25, value: 'Duyệt',
      imgSrc: '/assets/media/icons/edit_small_normal_hover.png',
      imgPosition: 'center', textPosition: 'center',
      textImageRelation: 'imageBeforeText'
   }
    let approveButton = jqwidgets.createInstance('#aproveButton', 'jqxButton', approveButtonOptions);

    let deleteButtonOptions = {
        width: 80, height: 25, value: 'Xoá ',
        imgSrc: '/assets/media/icons/close.png',
        imgPosition: 'center', textPosition: 'center',
        textImageRelation: 'imageBeforeText'
    }
    let deleteButton = jqwidgets.createInstance('#deleteButton', 'jqxButton', deleteButtonOptions);
    let reloadButtonOptions = {
        width: 80, height: 25, value: 'Đọc mới ',
        imgSrc: '/assets/media/icons/refresh.png',
        imgPosition: 'center', textPosition: 'center',
        textImageRelation: 'imageBeforeText'
    }
    let reloadButton = jqwidgets.createInstance('#reloadButton', 'jqxButton', reloadButtonOptions);
    let searchButtonOptions = {
        width: 80, height: 25, value: 'Tìm kiếm ',
        imgSrc: '/assets/media/icons/search.png',
        imgPosition: 'center', textPosition: 'center',
        textImageRelation: 'imageBeforeText'
    }
    //let searchButton = jqwidgets.createInstance('#searchButton', 'jqxButton', searchButtonOptions);

    // add new answer.
    addButton.addEventHandler('click', (event: any): void => {    
  //    alert("HTestt"  );
      let selectedrowindex = this.jqxgrid.getselectedrowindex();
      this.jqxgrid.begincelledit(selectedrowindex, 'answer');
    });

    approveButton.addEventHandler('click', (event: any): void => {      
      let selectedrowindex = this.jqxgrid.getselectedrowindex();
      //let row = this.jqxgrid.getdatainformation().rowscount;
      
      let id = this.jqxgrid.getrowid(selectedrowindex);
      let row = this.questList[parseInt(id)];
      if (!row) {
        return;
      }

      this.openDialog("Xác nhận","Có chắc chắn muốn xác nhận câu hỏi ? : " + 
            row["cq_content"] + "(id:" + row["cq_id"] +")").subscribe(result => {
        if (result=="1") {
          this.dbConnect.approveCommentQuestion(
            row["cq_id"],
            {},
            ).subscribe({next:(result) => {
                        
               alert('Xác nhận câu hỏi thành công ');

               this.reloadGridData();
               this.dbConnect.sysLog('CommentQuestManComponent','Submit','Approve Comment= ' +  row["cq_content"] + "(id:" + row["cq_id"] +")");
            
            }, error:err => {
              console.error('Observer got an error: ' + err);
              alert('Lỗi kết nối createButtons! ');
            }});                         
        }               
      });
      
    });

    reloadButton.addEventHandler('click', (event: any): void => {
      this.reloadGridData();
    });


    // delete selected row.
    deleteButton.addEventHandler('click', (event: any): void => {
        let selectedrowindex = this.jqxgrid.getselectedrowindex();
        
        let id = this.jqxgrid.getrowid(selectedrowindex);
        let row = this.questList[parseInt(id)];
        if (!row) {
          return;
        }
  
        //if ( this.currFoodId=="-1") return;
        this.openDialog("Xác nhận","Có chắc chắn muốn xóa câu hỏi này? " + 
            row["cq_content"] + "(id:" + row["cq_id"] +")").subscribe(result => {
          if (result=="1") {
            this.dbConnect.delEntryById('comment_question',row["cq_id"])
              .subscribe({next:(data: []) => {
                    this.reloadGridData();
                    alert("Xóa dữ liệu thành công!");
                    //this.jqxgrid.deleterow(id);
                    this.dbConnect.sysLog('CommentQuestManComponent','Submit','Delete Comment id=' + row["cq_id"]);
                  }, error:err => {
                    console.error('Observer got an error: ' + err);
                    alert('Lỗi kết nối mạng! ');
                  }});                              
          }               
        });
        
    });
    // // reload grid data.
    // reloadButton.addEventHandler('click', (event: any): void => {
    //     //this.jqxgrid.source(this.getAdapter());
    // });
    // search for a record.
    // searchButton.addEventHandler('click', (event: any): void => {
    //     //  this.findWindow.open();
    //     //  this.findWindow.move(220, 120);
    // });
  
   
  }

  openDialog(title:string, msg: string): Observable<any>{
    const dialogRef = this.dialog.open(MsgDialogComponent, {
      width: '250px',
      data: {title, msg}
    });
    return dialogRef.afterClosed();
  }

  rowselect(event: any): void {
    // event arguments.
    let args = event.args;
    // selected row.
    let row = event.args.row;
    // update inputs.
 //  alert(row);
  }; 

  onCellEndEdit(event: any): void {
    
    let args = event.args;
    let columnDataField = args.datafield;
    let rowIndex = args.rowindex;
    let cellValue = args.value;
    let oldValue = args.oldvalue;
    
    let body :any ={};
    body[columnDataField] = cellValue;
    console.log(body);
    //console.log(this.questList[parseInt(rowIndex)]["cq_id"]);

    this.dbConnect.updateCommentQuest(
      this.questList[parseInt(rowIndex)]["cq_id"],
      body,
      ).subscribe({next:(result) => {
                  
         // alert('Cập nhật bài viết thành công ');
         // this._location.back();
         //window.location.reload();
         this.reloadGridData();
         this.dbConnect.sysLog('CommentQuestManComponent','Submit','Answer Comment= ' +  this.questList[parseInt(rowIndex)]["cq_content"] );
      
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối onCellEndEdit! ');
      }});
  }; 


  reloadGridData() {
    this.questList = [];
    //this.hasData =false;
  //  console.debug("Call:reloadGridData");
    this.dbConnect
        .getCommentQuest()
        .subscribe({next:(data: []) => {
                //console.debug("Get data from back end:" + JSON.stringify(data))
                data.forEach((e) => {
                    // let item: any = {
                    //     food_name: e["food_name"] , //+ (" (id:" + e["food_id"] + ")"),
                    //     food_id: e["food_id"],  
                    //     food_desc: e["food_desc"],
                    //     food_type: e["food_typ_id"],
                    //     created_date: e["created_date"],
                    //     food_image: e["food_image"],
                    //     state: e["state"],
                    //     created_by: e["created_by"],
                    //     user_id: e["user_id"],
                    //     votes: e["votes"],
                    //     category_name : catName,
                    // } ;
                    
                    //item = this.addChildrenNode(item);
                    
                    this.questList.push(e );
                   // this.hasData = true;

                   // this.currFoodId = "-1";
                });

                this.gridsource.localdata = this.questList;
                //console.debug("FoodList: " + JSON.stringify(this.foodList));

                this.jqxgrid.updatebounddata();
            }, error:err => {
            console.error('Observer got an error: ' + err);
            alert('Lỗi kết nối mạng! ');
        }});
  }
}
