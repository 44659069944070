'use strict';

export const api_endpoint = '/api/'; // strapi

export const x_access_key =  '2764efc296a235ab33c3def';

export const image_lib_base = '/assets/media/';

export const api_imgupload_url: string = api_endpoint + 'upload';
export const api_avatarupload_url: string =  api_endpoint + 'avatar_upload/';
export const api_avatarget_url: string = api_endpoint + 'avatar_get/';
export const api_avatarmod_url: string = api_endpoint + 'avatar_mod/';

// Kieu loai category.type
export const CAT_TYPE_FOOD = 1;
export const CAT_TYPE_DRUG = 2;
export const CAT_TYPE_NEWS = 3;
export const CAT_TYPE_VIDEO = 4;

export enum SCREEN_SIZE {
    XS,
    SM,
    MD,
    LG,
    XL
  }
