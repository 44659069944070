<div class="container-lg">
    <div class="row">
        <h2 class="text-primary">Tỷ lệ người dùng có triệu chứng</h2>
    </div> 
    <div class="row">
        <div class="col-6">
            <ngx-charts-bar-horizontal
            [view]="[700, 400]"
            [scheme]="colorScheme2"
            [results]="sympton_data"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xSymptonLabel"
            [yAxisLabel]="ySymptonLabel"
            legendTitle='Chú thích'
           >
          </ngx-charts-bar-horizontal>
        </div>
        <div class="col-6">
            
        </div>
    </div>
    <hr>
    <div class="row">
        <h2 class="text-primary">Biều đồ tần suất triệu chứng GERD trước và sau điều trị</h2>
    </div> 
    <div class="row">
        <div class="col-6">
            <ngx-charts-bar-vertical-stacked
                [view]="[700, 400]"
                [scheme]="colorScheme1"
                [results]="sympton_multi"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xMultiLabel"
                [yAxisLabel]="yMultiLabel"
                [animations]="animations"
                legendTitle='Chú thích'
            >
            </ngx-charts-bar-vertical-stacked>
        </div>
        <div class="col-6">
            
        </div>
    </div>

    <hr>
    <div class="row">
        <h2 class="text-primary">Biều đồ tần suất triệu chứng GERD trước và sau điều trị</h2>
    </div> 
    <div class="row">
        <div class="col-6">
            <ngx-charts-bar-vertical-2d
                [view]="[700, 400]"
                [scheme]="colorScheme11"
                [results]="sympton_tansuat"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xFreqLabel"
                [yAxisLabel]="yFreqLabel"
                [barPadding]=2
                [legendTitle]="legendTitle"
                >
                </ngx-charts-bar-vertical-2d>
        </div>
        <div class="col-6">
            
        </div>
    </div>

    
</div>

