import { Component, OnInit, ViewChild,AfterViewInit } from '@angular/core';
import { jqxEditorComponent } from 'jqwidgets-ng/jqxeditor';
import { jqxDropDownListComponent  } from 'jqwidgets-ng/jqxdropdownlist';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DbconnectService } from 'src/app/dbconnect.service';
import {Location} from '@angular/common';
import { jqxInputComponent } from 'jqwidgets-ng/jqxinput';
import { NewsData } from 'src/app/models/news';


@Component({
  selector: 'app-notify-editor',
  templateUrl: './notify-editor.component.html',
  styleUrls: ['./notify-editor.component.scss']
})
export class NotifyEditorComponent implements OnInit {

  valueUrl!: String;
  
  createNew = true;
  notifyId : string = "0";
  @ViewChild('notifyTitle', { static: false }) notifyTitle!: jqxInputComponent;
  @ViewChild('notifyDes', { static: false }) notifyDes!: jqxInputComponent;   

  constructor(private dbConnect : DbconnectService,
    private _location: Location) { }
  hasData : boolean = true;
  //newsList : NewsData[] = [];
  dataAdapter : any[] = [];
  //allNewsCat : any[] = [];  // Chua tat ca cac category
  currNotifyId : string = "0"; // gia tri id cua drug hien tai
  currNotifyData : any;
  //currCatId : number = -1;
  //new_list:any = [];
  loadData  :any;
  //dataSource = new MatTableDataSource<NewsData>(this.new_list);

  ngOnInit(): void {
  }
  getWidth() : any {
    // if (document.body.offsetWidth < 850) {
    // 	return '90%';
    // }		
    return window.innerWidth - 20;
  }
  
  getHeight() : any {
    // if (document.body.offsetWidth < 850) {
    // 	return '90%';
    // }		
    return window.innerHeight - 50;
  }
  
  ngAfterViewInit(): void 
  {
      
  }
  
  onAddNotify(){
    //alert("21432132");
    var body =  {
      title: this.notifyTitle.val(),
      status: 1,
      descriptions:this.notifyDes.val()      
    };
    alert(body);
    if (this.createNew) { 
      this.dbConnect.addNotify(
      body
      ).subscribe({next:(result) => {
                  
          alert('Thêm thông báo thành công! ' );
         // this._location.back();
         this.dbConnect.sysLog('NotifyEditorComponent','submit','Add Notify Data= ' +  this.notifyTitle.val());
      }, error:err => {
        console.error('Observer got an error: ' + err);
       // alert('Lỗi kết nối addNewsCategory! ');
      }});
      //this.userProfileService.sysLog('FilterQuestionComponent','submit','Add Quest= ' +  this.userForm.controls.quest.value);
    } else {
      this.dbConnect.updateNotify(
        this.notifyId,
        body
        ).subscribe({next:(result) => {
                    
            alert('Cập nhật thông báo thành công ');
            this._location.back();
            this.dbConnect.sysLog('NotifyEditorComponent','submit','Update Notify Data= ' +  this.notifyTitle.val());
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối NotifyEditorComponent! ');
        }});
        //this.dbConnect.sysLog('FilterQuestionComponent','submit','Update Quest= ' +  this.userForm.controls.category_name.value);
    }
  }
 

}
