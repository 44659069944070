import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import * as myGlobal from '../../global'; 
import { DbconnectService } from 'src/app/dbconnect.service';
//import {checkTimeOut} from '../utils';
import {Router} from '@angular/router';
import { AuthenticationService } from 'src/app/authentication.service';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.css'], 
})
export class ProfilePictureComponent implements OnInit {

  public uploader!: FileUploader;
  public hasDragOver = false;

  img_data!: string;
  json_obj!: object;
  orgId!: number;

  @Input()
  public up4RegisterMode = true;

  @Input()
  public editmode = true;

  @Input()
  public smallsize = true;

  @Input()
  public url = '';

  @Input()
  public oldUserid = 0;

  //private avatarId = 0;

  @Output()
  private urlChange = new EventEmitter();

  @Output() 
  private avatarIdChanged = new EventEmitter<number>();

  getHttpOptions() {
    let data = JSON.parse(localStorage.getItem('currentUser')+"");

    // this.headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'auth-key': myGlobal.call_api_key ,
    //   'org_id' :  data.org_id,
    //   'token'   : data.token,
    // });

    this.orgId = data.org_id;
  
    // this.options = { headers: this.headers };

    return  ;//this.options;
  }

  getUserId() {
    if (this.authenticationService.currentUserValue)
      return this.authenticationService.currentUserValue.user_id;
    else 
      return -1;
  }

  constructor(
    private dbConnect:DbconnectService,
    private router: Router,
    private authenticationService: AuthenticationService
    ) {

   
  }

  // private updateImage(userID) {
  //   this.rest.getUserAvatar(userID).subscribe((data) => {
  //     this.jsonobj = JSON.parse(data[0].content );      
  //   }, (err) => {
  //     console.log(err);
  //   });
  // }

  public fileOver(e: any): void {
    this.hasDragOver = e;
  }

  ngOnInit() {

    if (this.up4RegisterMode) {
      this.uploader = new FileUploader({
        url: myGlobal.api_avatarupload_url + this.getUserId(), 
        disableMultipart: false,
        autoUpload: true
      });
   } else {
      this.uploader = new FileUploader({
        url: myGlobal.api_avatarmod_url + this.getUserId(), 
        disableMultipart: false,
        autoUpload: true
      });

   }

   console.log("DEBUG: up4RegisterMode=" + this.up4RegisterMode);
    this.uploader.response.subscribe(res => {
      // Upload returns a JSON with the image ID
      this.url = myGlobal.api_avatarget_url + JSON.parse(res).id;
      this.avatarIdChanged.emit(JSON.parse(res).id);
      this.urlChange.emit(this.url);
      //this.updateImage(JSON.parse(res).id);
      console.log("Return: " + res);
      this.img_data = JSON.parse(res).avata_img64;
      //this.getUserAvatar(this.getUserId()+"");

    });

    //console.log("DEBUG: up4RegisterMode=" + this.up4RegisterMode);
    console.log("DEBUG: oldid=" + this.oldUserid);
    if (!this.up4RegisterMode && this.oldUserid>0 ) {
      this.url = myGlobal.api_avatarget_url + this.oldUserid;
      this.getUserAvatar(this.oldUserid+"");
    }
  }

  getUserAvatar(userId:string): void {

    if (this.authenticationService.currentUserValue)
      this.img_data = this.authenticationService.currentUserValue.avata_img64;

    // this.dbConnect.getUserProfile(userId)//.getUserAvatar(userId)
    //     .subscribe((data:any) => {
    //       //if (!checkTimeOut(data,this.router)) {return;}
    //       this.json_obj = data;
    //       this.img_data = data['imgdata'];
    //       //console.log('getUserAvatar return data');
    //       console.log('getUserAvatar return for  profile-pic:'+ JSON.stringify(data));

    //     });
  }
}
