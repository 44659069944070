<div class="container-fluid">
  <div class="row">
    <div class="col-8">
      <h1 mat-dialog-title>Chọn ảnh trong thư viện</h1>
    </div>
    <div class="col-4">
      <button mat-button [mat-dialog-close]="selectImage" cdkFocusInitial class="bg-success text-white">Select</button>
      &nbsp;
      <button mat-button [mat-dialog-close]="0" class="bg-danger text-white" >Cancel</button>
    </div>  
  </div>
  <div class="row">
    <div class="col-8">      
      <jqxInput #filter [height]="30" width="100%" [placeHolder]="'Nhập ảnh cần tìm'" [minLength]="1" >
        
      </jqxInput>
    </div>
    <div class="col-4">
      <button mat-button class="bg-primary text-white" (click)="onFilterClick()">Search</button>
    </div>
  </div>

</div>
 <jqxGrid #jqxgrid 
  (onRowselect)="rowselect($event)"  
  (onCelldoubleclick)="Celldoubleclick($event)"
  [height]="'100%'" [width]="'100%'" 
  [columnsresize]="true" 
  [showtoolbar]="false"   
  [pageable]="true"
  [autoheight]="true" 
  [sortable]="true" 
  [columns]="columns" 
  [source]="griddataAdapter" 
  [rowsheight]="50"
  [pagesize]="6"
  [editable]="false"
  [enabletooltips]="true"
  [pagesizeoptions]="['5','10', '20', '30']"
  ></jqxGrid>
