<div class="wrapper fadeInDown">
  
    <div id="formContent">
      <!-- Tabs Titles -->
     <div class="fadeIn first">
        <img src="/assets/login2.png" id="icon" alt="User Icon" width="120" height="120" />
      </div>
      
      Login
      <!-- Login Form -->
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <!-- input type="text" id="login" class="fadeIn second" name="login" placeholder="login" -->
        <!-- input type="password" id="password" class="fadeIn third" name="login" placeholder="password" -->

        <div class="form-group">
          <!-- label for="username">Username</label -->
          <input type="text" formControlName="username" class="fadeIn second" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" placeholder="Enter username" />
          <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
              <div *ngIf="f.username.errors.required">Username is required</div>
          </div>
      </div>
      <div class="form-group">
          <!-- label for="password">Password</label -->
          <input type="password" formControlName="password" class="fadeIn third" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Enter password"/>
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
          </div>
      </div>


        <input type="submit" class="fadeIn fourth" value="Log In">
      </form>
      <font size="2"><p style="color:red">{{status}}</p></font>
      <!-- Remind Passowrd -->
      <div id="formFooter">
        <a class="underlineHover" href="#">Forgot Password?</a>
      </div>
  
    </div>

  </div>