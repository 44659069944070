import { NgModule,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FoodManComponent } from './gerd-monitor/food-man/food-man.component';
import { CatTreeComponent } from './utils/cat-tree/cat-tree.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { LayoutModule } from '@angular/cdk/layout';


import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import {MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs'; 
import {MatRadioModule} from '@angular/material/radio';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import {ReactiveFormsModule , FormsModule}  from '@angular/forms'
import { MatInputModule } from "@angular/material/input";
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';

import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSortModule } from "@angular/material/sort";
import {MatDatepickerModule} from '@angular/material/datepicker'; 



import {MatListModule} from '@angular/material/list';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SizeDetectorComponent } from './size-detector/size-detector.component';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxExpanderModule } from 'jqwidgets-ng/jqxexpander';
import { jqxTreeModule } from 'jqwidgets-ng/jqxtree';
import { jqxPanelModule } from 'jqwidgets-ng/jqxpanel';
import { jqxListBoxModule } from 'jqwidgets-ng/jqxlistbox';
import { jqxSplitterModule } from 'jqwidgets-ng/jqxsplitter';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { jqxMenuModule } from 'jqwidgets-ng/jqxmenu';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxSliderModule } from 'jqwidgets-ng/jqxslider';
import { jqxEditorModule } from 'jqwidgets-ng/jqxeditor';
import { jqxDataTableModule }   from 'jqwidgets-ng/jqxdatatable';

import { LoginFormComponent } from './login-form/login-form.component';
import { UploadFileComponent } from './utils/upload-file/upload-file.component';
import { FileUploadModule } from 'ng2-file-upload';
import { UploadImageComponent } from './utils/upload-image/upload-image.component';
import { CategoryDetailComponent } from './utils/category/category-detail.component';
//import { MessageDialog  } from 'src/app/utils/message-dialog/message-dialog';
import { MsgDialogComponent } from './utils/msg-dialog/msg-dialog.component';

import { ImageGalleryComponent } from './utils/image-gallery/image-gallery.component';
import { FoodDetailComponent } from './gerd-monitor/food-detail/food-detail.component';
import { FilterQuestionComponent } from './question/filter-question/filter-question.component';
import { FilterQuestionManComponent } from './question/filter-question-man/filter-question-man.component';
import { CommentQuestManComponent } from './comment/comment-quest-man/comment-quest-man.component';
import { NewsEditorComponent } from './gerd-news/news-editor/news-editor.component';
import { jqxComboBoxModule }   from 'jqwidgets-ng/jqxcombobox';
import { NewsListComponent } from './gerd-news/news-list/news-list.component';

import { SystemLogComponent } from './system/system-log/system-log.component';
import { UserInfoComponent } from './system/user-info/user-info.component';
import { UserProfileComponent ,FinishModifyDialog} from './system/user-profile/user-profile.component';
import { UserListComponent } from './system/user-list/user-list.component';
import { ChangePasswordComponent } from './system/change-password/change-password.component';
import { ProfilePictureComponent } from './system/profile-picture/profile-picture.component';
import { CatSelectorComponent } from './utils/cat-selector/cat-selector.component';
import { DrugManComponent } from './gerd-monitor/drug-man/drug-man.component';
import { PatientManComponent } from './patient/patient-man/patient-man.component';
import { DrugDetailComponent } from './gerd-monitor/drug-detail/drug-detail.component';
import { RptCommonComponent } from './report/rpt-common/rpt-common.component';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { RptGERDQComponent } from './report/rpt-gerdq/rpt-gerdq.component';
import { RptSymptonComponent } from './report/rpt-sympton/rpt-sympton.component';
import { RptDrugUsageComponent } from './report/rpt-drug-usage/rpt-drug-usage.component';
import { VideoListComponent } from './gerd-news/video-list/video-list.component';
import { NotifyListComponent } from './gerd-news/notify-list/notify-list.component';
import { RptExportComponent } from './report/rpt-export/rpt-export.component';
import { ExpGerdComponent } from './report/exp-gerd/exp-gerd.component';
import { ExpDailyComponent } from './report/exp-daily/exp-daily.component';
import { ExpFoodComponent } from './report/exp-food/exp-food.component';
import { ExpDrugComponent } from './report/exp-drug/exp-drug.component';
import { NotifyEditorComponent } from './gerd-news/notify-editor/notify-editor.component';
import { ImageSelectorComponent } from './utils/image-selector/image-selector.component';
import { ImageQuickViewerComponent } from './utils/image-quick-viewer/image-quick-viewer.component';
import { ImageManComponent } from './utils/image-man/image-man.component';
import { ImageUploadComponent } from './utils/image-upload/image-upload.component';
import { ImageUploadPreviewComponent } from './utils/image-upload-preview/image-upload-preview.component';


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    AppComponent,
    FoodManComponent,
    CatTreeComponent,
    DashboardComponent,
    SizeDetectorComponent,
  //  MessageDialog,

    LoginFormComponent,
    UploadFileComponent,
    UploadImageComponent,
    CategoryDetailComponent,
    MsgDialogComponent,
    ImageGalleryComponent,
    FoodDetailComponent,
    FilterQuestionComponent,
    FilterQuestionManComponent,
    CommentQuestManComponent,
    NewsEditorComponent,
    NewsListComponent,
    
    
    SystemLogComponent,
    UserInfoComponent,
    UserProfileComponent,
    UserListComponent,
    ChangePasswordComponent,
    ProfilePictureComponent,
    CatSelectorComponent,
    DrugManComponent,
    PatientManComponent,
    DrugDetailComponent,
    RptCommonComponent,
    RptGERDQComponent,
    RptSymptonComponent,
    RptDrugUsageComponent,
    VideoListComponent,
    NotifyListComponent,
    RptExportComponent,
    ExpGerdComponent,
    ExpDailyComponent,
    ExpFoodComponent,
    ExpDrugComponent,
    NotifyEditorComponent,
    ImageSelectorComponent,
    ImageQuickViewerComponent,
    ImageManComponent,
    ImageUploadComponent,
    ImageUploadPreviewComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    LayoutModule,
    SwiperModule,

    MatDialogModule,
    MatTabsModule,
    
    
    MatRadioModule,
    ReactiveFormsModule, FormsModule,
    HttpClientModule, CommonModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    
    MatTooltipModule,
    // NgxMatDatetimePickerModule, 
    // NgxMatTimepickerModule,
    MatDatepickerModule,
    MatDatepickerModule, 

    MatListModule,

    NgxChartsModule,
    
    //MatTableModule,
    //MatPaginatorModule,
    MatExpansionModule,
    MatSortModule,
    MatProgressSpinnerModule,
    FontAwesomeModule,
    FileUploadModule,

    jqxGridModule ,
    jqxExpanderModule,
    jqxTreeModule,
    jqxPanelModule,
    jqxListBoxModule,
    jqxSplitterModule,
    jqxButtonModule,
    jqxInputModule,
    jqxMenuModule,
    jqxWindowModule,
    jqxDropDownListModule,
    jqxSliderModule,
    jqxEditorModule,
    jqxComboBoxModule,
    jqxDataTableModule,
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
