export class User {
    public user_id!: number;
    public user_name!: string;
    public password!: string;
    public first_name!: string;
    public last_name!: string;
    public token!: string;
    public roles!: string;
    public telephone!: string;
    public email!: string;
    public avata_img64!: string;
    
    user:any;
    //webAppRoles : string;

    constructor() {
    
        this.clear();       
    };

    public clear() {
        this.user_id = 1;
        this.user_name = "unknown";
        this.password= "";
        this.first_name="unknown";
        this.last_name= "unknown";
        this.token= "";
        this.roles= "guest";
        this.telephone="" ;  
        this.roles = "user";
    }
    
}