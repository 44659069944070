

<head>
    <meta http-equiv=Content-Type content="text/html; charset=utf-8">
    <meta name=ProgId content=Excel.Sheet>
    <meta name=Generator content="Microsoft Excel 15">
    <link rel=File-List href="exp_food.fld/filelist.xml">
    <style id="exp_food_9991_Styles">
    <!--table
        {mso-displayed-decimal-separator:"\,";
        mso-displayed-thousand-separator:"\.";}
    @page
        {margin:.75in .7in .75in .7in;
        mso-header-margin:0in;
        mso-footer-margin:0in;}
    tr
        {mso-height-source:auto;}
    col
        {mso-width-source:auto;}
    br
        {mso-data-placement:same-cell;}
    .style0
        {mso-number-format:General;
        text-align:general;
        vertical-align:bottom;
        white-space:nowrap;
        mso-rotate:0;
        mso-background-source:auto;
        mso-pattern:auto;
        color:black;
        font-size:11.0pt;
        font-weight:400;
        font-style:normal;
        text-decoration:none;
        font-family:Arial;
        mso-generic-font-family:auto;
        mso-font-charset:0;
        border:none;
        mso-protection:locked visible;
        mso-style-name:Normal;
        mso-style-id:0;}
    td
        {mso-style-parent:style0;
        padding-top:1px;
        padding-right:1px;
        padding-left:1px;
        mso-ignore:padding;
        color:black;
        font-size:11.0pt;
        font-weight:400;
        font-style:normal;
        text-decoration:none;
        font-family:Arial;
        mso-generic-font-family:auto;
        mso-font-charset:0;
        mso-number-format:General;
        text-align:general;
        vertical-align:bottom;
        border:none;
        mso-background-source:auto;
        mso-pattern:auto;
        mso-protection:locked visible;
        white-space:nowrap;
        mso-rotate:0;}
    .xl65
        {mso-style-parent:style0;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;}
    .xl66
        {mso-style-parent:style0;
        font-weight:700;
        font-family:Arial, sans-serif;
        mso-font-charset:0;}
    .xl67
        {mso-style-parent:style0;
        font-weight:700;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        text-align:center;
        border:.5pt solid windowtext;
        background:yellow;
        mso-pattern:yellow none;}
    .xl68
        {mso-style-parent:style0;
        font-weight:700;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        border:.5pt solid windowtext;
        background:#B6DDE8;
        mso-pattern:#B6DDE8 none;}
    .xl69
        {mso-style-parent:style0;
        font-weight:700;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        border:.5pt solid windowtext;
        background:#FBD4B4;
        mso-pattern:#FBD4B4 none;}
    .xl70
        {mso-style-parent:style0;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        vertical-align:middle;
        border-top:none;
        border-right:.5pt solid black;
        border-bottom:.5pt solid black;
        border-left:.5pt solid black;
        white-space:normal;}
    .xl71
        {mso-style-parent:style0;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        mso-number-format:"\[ENG\]\[$-409\]d\\-mmm\\-yy";
        vertical-align:middle;
        border-top:none;
        border-right:.5pt solid black;
        border-bottom:.5pt solid black;
        border-left:.5pt solid black;
        white-space:normal;}
    .xl72
        {mso-style-parent:style0;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        vertical-align:middle;
        border-top:none;
        border-right:none;
        border-bottom:.5pt solid black;
        border-left:.5pt solid black;
        white-space:normal;}
    .xl73
        {mso-style-parent:style0;
        color:windowtext;
        font-family:Arial, sans-serif;
        mso-font-charset:0;
        border-top:none;
        border-right:.5pt solid black;
        border-bottom:.5pt solid black;
        border-left:none;}
    .xl74
        {mso-style-parent:style0;
        color:windowtext;
        font-family:Arial, sans-serif;
        mso-font-charset:0;
        border-top:none;
        border-right:none;
        border-bottom:.5pt solid black;
        border-left:none;}
    .xl75
        {mso-style-parent:style0;
        color:windowtext;
        font-family:Arial, sans-serif;
        mso-font-charset:0;
        border:.5pt solid windowtext;}
    .xl76
        {mso-style-parent:style0;
        font-weight:700;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        border:.5pt solid windowtext;}
    -->
    </style>
    </head>
    
    <body link=blue vlink=blue>
    <button type="button" (click)="export()" class="btn btn-success"><i class="far fa-file-excel"  ></i> Export</button>

    <!--[if !excel]>&nbsp;&nbsp;<![endif]-->
    <!--The following information was generated by Microsoft Excel's Publish as Web
    Page wizard.-->
    <!--If the same item is republished from Excel, all information between the DIV
    tags will be replaced.-->
    <!----------------------------->
    <!--START OF OUTPUT FROM EXCEL PUBLISH AS WEB PAGE WIZARD -->
    <!----------------------------->
    
    <div id="exp_food_9991" align=center x:publishsource="Excel">
    
    <table border=0 cellpadding=0 cellspacing=0 width=2755 style='border-collapse:
     collapse;table-layout:fixed;width:2070pt' id="ExportTable">
     <col width=97 style='mso-width-source:userset;mso-width-alt:3114;width:73pt'>
     <col width=119 style='mso-width-source:userset;mso-width-alt:3797;width:89pt'>
     <col width=109 style='mso-width-source:userset;mso-width-alt:3498;width:82pt'>
     <col width=133 style='mso-width-source:userset;mso-width-alt:4266;width:100pt'>
     <col width=125 style='mso-width-source:userset;mso-width-alt:4010;width:94pt'>
     <col width=136 style='mso-width-source:userset;mso-width-alt:4352;width:102pt'>
     <col width=152 style='mso-width-source:userset;mso-width-alt:4864;width:114pt'>
     <col width=265 style='mso-width-source:userset;mso-width-alt:8490;width:199pt'>
     <col width=173 style='mso-width-source:userset;mso-width-alt:5546;width:130pt'>
     <col width=127 style='mso-width-source:userset;mso-width-alt:4053;width:95pt'>
     <col width=208 style='mso-width-source:userset;mso-width-alt:6656;width:156pt'>
     <col width=101 span=2 style='width:76pt'>
     <col width=101 span=9 style='width:76pt'>
     <tr height=20 style='mso-height-source:userset;height:15.0pt'>
      <td height=20 width=97 style='height:15.0pt;width:73pt'></td>
      <td width=119 style='width:89pt'></td>
      <td width=109 style='width:82pt'></td>
      <td class=xl66 width=133 style='width:100pt'>FOOD REPORT</td>
      <td width=125 style='width:94pt'></td>
      <td width=136 style='width:102pt'></td>
      <td width=152 style='width:114pt'></td>
      <td width=265 style='width:199pt'></td>
      <td width=173 style='width:130pt'></td>
      <td width=127 style='width:95pt'></td>
      <td width=208 style='width:156pt'></td>
      <td width=101 style='width:76pt'></td>
      <td width=101 style='width:76pt'></td>
      <td width=101 style='width:76pt'></td>
      <td width=101 style='width:76pt'></td>
      <td width=101 style='width:76pt'></td>
      <td width=101 style='width:76pt'></td>
      <td width=101 style='width:76pt'></td>
      <td width=101 style='width:76pt'></td>
      <td width=101 style='width:76pt'></td>
      <td width=101 style='width:76pt'></td>
      <td width=101 style='width:76pt'></td>
     </tr>
     <tr height=20 style='height:15.0pt'>
      <td height=20 colspan=22 style='height:15.0pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td colspan=6 height=19 class=xl67 style='height:14.25pt'>Thông tin cá nhân</td>
      <td class=xl68 style='border-left:none'>Thức ăn gây trào ngược</td>
      <td class=xl68 style='border-left:none'>&nbsp;</td>
      <td class=xl69 style='border-left:none'>Danh mục đồ ăn cá nhân</td>
      <td class=xl69 style='border-left:none'>&nbsp;</td>
      <td class=xl69 style='border-left:none'>&nbsp;</td>
      <td colspan=11 style='mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 class=xl76 style='height:14.25pt;border-top:none'>ID</td>
      <td class=xl76 style='border-top:none;border-left:none'>Họ và tên</td>
      <td class=xl76 style='border-top:none;border-left:none'>Email</td>
      <td class=xl76 style='border-top:none;border-left:none'>Năm sinh</td>
      <td class=xl76 style='border-top:none;border-left:none'>Giới tính</td>
      <td class=xl76 style='border-top:none;border-left:none'>Ngày khảo sát</td>
      <td class=xl76 style='border-top:none;border-left:none'>Food1_xuathien</td>
      <td class=xl76 style='border-top:none;border-left:none'>Food1_mucdo</td>
      <td class=xl76 style='border-top:none;border-left:none'>Nên dùng</td>
      <td class=xl76 style='border-top:none;border-left:none'>Nên tránh</td>
      <td class=xl76 style='border-top:none;border-left:none'>Trung lập</td>
      <td colspan=11 style='mso-ignore:colspan'></td>
     </tr>
     <tr height=217 style='mso-height-source:userset;height:163.5pt'>
      <td height=217 class=xl70 width=97 style='height:163.5pt;width:73pt'>&nbsp;</td>
      <td class=xl70 width=119 style='border-left:none;width:89pt'>&nbsp;</td>
      <td class=xl70 width=109 style='border-left:none;width:82pt'>&nbsp;</td>
      <td class=xl70 width=133 style='border-left:none;width:100pt'>&nbsp;</td>
      <td class=xl70 width=125 style='border-left:none;width:94pt'>&nbsp;</td>
      <td class=xl71 width=136 style='border-left:none;width:102pt'>&nbsp;</td>
      <td colspan=2 class=xl72 width=417 style='border-right:.5pt solid black;
      border-left:none;width:313pt'>&nbsp;</td>
      <td colspan=3 class=xl72 width=508 style='border-right:.5pt solid black;
      border-left:none;width:381pt'>&nbsp;</td>
      <td colspan=11 style='mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 style='height:14.25pt'></td>
      <td class=xl65></td>
      <td colspan=4 style='mso-ignore:colspan'></td>
      <td class=xl65></td>
      <td colspan=15 style='mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 style='height:14.25pt'></td>
      <td class=xl65></td>
      <td colspan=4 style='mso-ignore:colspan'></td>
      <td class=xl65></td>
      <td colspan=15 style='mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 style='height:14.25pt'></td>
      <td class=xl65></td>
      <td colspan=4 style='mso-ignore:colspan'></td>
      <td class=xl65></td>
      <td colspan=15 style='mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=22 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=22 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=22 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=22 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=22 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=22 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=22 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=22 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=22 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=266 style='mso-height-source:userset;height:199.5pt;mso-xlrowspan:
      14'>
      <td height=266 colspan=22 style='height:199.5pt;mso-ignore:colspan'></td>
     </tr>
    
     <tr height=0 style='display:none'>
      <td width=97 style='width:73pt'></td>
      <td width=119 style='width:89pt'></td>
      <td width=109 style='width:82pt'></td>
      <td width=133 style='width:100pt'></td>
      <td width=125 style='width:94pt'></td>
      <td width=136 style='width:102pt'></td>
      <td width=152 style='width:114pt'></td>
      <td width=265 style='width:199pt'></td>
      <td width=173 style='width:130pt'></td>
      <td width=127 style='width:95pt'></td>
      <td width=208 style='width:156pt'></td>
      <td width=101 style='width:76pt'></td>
      <td width=101 style='width:76pt'></td>
      <td width=101 style='width:76pt'></td>
      <td width=101 style='width:76pt'></td>
      <td width=101 style='width:76pt'></td>
      <td width=101 style='width:76pt'></td>
      <td width=101 style='width:76pt'></td>
      <td width=101 style='width:76pt'></td>
      <td width=101 style='width:76pt'></td>
      <td width=101 style='width:76pt'></td>
      <td width=101 style='width:76pt'></td>
     </tr>
    
    </table>
    
    </div>
    
    
    <!----------------------------->
    <!--END OF OUTPUT FROM EXCEL PUBLISH AS WEB PAGE WIZARD-->
    <!----------------------------->
    </body>
    
    