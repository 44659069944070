import { Component, Inject, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { CatSelectorComponent } from 'src/app/utils/cat-selector/cat-selector.component';
import { ImageGalleryComponent } from 'src/app/utils/image-gallery/image-gallery.component';
import { Observable, of } from 'rxjs';

import {Location} from '@angular/common';

import { jqxSliderComponent } from 'jqwidgets-ng/jqxslider';

import {FoodData, CatNode} from '../../models/food-category';
import { DbconnectService } from 'src/app/dbconnect.service';
import * as myGlobal from '../../global';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { ImageSelectorComponent } from 'src/app/utils/image-selector/image-selector.component';

@Component({
  selector: 'app-food-detail',
  templateUrl: './food-detail.component.html',
  styleUrls: ['./food-detail.component.scss']
})
export class FoodDetailComponent implements OnInit {
  // @ViewChild('typeSlider', { static: false }) typeSlider!: jqxSliderComponent;
  @ViewChild('foodTypeComboBox', { static: false }) foodTypeComboBox!: jqxDropDownListComponent;

  catData: any;

  foodData!: any; // FoodData;

  foodId = '0';
  catId = '0';
  createNew = false;

  foodType = 1;

  userForm!: UntypedFormGroup;
  parentId!: never;

  comboBoxFoodTypeSource = [{ value: '1', label: '1 - Nên dùng' }, {value: '2', label: '2 - Trung lập'}, {value: '3', label: '3 - Nên tránh'}];

  constructor(
    private dbConnect: DbconnectService,
    private route: ActivatedRoute,
    private _location: Location,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog
    ) {

      this.foodData = {
        ​created_by: 'system',​
        created_date: 'none',​
        category_id: 0,​
        food_desc: 'Desc',​
        food_id: 2,​
        food_image: 'pasta.jpg',​
        food_name: 'name',​
        food_typ_id: 1,​
        food_url: null,​
        imgUrl: '/assets/media/pasta.jpg',​
        states: null,​
        updated_date: null,​
        user_id: null,​
        votes: 0
      };

      this.catData = {
        category_name : '',
      };
    }

  ngOnInit(): void {
    this.foodId = this.route.snapshot.paramMap.get('foodId') + '';
    this.catId = this.route.snapshot.paramMap.get('catId') + '';

    console.debug('FoodID = ' + this.foodId  + ' /catId= ' + this.catId);

    if (this.foodId == 'undefined' || (Object.keys(this.foodId).length === 0)) { // Chuan hoa parentId
      this.foodId = '-1';
    }
    this.createNew = this.foodId == '-1';

    this.userForm = this.formBuilder.group({
      food_name: ['', Validators.required],
      remark: ['', Validators.nullValidator],
      image: [{value: null, disabled: true}, Validators.required],
    });

    // Doc thong tin ve cat
    this.dbConnect
      .getCatDataByID(this.catId)
      .subscribe({next: (data: any ) => {
        if (data.length > 0)   {
          this.catData = data[0];

        }
        console.debug( this.catData);

      }, error: err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }});


    // Doc thon tin thuc an
    this.dbConnect
      .getFoodDetailById(this.foodId)
      .subscribe({next: (data: any ) => {
        if (data.length > 0)   {
          this.foodData = data[0];

          this.foodData.imgUrl = myGlobal.image_lib_base + this.foodData.food_image;

          this.userForm.controls.food_name.setValue(this.foodData.food_name);
          this.userForm.controls.remark.setValue(this.foodData.food_desc);
          this.userForm.controls.image.setValue(this.foodData.food_image);
          
        }
       // console.debug(this.foodData);

      }, error: err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }});


  }

  typeSliderOnChange(): void {
    // let red = this.fixHex(Math.round(this.typeSlider.value()).toString(16));
    this.foodType = this.foodTypeComboBox.getSelectedItem()['value'];
   // this.currFoodData.food_type = this.foodType ;
    // if (this.currFoodData) {
    //     this.FoodType.val(this.currFoodData.food_type);
    // }
  }

  fixHex(hex: any): any {
    return (hex.length < 2) ? '0' + hex : hex;
  }

  // tooltipFormatFunction: any = (value:any) => {
  //   if (this.typeSlider.mode() === 'default') {
  //       if (value < 10) return new Number(value.toPrecision(3));
  //       if (value > 10 && value < 100) return new Number(value.toPrecision(4));
  //       else return new Number(value.toPrecision(5));
  //   }
  //   else {
  //       if (value < 10) return new Number(value.toPrecision(3));
  //       if (value > 10 && value < 256) return new Number(value.toPrecision(4));
  //   }
  //   return "";
  // };

  tickLabelFormatFunction: any = (value: any) => {
    if (value == 1) { return value; }
    if (value == 5) { return value; }
    return '';
  }

  onSubmit() {
      let body =  {
        food_name: this.userForm.controls.food_name.value,
        food_desc: this.userForm.controls.remark.value,
        category_id: this.catId,     // Luu y doi ten truong
        food_image: this.userForm.controls.image.value,
        food_typ_id: this.foodTypeComboBox.getSelectedItem()['value'], // this.typeSlider.getValue(),
        created_by: 'system',
        votes: 0,
      };


      if (this.createNew) {
        this.dbConnect.addFoodData(
        body
        ).subscribe({next: (result) => {

            alert('Thêm thức ăn thành công ' + this.userForm.controls.food_name.value );
            this._location.back();
            this.dbConnect.sysLog('FoodDetailComponent', 'submit', 'Add Food Data= ' +  this.userForm.controls.food_name.value);

        }, error: err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }});

        // this.userProfileService.sysLog('FilterQuestionComponent','submit','Add Quest= ' +  this.userForm.controls.quest.value);
      } else {
        this.dbConnect.updateFoodData(
          this.foodId,
          body
          ).subscribe({next: (result) => {

              alert('Cập nhậtthức ăn thành công ' + this.userForm.controls.food_name.value );
              this._location.back();
              this.dbConnect.sysLog('FoodDetailComponent', 'submit', 'Modify Food Data= ' +  this.userForm.controls.food_name.value);

          }, error: err => {
            console.error('Observer got an error: ' + err);
            alert('Lỗi kết nối mạng! ');
          }});
          // this.dbConnect.sysLog('FilterQuestionComponent','submit','Update Quest= ' +  this.userForm.controls.category_name.value);
      }
  }

  onGoBack()
  {
    this._location.back();
  }

  // Doi sang nhom khac
  changeCatClick() {
    this.openCatSelectorDialog('Chọn nhóm', 'Hãy chọn nhóm').subscribe(result => {
      if (result != '0') {
        this.dbConnect
        .getCatDataByID(result)
        .subscribe({next: (data: any ) => {
          if (data.length > 0)   {
            this.catData = data[0];
          }          
          console.debug( this.catData);
        }, error: err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }});

        this.foodData.category_id = result;
        this.catId = result;
      }
    });
  }

  changeImageClick() {
    this.openImageSelectorDialog('Chọn ảnh', 'Hãy chọn nhóm').subscribe(result => {
      if (result != '0') {
        this.foodData.food_image = result;
        this.foodData.imgUrl = myGlobal.image_lib_base + this.foodData.food_image;
        this.userForm.controls.image.setValue(this.foodData.food_image);
       // alert (this.foodData.food_image);
      }
    });
  }

  openCatSelectorDialog(title: string, msg: string): Observable<any>{
    const dialogRef = this.dialog.open(CatSelectorComponent, {
      width: '350px', height: '480px',
      data: {title, msg, catType: myGlobal.CAT_TYPE_FOOD}
    });
    return dialogRef.afterClosed();
  }

  openImageSelectorDialog(title: string, msg: string): Observable<any>{
    // const dialogRef = this.dialog.open(ImageGalleryComponent, {
    //   width: '650px',height: '360px',
    //   data: {title, msg}
    // });
    // return dialogRef.afterClosed();

    const dialogRef = this.dialog.open(ImageSelectorComponent, {
      width: '700px', height: '480px',
      // data: {title, msg}
    });
    return dialogRef.afterClosed();
  }

  invalidName() { return false;
  }
  invalidRemark() { return false; }
  invalidImageUrl() { return false; }

}


