import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { SwiperComponent, SwiperDirective } from 'ngx-swiper-wrapper';
import { SwiperOptions } from 'swiper';
import { PaginationOptions } from 'swiper/types/components/pagination';
import { ScrollbarOptions } from 'swiper/types/components/scrollbar';
import { MsgDialogComponent } from 'src/app/utils/msg-dialog/msg-dialog.component';
import { DbconnectService } from 'src/app/dbconnect.service';

import * as myGlobal from '../../global';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxInputComponent } from 'jqwidgets-ng/jqxinput';
import { Observable } from 'rxjs';
import { ImageQuickViewerComponent } from '../image-quick-viewer/image-quick-viewer.component';
import { ImageUploadComponent } from '../image-upload/image-upload.component';


@Component({
  selector: 'app-image-man',
  templateUrl: './image-man.component.html',
  styleUrls: ['./image-man.component.scss']
})
export class ImageManComponent implements  OnInit, AfterViewInit {

  constructor(
    private dbConnect: DbconnectService,
    private route: ActivatedRoute,
    private _location: Location,
    private router: Router,
    public dialog: MatDialog
    ) { }
; 
  @ViewChild('jqxgrid', { static: false }) jqxgrid!: jqxGridComponent;

  imagerenderer = (row: number, datafield: string, value: string): string => {
    if (value == null || value == '') { value = '/assets/bp-acid-reflux-icon-fill.png'; }
  
    // if (this.imageList[row]["news_type"]=="1") {
    return '<img style="margin-left: 5px;" height="50" width="80" src="' + value + '"/>';
    // } else {
     // return '<iframe width="240" height="120" src="' + value + '" frameborder="0" allowfullscreen></iframe>';
      // return '<div class="video"> \
      //   <video controls #videoPlayer> \
      //    <source src="' + value + '" " /> \
      //   Browser not supported \
      // </video> \
      // </div>';
    // }
  }

  public imageList!: any ;

  columns: any[] =
  [
    { text: 'ID', datafield: 'img_id', width: '5%' , editable: false},
    { text: 'Ảnh', datafield: 'show_img_path', width: 100, cellsrenderer: this.imagerenderer , editable: false},       
    { text: 'Tiêu đề', datafield: 'img_title' , width: 180, editable: true, columntype: 'textarea', },
    { text: 'Mô tả', datafield: 'remark' , width: 140, editable: true, columntype: 'textbox', } ,
    { text: 'Ngày tạo', datafield: 'created_date' , width: 100, editable: false, columntype: 'textbox', } ,
    // { text: 'Image path', datafield: 'img_path' ,width: 280,editable:false, columntype: 'textbox',} ,
    // { text: 'File Name', datafield: 'file_name' ,width: 100,editable:false, columntype: 'textbox',} ,
    
  ];

  gridsource: any = {
    datatype: 'json',
    datafields: [
        { name: 'img_id', type: 'number' },
        { name: 'show_img_path', type: 'string' },   // for render    
        { name: 'img_name', type: 'string' },   
        { name: 'img_path', type: 'string' },  
        { name: 'img_title', type: 'string' },
        { name: 'remark', type: 'string' } ,    
        { name: 'created_date', type: 'string' }   , 
        { name: 'file_name', type: 'string' }   , 
        
    ],
    localdata: this.imageList,
    // updaterow: (rowid, rowdata, commit): void => {
    //     commit(true);
    // }
  };
  griddataAdapter: any = new jqx.dataAdapter(this.gridsource);
  // slides: any;
  directiveRef: any;

  

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    
    this.reloadGridData();
  
    this.createButtons();
  }

  Celldoubleclick(event: any): void 
  {
    //  this.openImageSelectorDialog();

    const rowidx = this.jqxgrid.selectedrowindex();
    if (rowidx == -1) { return; }

    const data = this.jqxgrid.getrowdata(rowidx);

    // this.selectImage = data["img_path"];

    const dialogRef = this.dialog.open(ImageQuickViewerComponent, {
        width: '650px', height: '360px',
        data: {imgUrl: data.show_img_path, imgTitle: ''}
    });
    dialogRef.afterClosed();
  }
 

reloadGridData() {
  this.imageList = [];
  // this.hasData =false;
//   alert("Call:reloadGridData " + catid);
  this.dbConnect
  .getImgGallery('*')
  .subscribe({next: (data: []) => {
    
    this.imageList = data;    
    data.forEach((e: any) => {
      e.show_img_path = myGlobal.image_lib_base + e.img_path ; 
      // +'?' + this.randomString(40,this.randSeed);
      
    });
    console.debug('Gallery length: ' + this.imageList.length);
    this.gridsource.localdata = this.imageList;
    this.jqxgrid.updatebounddata();
    // console.log(this.swiperComp);
    setTimeout(() => {
      this.directiveRef?.update();
    }, 2000);
    
  }, error: err => {
    console.error('Observer got an error: ' + err);
    alert('Lỗi kết nối mạng! ');
  }});
}

  rowselect(event: any): void {
    // event arguments.
    // let args = event.args;
    // // selected row.
    // let row = event.args.row;
    // this.currNewsId = row.news_id;
  }

  createToolbar(statusbar: any): void {
    const buttonsContainer = document.createElement('div');
    buttonsContainer.style.cssText = 'overflow: hidden; position: relative; margin: 5px;';
    const addButtonContainer = document.createElement('div');
    const deleteButtonContainer = document.createElement('div');
    const reloadButtonContainer = document.createElement('div');
    const searchButtonContainer = document.createElement('div');
    const uploadButtonContainer = document.createElement('div');
    addButtonContainer.id = 'addButton';
    deleteButtonContainer.id = 'deleteButton';
    reloadButtonContainer.id = 'reloadButton';
    searchButtonContainer.id = 'searchButton';
    uploadButtonContainer.id = 'uploadButton';
    addButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
    deleteButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
    reloadButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
    searchButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
    uploadButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
    buttonsContainer.appendChild(addButtonContainer);
    buttonsContainer.appendChild(deleteButtonContainer);
    buttonsContainer.appendChild(reloadButtonContainer);
   // buttonsContainer.appendChild(searchButtonContainer);
    buttonsContainer.appendChild(uploadButtonContainer);
    statusbar[0].appendChild(buttonsContainer);
  }

  createButtons(): void {
   
    const addButtonOptions = {
        width: 80, height: 25, value: 'Add',
        imgSrc: '/assets/media/icons/add.png',
        imgPosition: 'center', textPosition: 'center',
        textImageRelation: 'imageBeforeText'
    }
    const addButton = jqwidgets.createInstance('#addButton', 'jqxButton', addButtonOptions);
    const deleteButtonOptions = {
        width: 80, height: 25, value: 'Delete',
        imgSrc: '/assets/media/icons/close.png',
        imgPosition: 'center', textPosition: 'center',
        textImageRelation: 'imageBeforeText'
    }
    const deleteButton = jqwidgets.createInstance('#deleteButton', 'jqxButton', deleteButtonOptions);
    const reloadButtonOptions = {
        width: 80, height: 25, value: 'Reload',
        imgSrc: '/assets/media/icons/refresh.png',
        imgPosition: 'center', textPosition: 'center',
        textImageRelation: 'imageBeforeText'
    }
    const reloadButton = jqwidgets.createInstance('#reloadButton', 'jqxButton', reloadButtonOptions);
    const searchButtonOptions = {
        width: 80, height: 25, value: 'Find',
        imgSrc: '/assets/media/icons/search.png',
        imgPosition: 'center', textPosition: 'center',
        textImageRelation: 'imageBeforeText'
    }
   // let searchButton = jqwidgets.createInstance('#searchButton', 'jqxButton', searchButtonOptions);
    const uploadButtonOptions = {
      width: 80, height: 25, value: 'Upload',
      imgSrc: '/assets/media/icons/up_small_normal_hover.png',
      imgPosition: 'center', textPosition: 'center',
      textImageRelation: 'imageBeforeText'
    }
    const uploadButton = jqwidgets.createInstance('#uploadButton', 'jqxButton', uploadButtonOptions);
    // add new row.
    addButton.addEventHandler('click', (event: any): void => {
    //    alert('Here! ');
       // this.addNewItemCatId(this.currCatId);
    });
    // delete selected row.
    deleteButton.addEventHandler('click', (event: any): void => {
        // const selectedrowindex = this.jqxgrid.getselectedrowindex();
        // const row = this.jqxgrid.getdatainformation().rowscount;
        // const id = this.jqxgrid.getrowid(selectedrowindex);
        let rowidx = this.jqxgrid.selectedrowindex();
        if (rowidx==-1) return;

        let rowdata = this.jqxgrid.getrowdata(rowidx);
        alert(JSON.stringify(rowdata));

        this.openDialog("Xác nhận","Có chắc chắn muốn xóa ảnh này ? Thao tác này sẽ không khôi phục được " + 
          rowdata["img_title"] + "(" + rowdata["img_path"] +")").subscribe(result => {
          if (result=="1") {
            this.dbConnect.delEntryById('img_gallery',rowdata["img_id"])
              .subscribe({next:(data: []) => {
                    //this.reloadGridData();
                    alert("Xóa dữ liệu thành công!");
                    //this.jqxgrid.deleterow(id);
                    this.dbConnect.sysLog('ImageManComponent','Submit','Delete image id=' + rowdata["img_id"] + 
                        "/" + rowdata["img_title"] + "(" + rowdata["img_path"] +")");
                  }, error:err => {
                    console.error('Observer got an error: ' + err);
                    alert('Lỗi kết nối mạng! ');
                  }});                              
          }               
        });

  
    });
  
    // reload grid data.
    reloadButton.addEventHandler('click', (event: any): void => {
        // this.jqxgrid.source(this.getAdapter());
       // this.reloadGridData(this.dataSource+"","");
       this.reloadGridData();
    });
    // search for a record.
   // searchButton.addEventHandler('click', (event: any): void => {
        //  this.findWindow.open();
        //  this.findWindow.move(220, 120);
   // });

    uploadButton.addEventHandler('click', (event: any): void => {
    this.openImageUploadDialog('Upload ảnh', 'Upload ảnh').subscribe(result => {
      if (result !== '0') {
        //alert(result);
        // this.currFoodData.food_image = result;
        // this.imgUrl = myGlobal.image_lib_base + this.currFoodData.food_image;
       // this.currFoodData.controls.image.setValue(this.currFoodData['food_image']);
      }
    });
    });
  }

  openImageUploadDialog(title: string, msg: string): Observable<any>{
    const dialogRef = this.dialog.open(ImageUploadComponent, {
      width: '680px', height: '320px',
      // data: {title, msg}
    });
    return dialogRef.afterClosed();
  }

  onCellEndEdit(event: any): void {

    const args = event.args;
    let columnDataField = args.datafield;
    const rowIndex = args.rowindex;
    const cellValue = args.value;
    const oldValue = args.oldvalue;

    const body: any = {};

    // if (columnDataField === 'img_url2') {  // Update img_url if changed
    //   columnDataField = 'img_url';
    // }

    body[columnDataField] = cellValue;
    
    console.log(body);
    // this.dbConnect.updateNews(
    //   this.imageList[parseInt(rowIndex)].news_id,
    //   body,
    //   ).subscribe({next: (result) => {
                  
    //      // alert('Cập nhật bài viết thành công ');
    //      // this._location.back();
    //      // window.location.reload();
    //     // this.reloadGridData(this.currCatId+"","");
      
    //   }, error: err => {
    //     console.error('Observer got an error: ' + err);
    //     alert('Lỗi kết nối onCellEndEdit! ');
    //   }});
  }

  openDialog(title:string, msg: string): Observable<any>{
    const dialogRef = this.dialog.open(MsgDialogComponent, {
      width: '250px',
      data: {title, msg}
    });
    return dialogRef.afterClosed();
  }


}
