<div class="container-lg">
    <div class="row">
        <h2 class="text-primary">Thông tin chung</h2>
    </div>
    <div class="row">
        <table style="border: 1px solid black;display: grid;">
            <tr>
                <td>Tổng số người dùng tải ứng dụng: </td>
                <td style="text-align:left"><strong>N/A</strong></td>
            </tr><tr>
                <td>Tổng số người dùng ứng dụng: </td>
                <td style="text-align:left"><strong> {{userCount | json}}</strong></td>
            </tr><tr>
                <td>
                Tổng số người dùng ứng dụng trong một tháng qua: </td>
                <td style="text-align:left"><strong>22</strong></td>
            </tr><tr>   
        </table>
    </div>

    <div class="row">
        <div class="col-6">
            <h2  class="text-primary">Biểu đồ phân bố giới tính</h2>
            <ngx-charts-pie-chart
                [view]="[700, 400]"
                [scheme]="colorScheme1"
                [results]="gender"
                [gradient]="gradient"
                [legend]="showLegend"
                [labels]="showLabels"
                [doughnut]="isDoughnut"  
                legendTitle='Chú thích'
                [labelFormatting]="labelFormatting"
                [legendPosition]="legendPosition"
                >
                </ngx-charts-pie-chart>

               
        </div>
        <div class="col-6">
        <h2  class="text-primary">Biểu đồ phân bố độ tuổi</h2>
            <ngx-charts-pie-chart
                [view]="[700, 400]"
                [scheme]="colorScheme2"
                [results]="ages"
                [gradient]="gradient"
                [legend]="showLegend"
                [labels]="showLabels"
                [doughnut]="isDoughnut"  
                legendTitle='Chú thích'
                [labelFormatting]="labelFormatting"
                [legendPosition]="legendPosition"
                >
                </ngx-charts-pie-chart>
        </div>
    </div>
</div>

