<div class="container-lg">
    <h2 class="text-primary">Thông tin thức ăn</h2>

    
    <form [formGroup]="userForm" >
        <div class="row">       
            <div class="col">

              <div class="container">
                <div class="row">
                
                <div class="col-sm-4 ">
                    
                    <img class="foodImg" src="{{foodData['imgUrl']}}" width="300" height="200" >
                    <!-- a href="/gallery" *ngIf="foodData['imgUrl'] && !createNew">Change image</a>
                    <a href="/gallery" *ngIf="createNew">Select image</a -->
                    <button mat-raised-button class="btn btn btn-info" (click)="changeImageClick()"><i class="fas fa-images"></i> Chọn ảnh</button>

                </div>
                <div class="col-sm-8 ">                  

                    <h3 *ngIf="!createNew && foodData" class="bold"> Nhập thông tin thức ăn id : {{foodId}}
                        - {{foodData['food_name']}} </h3>
                       
                  <h3 class="bold" *ngIf="createNew"> Tạo mới thông tin thức ăn </h3>
                  Nhóm thức ăn:<strong> {{catData['category_name']}}</strong>&nbsp;
                  <button mat-raised-button class="btn btn btn-warning" (click)="changeCatClick()"><i class="fas fa-folder-plus"></i> Đổi nhóm</button>
                <div class="form-group" [ngClass]="{ 'has-danger': invalidName() }"> 
                    <label for="food_name" class="control-label">Tên*</label>
                    <input type="text" formControlName="food_name" class="form-control" [ngClass]="{ 'is-invalid': invalidName() }" 
                            id="food_name" name="food_name" placeholder="Tên"  >
                    <p *ngIf="invalidName()" id="name_error" class="invalid-feedback">Tên không hợp lệ</p>
                </div>
    
                <div class="form-group" [ngClass]="{ 'has-danger': invalidRemark() }">
                    <label for="remark" class="form-control-label">Mô tả *</label>
                    <input type="text" formControlName="remark" class="form-control" [ngClass]="{ 'is-invalid': invalidRemark() }" 
                            id="remark" name="remark" placeholder="Mô tả">
                    <p *ngIf="invalidRemark()" class="invalid-feedback" id="remark_error">Mô tả.</p>
                </div>

                <div>
                    <label for="foodType" class="form-control-label">Phân loại: &nbsp;</label>
                    <jqxDropDownList  #foodTypeComboBox [width]='250' [height]='30' [selectedIndex]='0' [source]="comboBoxFoodTypeSource" [autoDropDownHeight]="true">
                    </jqxDropDownList >                    
                </div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidImageUrl() }">
                    <label for="image" class="form-control-label">Image link* &nbsp;</label>
                    <input type="text" formControlName="image" class="form-control" [ngClass]="{ 'is-invalid': invalidImageUrl() }" 
                            id="image" name="image" placeholder="Image link">
                    <p *ngIf="invalidImageUrl()" class="invalid-feedback" id="img_error"> Giới tính không hợp lệ.</p>
                </div>

                

                <!-- div class="form-group" [ngClass]="{ 'has-danger': invalidCreated_date() }">
                    <label for="created_date" class="form-control-label">Ngày tạo *</label>
                    <input type="text" formControlName="created_date" class="form-control" [ngClass]="{ 'is-invalid': invalidCreated_date() }" id="created_date" name="created_date" placeholder="Ngày tạo" disabled>
                    <p *ngIf="invalidCreated_date()" id="created_date_error" class="invalid-feedback">Invalid created date.</p>
                </div -->

                <!-- div class="form-group" [ngClass]="{ 'has-danger': invalidUpdated_date() }">
                    <label for="updated_date" class="form-control-label">Ngày tạo *</label>
                    <input type="text" formControlName="updated_date" class="form-control" [ngClass]="{ 'is-invalid': invalidUpdated_date() }" id="updated_date" name="updated_date" placeholder="Ngày tạo" disabled>
                    <p *ngIf="invalidUpdated_date()" id="updated_date_error" class="invalid-feedback">Invalid updated date.</p>
                </div -->

                <div class="form-group has-error"> 
                    <button type="submit" class="btn btn-primary" *ngIf="!createNew" (click)="onSubmit()">
                        <i class="fa fa-check"></i>&nbsp; Cập nhật</button>   &nbsp;   
                    <button type="submit" class="btn btn-primary" *ngIf="createNew" (click)="onSubmit()">
                            <i class="fa fa-check"></i>&nbsp;Tạo mới</button>   &nbsp;                
                    
                    <button mat-raised-button class="btn btn-success" (click)="onGoBack()"><i class="fa fa-angle-left"></i>&nbsp; Quay lại</button>&nbsp;
                     
                </div>   

                </div>
                </div>
            </div>


              
            </div>
        </div>

    </form>
</div>

<!-- {{quest_list|json}} -->
