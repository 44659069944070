import { Component, OnInit, ViewChild,AfterViewInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { SwiperComponent, SwiperDirective } from 'ngx-swiper-wrapper';
import { SwiperOptions } from 'swiper';
import { PaginationOptions } from 'swiper/types/components/pagination';
import { ScrollbarOptions } from 'swiper/types/components/scrollbar';
import { MsgDialogComponent } from 'src/app/utils/msg-dialog/msg-dialog.component';
import { DbconnectService } from 'src/app/dbconnect.service';

import * as myGlobal from '../../global';


@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit,AfterViewInit {

  public show: boolean = true;

  public slides :any ;

  public selectImage : string = "";

  public type: string = 'component';

  public disabled: boolean = false;

  public config: SwiperOptions = {
    a11y: { enabled: true },
    direction: 'horizontal',
    slidesPerView: 4,
    keyboard: true,
    mousewheel: true,
    scrollbar: true,
    navigation: true,
    pagination: true,
    observer: true,
    observeParents: true,

    loop: true,
    spaceBetween: 10,
            
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
  };

  private scrollbar: ScrollbarOptions = {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true
  };

  private pagination: PaginationOptions = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false
  };

  @ViewChild(SwiperComponent, { static: false }) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;
  @ViewChild('swiperComp') swiperComp: any;

  constructor(
    private dbConnect : DbconnectService,
    private route: ActivatedRoute,
    private _location: Location,
    private router: Router,
    public dialog: MatDialog
  ) {}
  
  randomString(length:number, chars:string) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  randSeed = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
//var rString = randomString(40, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'); 
  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    
      this.dbConnect
      .getImgGallery("0")
      .subscribe({next:(data: []) => {
        this.slides = data;
        data.forEach((e:any) => {
          e['show_img_path'] = myGlobal.image_lib_base + e['img_path'] ; 
          //+'?' + this.randomString(40,this.randSeed);
          
        });
        console.debug("Gallery length: " + this.slides.length);

        console.log(this.swiperComp);
        setTimeout(() => {
           this.directiveRef?.update();
        }, 2000);
        
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }});
    
  }

  public toggleType(): void {
    this.type = (this.type === 'component') ? 'directive' : 'component';
  }

  public toggleDisabled(): void {
    this.disabled = !this.disabled;
  }

  public toggleDirection(): void {
    this.config.direction = (this.config.direction === 'horizontal') ? 'vertical' : 'horizontal';
  }

  public toggleSlidesPerView(): void {
    if (this.config.slidesPerView !== 1) {
      this.config.slidesPerView = 1;
    } else {
      this.config.slidesPerView = 2;
    }
  }

  public toggleOverlayControls(): void {
    if (this.config.navigation) {
      this.config.scrollbar = false;
      this.config.navigation = false;

      this.config.pagination = this.pagination;
    } else if (this.config.pagination) {
      this.config.navigation = false;
      this.config.pagination = false;

      this.config.scrollbar = this.scrollbar;
    } else {
      this.config.scrollbar = false;
      this.config.pagination = false;

      this.config.navigation = true;
    }

    if (this.type === 'directive' && this.directiveRef) {
      this.directiveRef.setIndex(0);
    } else if (this.type === 'component' && this.componentRef && this.componentRef.directiveRef) {
      this.componentRef.directiveRef.setIndex(0);
    }
  }

  public toggleKeyboardControl(): void {
    this.config.keyboard = !this.config.keyboard;
  }

  public toggleMouseWheelControl(): void {
    this.config.mousewheel = !this.config.mousewheel;
  }

  public onIndexChange(index: number): void {
    console.log('Swiper index: ', index);
  }

  public onSwiperEvent(event: string): void {
    console.log('Swiper event: ', event);
  }

  public onSelectPicture(event: any): void {
    console.log('onSelectPicture event: ', event);
    this.selectImage = event.target.name;
  }

  

}
