import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Location} from '@angular/common';



import {FoodData, CatNode} from '../../models/food-category';
import { DbconnectService } from 'src/app/dbconnect.service';
import * as myGlobal from '../../global';

@Component({
  selector: 'app-category-detail',
  templateUrl: './category-detail.component.html',
  styleUrls: ['./category-detail.component.scss']
})

export class CategoryDetailComponent implements OnInit {

  catData! : CatNode;
  catId!: string;
  catType!: number;
  parentId: string = "0";
  createNew = false;
  parentObj!:CatNode;

  userForm!: UntypedFormGroup;
  
  constructor(
    private dbConnect : DbconnectService,
    private route: ActivatedRoute,
    private _location: Location,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    ) { }

  ngOnInit(): void {
    this.catId = this.route.snapshot.paramMap.get('catId')+"";
    this.catType =  parseInt(this.route.snapshot.paramMap.get('catType')+"");
    this.parentId = this.route.snapshot.paramMap.get('parentId')+"";
    if (this.parentId=='undefined' || (Object.keys(this.parentId).length === 0)) { // Chuan hoa parentId
      this.parentId = "-1";
    }
    this.createNew = this.catId=='-1';

    this.userForm = this.formBuilder.group({      
      category_name: ['', Validators.required],
      remark: ['', Validators.nullValidator],
      image: ['', Validators.required],
    });

    // Doc toan bo cat
    this.dbConnect
    .getAllCatList(this.catType)
    .subscribe({next:(data: []) => {      
      data.forEach((e) => {
        if (e["category_id"] == this.parentId) {
          this.parentObj = {
              category_name: e["category_name"] ,
              id: e["category_id"],  
              parentid: e["parent_id"],
              remark: e["remark"],
              image: e["image"],   
              icon: e["image"] ,
              locked: e["locked"]        
            } ;
        } else if (e["category_id"] == this.catId) {
          this.catData = {
            category_name: e["category_name"],
            id: e["category_id"],  
            parentid: e["parent_id"],
            remark: e["remark"],
            image: e["image"],   
            icon: e["image"]  ,
            locked: e["locked"] 
          } ;
          this.userForm.controls.category_name.setValue(  this.catData['category_name']);
          this.userForm.controls.remark.setValue(  this.catData['remark']);
          this.userForm.controls.image.setValue(  this.catData['image']);
        }        
      });
      
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});

    if (this.createNew) {
      this.userForm.controls.image.setValue('/assets/media/icons/folder.png');      
    }

    
  }

  onGoBack()
  {  
    this._location.back();
  }

  onSubmit() {
    var body =  {
      category_name: this.userForm.controls.category_name.value,
      remark: this.userForm.controls.remark.value,      
      parent_id: this.parentId,     // Luu y doi ten truong
      image: this.userForm.controls.image.value,
      locked: "0",
      type: this.catType
    };


    if (this.createNew) { 
      this.dbConnect.addCat(
      body
      ).subscribe({next:(result) => {
                  
          alert('Thêm nhóm thành công ' + this.userForm.controls.category_name.value );
          this._location.back();
          this.dbConnect.sysLog('CategoryDetailComponent','submit','Add new cat = ' +  body.category_name);
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }});
      //this.userProfileService.sysLog('FilterQuestionComponent','submit','Add Quest= ' +  this.userForm.controls.quest.value);
    } else {
      this.dbConnect.updateCat(
        this.catId,
        body
        ).subscribe({next:(result) => {
                    
            alert('Cập nhật nhóm thành công ' + this.userForm.controls.category_name.value );
            this._location.back();
            this.dbConnect.sysLog('CategoryDetailComponent','submit','Update cat = ' +  body.category_name);
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }});
        //this.dbConnect.sysLog('FilterQuestionComponent','submit','Update Quest= ' +  this.userForm.controls.category_name.value);
    }
  }

  
  
  invalidName() { return false};
  invalidRemark() { return false};
  invalidImageUrl() { return false};
}
