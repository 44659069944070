import { Component, OnInit } from '@angular/core';
import { DbconnectService } from 'src/app/dbconnect.service';
import { XlsExportService } from 'src/app/utils/xls-export.service';

@Component({
  selector: 'app-exp-drug',
  templateUrl: './exp-drug.component.html',
  styleUrls: ['./exp-drug.component.scss']
})
export class ExpDrugComponent implements OnInit {

  constructor(
    private dbConnect:DbconnectService,
    private exportService: XlsExportService) { }

  ngOnInit(): void {
  }

  export() {
    this.dbConnect.sysLog('ExpDrugComponent','Export','Template=' +  'DrugReport');
    this.exportService.exportToExcel("ExportTable", 'DrugReport');    
  }


}
