import { Component, OnInit } from '@angular/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DbconnectService } from 'src/app/dbconnect.service';

@Component({
  selector: 'app-rpt-sympton',
  templateUrl: './rpt-sympton.component.html',
  styleUrls: ['./rpt-sympton.component.scss']
})
export class RptSymptonComponent implements OnInit {

  
  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;  
  showYAxisLabel: boolean = true;  
  animations: boolean = true;

  view: any[] = [700, 400];

  ySymptonLabel: string = 'Triệu chứng';
  xSymptonLabel: string = 'Tỷ lệ';
  yMultiLabel: string = 'Tỷ lệ';
  xMultiLabel: string = 'Triệu chứng';


  xFreqLabel: string = 'Triệu chứng';
  yFreqLabel: string = 'Tỷ lệ';
  legendTitle: string = 'Thời điểm';


  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  colorScheme1 = {    
    domain: ['#0d6efd', '#6610f2', '#d63384',
          '#dc3545', '#fd7e14', '#ffc107', '#198754', '#20c997', '#0dcaf0', '#adb5bd','#000']
  };

  colorScheme11 = {    
    domain: [
          //'#0d6efd', '#6610f2', '#d63384',
          '#dc3545', '#fd7e14', '#ffc107', '#198754', '#20c997', '#0dcaf0', '#adb5bd','#000']
  };

  colorScheme2 = {    
    domain: ['#0d6efd', '#6610f2', '#6f42c1', '#d63384',
          '#dc3545', '#fd7e14', '#ffc107', '#198754', '#20c997', '#0dcaf0', '#adb5bd','#000']
  };


  sympton_data = [
    {
      "name": "Trào ngược",
      "value": 70
    },{
      "name": "Nóng rát",
      "value": 56
    },{
      "name": "Nôn",
      "value": 4
    },{
      "name": "Khó thở",
      "value": 5
    },{
      "name": "Buồn nôn",
      "value": 10
    },{
      "name": "Viêm họng",
      "value": 40
    },{
      "name": "Ợ hơi",
      "value": 15
    },{
      "name": "Đầy bụng",
      "value": 14
    }

  ];

  sympton_multi =  [
    {
      "name": "Đau bụng",
      "series": [
        {
          "name": "Nhẹ đi",
          "value": 63.6
        },
        {
          "name": "Nặng lên",
          "value": 36.4
        }
      ]
    },  
    {
      "name": "Đau thượng vị",
      "series": [
        {
          "name": "Nhẹ đi",
          "value": 63.6
        },
        {
          "name": "Nặng lên",
          "value": 36.4
        }
      ]
    },  
    {
      "name": "Buồn nôn",
      "series": [
        {
          "name": "Nhẹ đi",
          "value": 54.5
        },
        {
          "name": "Nặng lên",
          "value": 36.4
        },
        {
          "name": "Khỏi",
          "value": 9.1
        }
      ]
    },{
      "name": "Khó thở",
      "series": [
        {
          "name": "Nhẹ đi",
          "value": 81.8
        },
        {
          "name": "Nặng lên",
          "value": 18.2
        }
      ]
    },  
  ];

  sympton_tansuat = [
    {
      "name": "Đau đầu",
      "series": [
        {
          "name": "Trước",
          "value": 9.46
        },
        {
          "name": "Sau",
          "value": 10.31
        }
      ]
    },
  
    {
      "name": "Ợ hơi",
      "series": [
        {
          "name": "Trước",
          "value": 10.45
        },
        {
          "name": "Sau",
          "value": 8.74
        }
      ]
    },
  
    {
      "name": "Viêm họng",
      "series": [
        {
          "name": "Trước",
          "value": 9.28
        },
        {
          "name": "Sau",
          "value": 10.08
        }
      ]
    },
  
    {
      "name": "Khó thở",
      "series": [
        {
          "name": "Trước",
          "value": 10.63
        },
        {
          "name": "Sau",
          "value": 10.20
        }
      ]
    },
  
    {
      "name": "Non",
      "series": [
        {
          "name": "Trước",
          "value": 11.33
        },
        {
          "name": "Sau",
          "value": 10.00
        }
      ]
    }
  ];
  

  constructor(
    private dbConnect:DbconnectService
    ) { }

  ngOnInit(): void {
  }

}
