import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface ImgViewerDialogData {
  imgUrl: string;
  imgTitle: string;  
}

@Component({
  selector: 'app-image-quick-viewer',
  templateUrl: './image-quick-viewer.component.html',
  styleUrls: ['./image-quick-viewer.component.scss']
})
export class ImageQuickViewerComponent implements OnInit {

  imageUrl!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dlgdata: ImgViewerDialogData
  ) { }

  ngOnInit(): void {
    this.imageUrl = this.dlgdata.imgUrl;
    
  }

}
