import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        const currentUser = this.authenticationService.currentUserValue;
        

        if (currentUser) {
            console.log("canActivate:" + currentUser.user_name);
            
            // authorised so return true
            let isadmin = (currentUser.roles =='sysadmin');
            //console.log('Admin:', isadmin);
            if (state.url == '/checkin-log-review') {
                //console.log('Admin:', isadmin);
                return isadmin;
            }
            return true;
        }

        // if (currentUser && currentUser.user_name!="unknown" && currentUser.user_name!="undefined") {
        //     // authorised so return true
        //     currentUser.user
        //     let isadmin = (currentUser.roles =='sysadmin');
           
        //     // if (state.url == '/checkin-log-review') {
        //     //     //console.log('Admin:', isadmin);
        //     //     return isadmin;
        //     // }
        //     return true;
        // }

        // not logged in so redirect to login page with the return url
        // console.log("Out");
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}