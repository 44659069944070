<div class="container-lg">
    <h3 class="text-primary">Thông tin thuốc</h3>
    <form [formGroup]="userForm" >
        <div class="row">       
            <div class="col">

                <h4 *ngIf="!createNew && currDrugData" class="bold"> Nhập thông tin thuốc id : {{drugId}}
                    - {{currDrugData['name']}} </h4>
              <h4 class="bold" *ngIf="createNew"> Tạo mới thuốc </h4>

                <div *ngIf="catData"><strong>Nhóm thuốc: </strong> {{catData.category_name}}</div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidDrugName() }"> 
                    <label for="drug_name" class="control-label">Tên thuốc *</label>
                    <input type="text" formControlName="drug_name" class="form-control" 
                    [ngClass]="{ 'is-invalid': invalidDrugName() }" id="drug_name" name="drug_name" placeholder="Tên thuốc"  >
                    <p *ngIf="invalidDrugName()" id="drug_name_error" class="invalid-feedback">Tên thuốc không hợp lệ</p>
                </div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidIngredient() }"> 
                    <label for="ingredient" class="control-label">Thành phần thuốc *</label>
                    <input type="text" formControlName="ingredient" class="form-control" 
                    [ngClass]="{ 'is-invalid': invalidIngredient() }" id="ingredient" name="ingredient" placeholder="Thành phần thuốc"  >
                    <p *ngIf="invalidIngredient()" id="drug_name_error" class="invalid-feedback">Thành phần thuốc không hợp lệ</p>
                </div>
                <div>&nbsp;</div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidDrugForm() }"> 
                    <label for="form" class="control-label">Dạng đóng gói : &nbsp;</label>
                    <mat-radio-group aria-label="Select an option"  formControlName="form">
                        <mat-radio-button value="viên">Viên</mat-radio-button>
                        <mat-radio-button value="gói">Gói</mat-radio-button>
                    </mat-radio-group>

                    <!-- input type="text" formControlName="form" class="form-control" 
                    [ngClass]="{ 'is-invalid': invalidDrugForm() }" id="form" name="form" placeholder="Dạng đóng gói thuốc"  -->

                    <p *ngIf="invalidDrugForm()" id="drug_name_error" class="invalid-feedback">Dạng đóng gói thuốc không hợp lệ</p>
                </div>
                <div>&nbsp;</div>
                <div class="form-group" [ngClass]="{ 'has-danger': invalidDosage() }"> 
                    <label for="dosage" class="control-label">Liều dùng : &nbsp;</label>

                    <!-- mat-radio-group aria-label="Select an option"  formControlName="form">
                        <mat-radio-button value="viên">Viên</mat-radio-button>
                        <mat-radio-button value="gói">Gói</mat-radio-button>
                    </mat-radio-group -->

                    <input type="text" formControlName="dosage" class="form-control" 
                    [ngClass]="{ 'is-invalid': invalidDosage() }" id="dosage" name="dosage" placeholder="Liều dùng" >
                    <p *ngIf="invalidDosage()" id="drug_name_error" class="invalid-feedback">Liều dùng không hợp lệ</p>
                </div>

                <div>&nbsp;</div>
                


                <div class="form-group" [ngClass]="{ 'has-danger': invalidUsingTime() }"> 
                <label for="using_time" class="control-label">Thời gian dùng thuốc :&nbsp;</label>
                <mat-radio-group aria-label="Select an option"  formControlName="t4drugWhen">
                    <mat-radio-button value="0">Trước ăn</mat-radio-button>
                    <mat-radio-button value="1">Sau ăn</mat-radio-button>
                  </mat-radio-group>
                  &nbsp;
                
                  <mat-form-field appearance="fill">
                    <mat-label>Chọn bữa</mat-label>
                    <mat-select [formControl]="t4drugCtl" multiple>
                      <mat-select-trigger>
                        {{t4drugCtl.value ? t4drugList[t4drugCtl.value[0]] : ''}}
                        <span *ngIf="t4drugCtl.value?.length > 1" class="example-additional-selection">
                          (+{{t4drugCtl.value.length - 1}} {{t4drugCtl.value?.length === 2 ? 'other' : 'others'}})
                        </span>
                      </mat-select-trigger>
                      <mat-option *ngFor="let t4drug of t4drugList; index as i;" [value]="i">{{t4drug}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <!-- div class="form-group" [ngClass]="{ 'has-danger': invalidDuration() }"> 
                    <label for="duration" class="control-label">Thời điểm uống *</label>
                    <input type="text" formControlName="duration" class="form-control" 
                    [ngClass]="{ 'is-invalid': invalidDuration() }" id="duration" name="duration" placeholder="Liều lượng "  >
                    <p *ngIf="invalidDuration()" id="drug_name_error" class="invalid-feedback">Thời điểm dùng không hợp lệ</p>
                </div -->

                <div class="form-group" [ngClass]="{ 'has-danger': invalidRemark() }"> 
                    <label for="remark" class="control-label">Ghi chú</label>
                    <input type="text" formControlName="remark" class="form-control" 
                    [ngClass]="{ 'is-invalid': invalidRemark() }" id="remark" name="remark" placeholder="Ghi chú "  >
                    <p *ngIf="invalidRemark()" id="drug_name_error" class="invalid-feedback">Ghi chú dùng không hợp lệ</p>
                </div>
    
                <!-- div class="form-group" [ngClass]="{ 'has-danger': invalidIndex() }">
                    <label for="index" class="form-control-label">Index *</label>
                    <input type="text" formControlName="index" class="form-control" [ngClass]="{ 'is-invalid': invalidIndex() }" id="index" name="index" placeholder="Thứ tự">
                    <p *ngIf="invalidIndex()" class="invalid-feedback" id="index_error">Thứ tự không hợp lệ.</p>
                </div -->

                
                <!-- div class="form-group" [ngClass]="{ 'has-danger': invalidCreated_date() }">
                    <label for="created_date" class="form-control-label">Ngày tạo *</label>
                    <input type="text" formControlName="created_date" class="form-control" [ngClass]="{ 'is-invalid': invalidCreated_date() }" id="created_date" name="created_date" placeholder="Ngày tạo" >
                    <p *ngIf="invalidCreated_date()" id="created_date_error" class="invalid-feedback">Invalid created date.</p>
                </div -->

             
            </div>
        </div>
        <div>&nbsp;</div>

        <div class="row">  
            <div class="form-group has-error"> 
                <button type="submit" class="btn btn-primary" (click)="onSubmit()">
                    <i class="fa fa-check"></i> Cập nhật</button>   &nbsp;                 
                
               
                <button *ngIf="!callFromDrugMan" mat-raised-button class="btn btn-success" (click)="onGoBack()"><i class="fa fa-check"></i> Quay lại</button>&nbsp;         
            </div>   
        </div>
    </form>
</div>
