import { Component, OnInit } from '@angular/core';
import { LegendPosition, NgxChartsModule } from '@swimlane/ngx-charts';
import { DbconnectService } from 'src/app/dbconnect.service';

@Component({
  selector: 'app-rpt-gerdq',
  templateUrl: './rpt-gerdq.component.html',
  styleUrls: ['./rpt-gerdq.component.scss']
})
export class RptGERDQComponent implements OnInit {

   // options for charts
   gerdq_start = [
    {
      "name": "GERDQ<8",
      "value": 8940
    },
    {
      "name": "GERDQ>=8",
      "value": 5000
    }
  ];

  gerdq_lasttime = [
    {
      "name": "GERDQ<8",
      "value": 89400
    },
    {
      "name": "GERDQ>=8",
      "value": 5000
    }
  ];

   view:number[]  = [700, 400];

   gradient: boolean = true;
   showLegend: boolean = true;
   showLabels: boolean = true;
   isDoughnut: boolean = false;
   legendPosition : LegendPosition = LegendPosition.Below;


   colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  colorScheme1 = {    
    domain: ['#0d6efd', '#6610f2', '#d63384',
          '#dc3545', '#fd7e14', '#ffc107', '#198754', '#20c997', '#0dcaf0', '#adb5bd','#000']
  };

  colorScheme2 = {    
    domain: [
        //'#0d6efd', '#6610f2', '#6f42c1', '#d63384',
          '#dc3545', '#fd7e14', '#ffc107', '#198754', '#20c997', '#0dcaf0', '#adb5bd','#000']
  };

  constructor(
    private dbConnect:DbconnectService
    ) { }

  ngOnInit(): void {
  }

}
