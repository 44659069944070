import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import * as myGlobal from './global';
import { User } from './models/user';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  currentUser : User = new User();

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'x_access_key': myGlobal.x_access_key ,    
    'token'   : 'x'
  });
  options = { headers: this.headers };

  constructor(
      private http: HttpClient
    ) { }

  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
       // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
        // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
        // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getHttpOptions() {
    //let data = JSON.parse(localStorage.getItem('currentUser'));
    //console.log('DEBUG:getHttpOptions()->',JSON.stringify(data));
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x_access_key': myGlobal.x_access_key ,   
      // 'org-id' :  data.org_id,
      //'Authorization'   : 'Bearer ' + data['jwt'],
    });

    // console.log('Debug data:',JSON.stringify(headers));
   // this.orgId = data.org_id;

    return {headers}
    // let options = { headers: headers };

    // return  this.options;
  }

  //-----------------------------------------------------


  getUserCount(): Observable<any>{
      //const params = new HttpParams({fromString: 'name=term'});
      
      console.debug("Call:getUserCount");
      return this.http.get(myGlobal.api_endpoint + 'rpt.usercount' ,{responseType:'json'});
      //return this.http.request('GET', myGlobal.api_endpoint + 'rpt.usercount' , {responseType:'json'});
  }

  getGenderStat(): Observable<any>{    
    console.debug("Call:getGenderStat");
    return this.http.get(myGlobal.api_endpoint + 'rpt.genderstat' ,{responseType:'json'});    
  }

  getAgeStat(): Observable<any>{    
    console.debug("Call:getGenderStat");
    return this.http.get(myGlobal.api_endpoint + 'rpt.agestat' ,{responseType:'json'});    
  } 

}
