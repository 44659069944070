import { AfterViewInit } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LegendPosition, NgxChartsModule } from '@swimlane/ngx-charts';
import { DbconnectService } from 'src/app/dbconnect.service';
import { ReportingService } from 'src/app/reporting.service';


@Component({
  selector: 'app-rpt-common',
  templateUrl: './rpt-common.component.html',
  styleUrls: ['./rpt-common.component.scss']
})
export class RptCommonComponent implements OnInit,AfterViewInit  {

   userCount:any="";
   // options for charts
   gender = [
    {
      "name": "Nam",
      "value": 8940000
    },
    {
      "name": "Nữ",
      "value": 5000000
    },
    {
      "name": "Không xác định",
      "value": 7200000
    }
  ];

  ages = [
    {
      "name": "<= 20 tuổi",
      "value": 890
    },
    {
      "name": "21-30",
      "value": 500
    },
    {
      "name": "31-40",
      "value": 500
    },
    {
      "name": "41-50",
      "value": 500
    },
    {
      "name": "51-60",
      "value": 500
    },    
    {
      "name": ">=60",
      "value": 500
    },
    {
      "name": "Không xác định",
      "value": 72
    }
  ];

   view:number[]  = [700, 400];

   gradient: boolean = true;
   showLegend: boolean = true;
   showLabels: boolean = true;
   isDoughnut: boolean = false;
   legendPosition : LegendPosition = LegendPosition.Below;

  colorScheme1 = {    
    domain: ['#0d6efd', '#6610f2', '#d63384',
          '#dc3545', '#fd7e14', '#ffc107', '#198754', '#20c997', '#0dcaf0', '#adb5bd','#000']
  };

  colorScheme2 = {    
    domain: ['#0d6efd', '#6610f2', '#6f42c1', '#d63384',
          '#dc3545', '#fd7e14', '#ffc107', '#198754', '#20c997', '#0dcaf0', '#adb5bd','#000']
  };

  constructor(
    private dbConnect : DbconnectService,
    private reporting : ReportingService,
    // private route: ActivatedRoute,
    // private _location: Location,
    // private router: Router,
  ) { 
    //Object.assign(this, { single2 });
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void 
  {
      
    this.reporting
      .getUserCount()
      .subscribe({next:(data: [any]) => {
    //    console.debug(data);
        this.userCount = data[0]['cnt'];
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});

    this.reporting
      .getGenderStat()
      .subscribe({next:(data: [any]) => {
     //   console.debug(data);
        this.gender = data;
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});

    this.reporting
      .getAgeStat()
      .subscribe({next:(data: [any]) => {
      //  console.debug(data);
        this.ages = data;
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});

    
    

  }

  labelFormatting(value:any )
  {    

    let self: any = this; // this "this" will refer to the chart component (pun intented :))
    let data = self.series.filter((x: { name: any; }) => x.name == value); // chartData will be present in
                                                        // series along with the label

    if(data.length > 0) {
      return `${data[0].name}: [${data[0].value}]`;
    } else {
      return value;
    }
  }

}


