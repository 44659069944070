<!-- jqxSplitter #splitter [width]="getWidth()" [height]="getHeight()" [panels]="[{ size: 250 }]">
  <div id='first'>
    <app-cat-tree [catType]="catType" (selectCatId)="treeOnSelect($event)" (addNewItemCatId)="addNewItemCatId($event)">
    </app-cat-tree>
</div>
  <div id='second' -->

    <h2 *ngIf="catType==3" class="text-primary">Nhập thông tin tin tức</h2>
    <h2 *ngIf="catType==4" class="text-primary">Nhập thông tin video</h2>
    
    <table style="margin-top: 20px; width: 90%;margin-left: 10px;">
     
      <tr width="100%">
        <td style="text-align: left; width: 10%; margin-left: 20px;"> <b>Tiêu đề *:</b></td>
        <td style="text-align: left;width: 80%;">
          <jqxInput #newsTitle [height]="30" width="100%" [placeHolder]="'Nhập nội dung tiêu đề'" [minLength]="1">
          </jqxInput>
        </td>
      </tr>
      <tr width="100%">
        <td style="text-align: left; width: 10%; margin-left: 20px;"> <b>Mô tả:</b></td>
        <td style="text-align: left;width: 80%;">
          <jqxInput #newsDes [height]="30" width="100%" [placeHolder]="'Nhập nội dung mô tả'" [minLength]="2">
          </jqxInput> 
        </td>
      </tr>
      <tr width="100%">
        <td style="text-align: left; width: 10%; margin-left: 20px;"> <b>Link bài viết:</b></td>
        <td style="text-align: left;width: 80%;">
          <jqxInput #newsLinks [(ngModel)]="valueUrl" [height]="30" width="100%" [placeHolder]="'Nhập đường dẫn bài viết'" [minLength]="1" >
          </jqxInput>
        </td>
      </tr>
      <tr width="100%">
        <td style="text-align: left; width: 10%; margin-left: 20px;"> <b>Tag:</b></td>
        <td style="text-align: left;width: 80%;">
          <jqxInput  #newsTag [height]="30" width="100%" [placeHolder]="'Tag bài viết để hiển thị các bài viết liên quan'"
            [minLength]="1" ></jqxInput>
        </td>
      </tr>
      <tr width="100%">
        <td style="text-align: left; width: 10%; margin-left: 20px;"> <b>Loại tin tức:</b></td>
        <td style="text-align: left;width: 80%;">
          <jqxDropDownList  #newsCategoryDownList id="newsCategoryDownList" [width]='250' [height]='30' 
              [selectedIndex]='1' [source]="newsCategorySource" [autoDropDownHeight]="true" 
              >
          </jqxDropDownList >
        </td>
      </tr>
      <tr width="100%">
        <td style="text-align: left; width: 10%; margin-left: 20px;"> <b>Loại nội dung:</b></td>
        <td style="text-align: left;width: 80%;">
          <jqxDropDownList  #typeNewsComboBox [width]='250' [height]='30' [selectedIndex]='0' [source]="comboBoxTypeNewsSource" [autoDropDownHeight]="true" [disabled]="true">
          </jqxDropDownList >
        </td>
      </tr>
      <tr width="100%">
        <td style="text-align: left; width: 10%; margin-left: 20px;" colspan="2"> <b>Xem trước:</b></td>
        
      </tr>
    
    </table>
  
    <div #viewNewsContent></div>

    <div class="form-group has-error"> 
      <button mat-raised-button class="btn btn-primary" *ngIf="!createNew" (click)="onAddNews()">
          <i class="fa fa-check"></i>&nbsp; Cập nhật</button>   &nbsp;   
      <button mat-raised-button class="btn btn-primary" *ngIf="createNew" (click)="onAddNews()">
              <i class="fa fa-check"></i>&nbsp;Tạo mới</button>   &nbsp;                      
      <button mat-raised-button class="btn btn-success" (click)="onGoBack()"><i class="fa fa-angle-left"></i>&nbsp; Quay lại</button>&nbsp;
       
  </div>   

  <!-- /div>
</jqxSplitter -->