import { Injectable } from '@angular/core';

import { HttpClient , HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, min } from 'rxjs/operators';

import { User } from './models/user';
import * as myGlobal from './global'; 

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User|null>;
    public currentUser: Observable<User|null>;
    public userData !: User;

    headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'x_access_key': myGlobal.x_access_key ,
    });
    options = { headers: this.headers };

    constructor(private http: HttpClient) {
        console.log('Init!');
       // localStorage.removeItem('currentUser');
     //   console.log('Get from local storage:' ,localStorage.getItem('currentUser'));
        let localdata = localStorage.getItem('currentUser');     
     //   console.debug("DEBUG: localdata=" +localdata ) ;
        if(typeof localdata!=='undefined' &&  localdata !='undefined'){
            this.userData = JSON.parse(localdata + "");        
        }    
        if (JSON.stringify(this.userData)=='{}' || JSON.stringify(this.userData)=='[]') {
            this.userData = new User();
        }  
        // console.debug(this.userData);
        this.currentUserSubject = new BehaviorSubject<User|null>(this.userData);
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User|null {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        //let url = window.location.href.replace(/(^\w+:|^)\/\//, '');
        //console.log("Run to here! POST " + myGlobal.api_endpoint + 'dologin (' + username + "," + password +")");
        return this.http.post<any>(myGlobal.api_endpoint + 'dologin', { user_name: username, password  },this.options)
            .pipe(map(data => {
                // if (data.length<=0) {
                //     console.log('LOGIN FAILED!')    ;
                //     localStorage.setItem('currentUser', "{}");
                // } else {
                //     console.log('LOGIN OK!')    ;
                //     localStorage.setItem('currentUser', JSON.stringify(data[0]));
                // }
                //console.log('User:',data[0])
                // login successful if there's a jwt token in the response
                // if (data.result='nodata') return faile
                // if (user && user.token) {
                //     // store user details and jwt token in local storage to keep user logged in between page refreshes
                //     localStorage.setItem('currentUser', JSON.stringify(user));
                //     //this.currentUserSubject.next(user);
                // }
                
                localStorage.setItem('currentUser', JSON.stringify(data));

                this.currentUserSubject.next(data);
                
                return data;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        //this.currentUserSubject.next();
    }

    checkTimeOut(result:any, router:any): boolean {

        if (result.hasOwnProperty('result')) {
            console.log('Check timeout: result = ' + JSON.stringify(result));
    
            if (result.result=='timeout') {
                localStorage.removeItem('currentUser');
                this.currentUserSubject.next(null);
                router.navigate(['/login']);            
                alert("Time out! Please login again!");
                return false;
            }
        } 
        //router.navigate(['/login']);    
        return true;
    }
    
}