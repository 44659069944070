<div class="container-lg">
    <h4 class="text-primary">Thông tin phân nhóm </h4>
    <form [formGroup]="userForm" >
        <div class="row">       
            <div class="col">

                <h4 *ngIf="!createNew" class="bold"> Nhập thông tin phân nhóm id : {{catId}}
                    - {{catData['category_name']}} </h4>
              <h4 class="bold" *ngIf="createNew"> Tạo mới phân nhóm </h4>

                <div *ngIf="parentObj"><strong>Nhóm cha: </strong> {{parentObj['category_name']}}</div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidName() }"> 
                    <label for="category_name" class="control-label">Tên*</label>
                    <input type="text" formControlName="category_name" class="form-control" [ngClass]="{ 'is-invalid': invalidName() }" 
                            id="category_name" name="category_name" placeholder="Tên nhóm"  >
                    <p *ngIf="invalidName()" id="name_error" class="invalid-feedback">Tên không hợp lệ</p>
                </div>
    
                <div class="form-group" [ngClass]="{ 'has-danger': invalidRemark() }">
                    <label for="remark" class="form-control-label">Mô tả *</label>
                    <input type="text" formControlName="remark" class="form-control" [ngClass]="{ 'is-invalid': invalidRemark() }" 
                            id="remark" name="remark" placeholder="Mô tả">
                    <p *ngIf="invalidRemark()" class="invalid-feedback" id="remark_error">Mô tả.</p>
                </div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidImageUrl() }">
                    <label for="image" class="form-control-label">Image link* &nbsp;</label>
                    <input type="text" formControlName="image" class="form-control" [ngClass]="{ 'is-invalid': invalidImageUrl() }" 
                            id="image" name="image" placeholder="Image link">
                    <p *ngIf="invalidImageUrl()" class="invalid-feedback" id="img_error"> Giới tính không hợp lệ.</p>
                </div>

                <!-- div class="form-group" [ngClass]="{ 'has-danger': invalidCreated_date() }">
                    <label for="created_date" class="form-control-label">Ngày tạo *</label>
                    <input type="text" formControlName="created_date" class="form-control" [ngClass]="{ 'is-invalid': invalidCreated_date() }" id="created_date" name="created_date" placeholder="Ngày tạo" disabled>
                    <p *ngIf="invalidCreated_date()" id="created_date_error" class="invalid-feedback">Invalid created date.</p>
                </div -->

                <!-- div class="form-group" [ngClass]="{ 'has-danger': invalidUpdated_date() }">
                    <label for="updated_date" class="form-control-label">Ngày tạo *</label>
                    <input type="text" formControlName="updated_date" class="form-control" [ngClass]="{ 'is-invalid': invalidUpdated_date() }" id="updated_date" name="updated_date" placeholder="Ngày tạo" disabled>
                    <p *ngIf="invalidUpdated_date()" id="updated_date_error" class="invalid-feedback">Invalid updated date.</p>
                </div -->

              
            </div>
        </div>


        <div class="row">  
            <div class="form-group has-error"> 
                <button type="submit" class="btn btn-primary" *ngIf="!createNew" (click)="onSubmit()">
                    <i class="fa fa-check"></i> Cập nhật</button>   &nbsp;   
                <button type="submit" class="btn btn-primary" *ngIf="createNew" (click)="onSubmit()">
                        <i class="fa fa-check"></i>Tạo mới</button>   &nbsp;                
                
                <button mat-raised-button class="btn btn-success" (click)="onGoBack()"><i class="fa fa-check"></i> Quay lại</button>&nbsp;         
            </div>   
        </div>
    </form>
</div>

<!-- {{quest_list|json}} -->
