<jqxTree #jqxTree  [source]="treerecords" height="100%" (onSelect)="treeOnSelect($event)" (contextmenu)="onTreeRightClick($event)" >
</jqxTree>
  <jqxMenu #treeMenu (onItemclick)="treeMenuOnItemClick($event)"
    [width]="160" [height]="220" [autoOpenPopup]="false" [mode]="'popup'" class="shadow p-2 mb-3 bg-white rounded">
      <ul>
          <li><i class="fa fa-plus text-primary"></i> Add SubItem</li>
          <li type="separator"></li>
          <li><i class="fa fa-folder text-primary"></i> New Category</li>      
          <li><i class="fa fa-cog text-primary"></i> Edit Category</li>   
          <li type="separator"></li>                   
          <li><i class="fa fa-arrow-up text-success" ></i > Move Up</li>
          <li><i class="fa fa-arrow-down text-success" ></i> Move Down</li>
          <li><i class="fa fa-arrow-right text-success"></i> Move In</li>
          <li><i class="fa fa-arrow-left text-success" ></i> Move Out</li>
          <li type="separator"></li>
          <li><i class="fa fa-eraser text-danger"></i> Delete Category</li>
      </ul>
  </jqxMenu>
