// import { HttpClient } from '@angular/common/http';
// import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent,HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import * as myGlobal from './global';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/xml',
    'Authorization': 'jwt-token'
  })
};

@Injectable({
  providedIn: 'root'
})


export class UploadService {
  constructor(private http: HttpClient) {}

  uploadFile(file: File): Observable<any>{
    const data = new FormData()
    data.append('file', file)
   //return this.http.post('/api/upload', data)
    
    console.log("Call:uploadFile");
    console.log(data);
    //return this.http.post(myGlobal.api_endpoint + 'upload' ,data);

    return this.http.post<any>("http://localhost:9001" + myGlobal.api_endpoint + 'upload', data, {  
      reportProgress: true,  
      observe: 'events'  
    }); 
  }

  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', myGlobal.api_endpoint + 'upload', formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  getFiles(): Observable<any> {
    return this.http.get( myGlobal.api_endpoint + 'files');
  }
}