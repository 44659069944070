import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UploadService } from 'src/app/upload.service';
import { FileSelectDirective, FileDropDirective, FileUploader } from 'ng2-file-upload';

import { DbconnectService } from 'src/app/dbconnect.service';
import * as myGlobal from '../../global';

const URL = myGlobal.api_endpoint + 'upload';
//const URL = 'http://localhost:9001/api/upload';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

  uploader:FileUploader;
  hasBaseDropZoneOver:boolean;
  hasAnotherDropZoneOver:boolean;
  response:string;

  constructor(private uploads: UploadService) { 
    
    this.uploader = new FileUploader({
      url: URL,
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item:any) => {
        return new Promise( (resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });
 
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;
 
    this.response = '';
 
    this.uploader.response.subscribe( res => this.response = res );
  }

  ngOnInit(): void {
  }

  

  ngOnDestroy() {
    //this.subscription?.unsubscribe()
  }
 
  public fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e;
  }
 
  public fileOverAnother(e:any):void {
    this.hasAnotherDropZoneOver = e;
  }

}

//https://valor-software.com/ng2-file-upload/