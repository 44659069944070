import { Component, OnInit } from '@angular/core';
import { DbconnectService } from 'src/app/dbconnect.service';

@Component({
  selector: 'app-rpt-export',
  templateUrl: './rpt-export.component.html',
  styleUrls: ['./rpt-export.component.scss']
})
export class RptExportComponent implements OnInit {

  constructor(
    private dbConnect:DbconnectService
    ) { }

  ngOnInit(): void {
  }

}
