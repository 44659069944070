import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {FoodManComponent} from './gerd-monitor/food-man/food-man.component';
import {CatTreeComponent} from './utils/cat-tree/cat-tree.component';
import {UploadFileComponent} from './utils/upload-file/upload-file.component';
import {UploadImageComponent} from './utils/upload-image/upload-image.component';
import {CategoryDetailComponent} from './utils/category/category-detail.component';
import {FoodDetailComponent} from './gerd-monitor/food-detail/food-detail.component';
import {ImageGalleryComponent} from './utils/image-gallery/image-gallery.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import { FilterQuestionManComponent } from './question/filter-question-man/filter-question-man.component';
import { FilterQuestionComponent } from './question/filter-question/filter-question.component';
import {CommentQuestManComponent} from './comment/comment-quest-man/comment-quest-man.component';
import { NewsEditorComponent } from './gerd-news/news-editor/news-editor.component';
import { NewsListComponent } from './gerd-news/news-list/news-list.component';
import { VideoListComponent } from './gerd-news/video-list/video-list.component';
import { NotifyListComponent } from './gerd-news/notify-list/notify-list.component';
import { UserListComponent } from './system/user-list/user-list.component';
import { UserInfoComponent } from './system/user-info/user-info.component';
import { SystemLogComponent } from './system/system-log/system-log.component';
import { LoginFormComponent } from './login-form/login-form.component'; 
import { AuthGuard } from './auth.guard';
import { DrugManComponent } from './gerd-monitor/drug-man/drug-man.component';
import { PatientManComponent } from './patient/patient-man/patient-man.component';
import { DrugDetailComponent } from './gerd-monitor/drug-detail/drug-detail.component';
import { UserProfileComponent } from './system/user-profile/user-profile.component';
import { RptCommonComponent } from './report/rpt-common/rpt-common.component';
import { RptGERDQComponent } from './report/rpt-gerdq/rpt-gerdq.component';
import { RptSymptonComponent } from './report/rpt-sympton/rpt-sympton.component';
import { RptDrugUsageComponent } from './report/rpt-drug-usage/rpt-drug-usage.component';
import { RptExportComponent } from './report/rpt-export/rpt-export.component';

import { ExpGerdComponent } from './report/exp-gerd/exp-gerd.component';
import { ExpDailyComponent } from './report/exp-daily/exp-daily.component';
import { ExpFoodComponent } from './report/exp-food/exp-food.component';
import { ExpDrugComponent } from './report/exp-drug/exp-drug.component';
import { NotifyEditorComponent } from './gerd-news/notify-editor/notify-editor.component';
import { ImageSelectorComponent } from './utils/image-selector/image-selector.component';
import { ImageManComponent } from './utils/image-man/image-man.component';
import { ChangePasswordComponent } from './system/change-password/change-password.component';

const routes: Routes = [

  { path: 'login', component: LoginFormComponent },
  
  
  { path: 'patient-man', component: PatientManComponent , canActivate: [AuthGuard]},
  
  { path: 'drug/:category_id/:drugId', component: DrugDetailComponent , canActivate: [AuthGuard]},
  { path: 'drug-man', component: DrugManComponent , canActivate: [AuthGuard]},
  { path: 'food-man', component: FoodManComponent , canActivate: [AuthGuard]},
  { path: 'foodtree', component: CatTreeComponent , canActivate: [AuthGuard]},
  { path: 'upload', component: UploadFileComponent , canActivate: [AuthGuard]},
  { path: 'cat-detail/:catType/:catId/:parentId', component: CategoryDetailComponent , canActivate: [AuthGuard]},
  { path: 'food-detail/:catId/:foodId', component: FoodDetailComponent , canActivate: [AuthGuard] },

  { path: 'questanswer-man', component: CommentQuestManComponent, canActivate: [AuthGuard] },
  
  { path: 'filterquest-man', component: FilterQuestionManComponent , canActivate: [AuthGuard]},  
  { path: 'filterquest/:questId/:parentId/:idsIndex', component: FilterQuestionComponent, canActivate: [AuthGuard]},
  
  { path: 'gallery', component: ImageGalleryComponent, canActivate: [AuthGuard]},
  { path: 'img-man', component: ImageManComponent, canActivate: [AuthGuard]},

  { path: 'dashboard', component: DashboardComponent , canActivate: [AuthGuard]},

  { path: 'user-man', component: UserListComponent , canActivate: [AuthGuard]},
  { path: 'user-info/:userId', component: UserInfoComponent , canActivate: [AuthGuard]},
  { path: 'user-profile', component: UserProfileComponent , canActivate: [AuthGuard]},
  { path: 'change-pass/:userId', component: ChangePasswordComponent},
  { path: 'filterquest-man', component: FilterQuestionManComponent, canActivate: [AuthGuard]},
  { path: 'filterquest/:questId/:parentId/:idsIndex', component: FilterQuestionComponent , canActivate: [AuthGuard]},
  { path: 'system-log', component: SystemLogComponent , canActivate: [AuthGuard]},
  { path: 'news-editor/:catType/:catId/:newsId', component: NewsEditorComponent , canActivate: [AuthGuard]},
  { path: 'news-list', component: NewsListComponent , canActivate: [AuthGuard]},
  { path: 'video-editor/:catType/:catId/:newsId', component: NewsEditorComponent , canActivate: [AuthGuard]},
  { path: 'video-list', component: VideoListComponent , canActivate: [AuthGuard]},
  { path: 'notify-editor/:notifyId', component: NotifyEditorComponent , canActivate: [AuthGuard]},
  { path: 'notify-list', component: NotifyListComponent , canActivate: [AuthGuard]},


  //----------- report forms ---------
  { path: 'rpt-common', component: RptCommonComponent , canActivate: [AuthGuard]},
  { path: 'rpt-gerdq', component: RptGERDQComponent , canActivate: [AuthGuard]},
  { path: 'rpt-sympton', component: RptSymptonComponent , canActivate: [AuthGuard]},
  { path: 'rpt-drug', component: RptDrugUsageComponent , canActivate: [AuthGuard]},
  { path: 'rpt-export', component: RptExportComponent , canActivate: [AuthGuard]},

  { path: 'exp-gerd', component: ExpGerdComponent , canActivate: [AuthGuard]},
  { path: 'exp-daily', component: ExpDailyComponent , canActivate: [AuthGuard]},
  { path: 'exp-food', component: ExpFoodComponent , canActivate: [AuthGuard]},
  { path: 'exp-drug', component: ExpDrugComponent , canActivate: [AuthGuard]},

  
  //----------------------------------
  
  { path: '',component: DashboardComponent},
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
