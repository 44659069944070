import { Component, OnInit } from '@angular/core';
import {XlsExportService} from '../../utils/xls-export.service';
import * as XLSX from "xlsx";
import { DbconnectService } from 'src/app/dbconnect.service';


@Component({
  selector: 'app-exp-gerd',
  templateUrl: './exp-gerd.component.html',
  styleUrls: ['./exp-gerd.component.scss']
})
export class ExpGerdComponent implements OnInit {

  gerdReportData: any = [];

  constructor(
    private dbConnect:DbconnectService,
    private exportService: XlsExportService) { }

  ngOnInit(): void {
  }

  export() {
    this.dbConnect.sysLog('ExpGerdComponent','Export','Template=' +  'GERDReport');
    this.exportService.exportToExcel("ExportTable", 'GERDReport');    
  }

}
