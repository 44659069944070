<jqxSplitter #splitter
             [width]="getWidth()" [height]="getHeight()"
             [panels]="[{ size: 250 }]">
  <div>   
    <app-cat-tree 
      [catType]="2" 
      (selectCatId)="treeOnSelect($event)"
      (addNewItemCatId)="addNewItemCatId($event)"
    ></app-cat-tree>  
      
     </div>
  <div>
    <jqxSplitter #secondNested class="jqx-splitter-nested"
    [width]="'100%'" [height]="'100%'" [orientation]="'horizontal'"
    [panels]="[{ size: '50%' }, { size: '50%' }]">
        <div  #GridPanel>
            <jqxGrid #jqxgrid
            (onRowselect)="rowselect($event)"
            [height]="'100%'" [width]="'100%'"
            [source]="gridsource"
            [columnsresize]="true"
            [showtoolbar]="true"
            [rendertoolbar]="createToolbar"
            [pageable]="true" 
            [autoheight]="false" 
            [sortable]="true" 
            [columns]="gridcolumns">
            </jqxGrid>
            
        </div>
        <div  #ContentPanel class="container contentArea">
         
            <div class="row overflow-auto">
              <app-drug-detail
                [drugId]="currDrugId"
                [catId] = "currCatId"
              ></app-drug-detail>
            </div>
          
        </div>
        </jqxSplitter>
  </div>
</jqxSplitter>


