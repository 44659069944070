import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { User } from "../models/user";
import * as myGlobal from "../global";
import { AuthenticationService } from "../authentication.service";
import { first } from "rxjs/operators";
import {DbconnectService} from '../dbconnect.service';

@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
})
export class LoginFormComponent implements OnInit {
  loginForm!: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl!: string;

  status: string = "";

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dbConnection:DbconnectService,
    private authenticationService: AuthenticationService
  ) {
    let  currentUser = this.authenticationService.currentUserValue;
    // if (currentUser && currentUser.user_name!="unknown" && currentUser.user_name!="undefined") {
    //   this.router.navigate(["/"]);
    // }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService
      .login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        (data) => {

          // if (data.length>0) {
          //   this.router.navigate([this.returnUrl]);
          //   localStorage.setItem("currentUser", JSON.stringify(data[0]));
          //   this.dbConnection.sysLog('Login','Login OK!',this.f.username.value  );
          // } else {
          //   this.status = "Login failed!";
          // }
          
          if (data===null) {
            this.status = "Login failed!";
          } else {
            if (data['result']=='ok') {
              this.router.navigate([this.returnUrl]);
             // console.log("Login OK");
              this.status = "Login OK!";
              //console.debug('DEBUG user=',JSON.stringify(data));
              localStorage.setItem('currentUser', JSON.stringify(data));
              this.dbConnection.sysLog('Login','Login OK!',this.f.username.value  );
            } else {
              this.status = "Login Failed! " + data['result'] ;
              localStorage.setItem('currentUser', '{}');
            }
          }

        },
        (error) => {
          //this.alertService.error(error);
          console.log(error);
          this.loading = false;
          //console.log("Login FAILED");
          this.status = "Login failed! :" + error.error.message;
          //this.f.password.setValue('');
          localStorage.setItem("currentUser", "{}");
          this.dbConnection.sysLog('Login','Login Failed!',this.f.username.value+": Exception=" + error.error.message);
        }
      );
  }
}

//https://stackblitz.com/edit/angular-7-registration-login-example?file=app%2Fapp.routing.ts
