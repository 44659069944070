import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import * as myGlobal from '../../global'; 
import { DbconnectService } from 'src/app/dbconnect.service';
import {Router} from '@angular/router';
import { AuthenticationService } from 'src/app/authentication.service';

@Component({
  selector: 'app-image-upload-preview',
  templateUrl: './image-upload-preview.component.html',
  styleUrls: ['./image-upload-preview.component.scss']
})
export class ImageUploadPreviewComponent implements OnInit {

  public uploader!: FileUploader;
  public hasDragOver = false;

  img_data!: string;
  json_obj!: object;
  orgId!: number;

  //@Input()
  public smallsize = true;

  //@Input()
  public url = '';

  @Output() fileName = new EventEmitter<string>();

  getHttpOptions() {
    let data = JSON.parse(localStorage.getItem('currentUser')+"");
    this.orgId = data.org_id;
    return  ;//this.options;
  }

  getUserId() {
    if (this.authenticationService.currentUserValue)
      return this.authenticationService.currentUserValue.user_id;
    else 
      return -1;
  }

  constructor(
    private dbConnect:DbconnectService,
    private router: Router,
    private authenticationService: AuthenticationService
    ) {
  
  }

  // private updateImage(userID) {
  //   this.rest.getUserAvatar(userID).subscribe((data) => {
  //     this.jsonobj = JSON.parse(data[0].content );      
  //   }, (err) => {
  //     console.log(err);
  //   });
  // }

  public fileOver(e: any): void {
    this.hasDragOver = e;
  }

  ngOnInit() {

    this.uploader = new FileUploader({
      url: myGlobal.api_imgupload_url ,//+ this.getUserId(), 
      disableMultipart: false,
      autoUpload: true
    });
 

    this.uploader.response.subscribe(res => {
      // Upload returns a JSON with the image ID
      this.url = myGlobal.api_imgupload_url + JSON.parse(res).file;//+ JSON.parse(res).id;
      console.log("Upload success: " + JSON.parse(res).file);
      this.img_data = JSON.parse(res).imgdata_base64;

      this.fileName.emit(JSON.parse(res).file);

      // result: 'ok',
      // file: req.file.originalname,
      // imgdata_base64: utils.base64_encode( req.path)

    });
    
  }

}
