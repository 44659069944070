<jqxSplitter #splitter [width]="getWidth()" [height]="getHeight()" [panels]="[{ size: 250 }]">
    <div id='first'>
        <app-cat-tree [catType]="4" (selectCatId)="treeOnSelect($event)" (addNewItemCatId)="addNewItemCatId($event)">
        </app-cat-tree>
    </div>
    <div id='second'>
        <!--jqxDataTable
    [width]="getWidth()" [height]="getHeight()" [selectionMode]="'singleRow'"
    [altRows]="true" [sortable]="true" [editable]="true">
    [source]="dataAdapter"
</jqxDataTable-->
        <jqxGrid #jqxgrid 
            (onRowselect)="rowselect($event)" 
            (onCellendedit)="onCellEndEdit($event)"
            [height]="'100%'" [width]="'100%'" 
            [columnsresize]="true" 
            [showtoolbar]="true" 
            [rendertoolbar]="createToolbar" 
            [pageable]="true"
            [autoheight]="true" 
            [sortable]="true" 
            [columns]="columns" 
            [source]="griddataAdapter" 
            [rowsheight]="50"
            [pagesize]="50"
            [editable]="true"
            [enabletooltips]="true"
            [pagesizeoptions]="['50', '100', '200']">
            >

           
        </jqxGrid>
    </div>
</jqxSplitter>