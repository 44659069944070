import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import * as myGlobal from './global';
import { User } from './models/user';

@Injectable({
  providedIn: 'root'
})
export class DbconnectService {
  // this.orgId = data.org_id;
  
  currentUser : User = new User();

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'x_access_key': myGlobal.x_access_key ,    
    'token'   : 'x'
  });
  options = { headers: this.headers };

  constructor(
      private http: HttpClient
    ) { }

  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
       // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
        // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
        // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getHttpOptions() {
    //let data = JSON.parse(localStorage.getItem('currentUser'));
    //console.log('DEBUG:getHttpOptions()->',JSON.stringify(data));
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x_access_key': myGlobal.x_access_key ,   
      // 'org-id' :  data.org_id,
      //'Authorization'   : 'Bearer ' + data['jwt'],
    });

    // console.log('Debug data:',JSON.stringify(headers));
   // this.orgId = data.org_id;

    return {headers}
    // let options = { headers: headers };

    // return  this.options;
  }

  //-----------------------------------------------------

  getOrgId() {
  
    console.debug("current_user" + localStorage.getItem('currentUser'));

    if (localStorage.getItem('currentUser')) {
      let data = JSON.parse(""+localStorage.getItem('currentUser'));    
      return data.org_id;
    } else {
      return 0;
    }
  }
  

  sysLog(menu:string,action:string,content:string) {
    let currdata = localStorage.getItem('currentUser') +"";

    if (currdata == "{}" || currdata == "[]" || currdata == "undefined"  ) {
      this.currentUser = new User();
    } else {
      this.currentUser = JSON.parse(currdata );
     
    }

    if (!this.currentUser) {
      this.currentUser = new User();
      // console.log('Create new');
    }  

    // console.log('currentUser= ' + JSON.stringify(this.currentUser));
    // console.log(this.currentUser);
    // console.log({
    //   user_id: this.currentUser.user_id,
    //   user_name : this.currentUser.user_name,
    //   action: action,
    //   menu: menu,
    //   content:content,
    // });
    this.http.post(myGlobal.api_endpoint + 'systemlog',
      {
        user_id: this.currentUser.user_id,
        user_name : this.currentUser.user_name,
        action: action,
        menu: menu,
        content:content,
      }
      ,  this.getHttpOptions()).pipe(
        tap(_ => console.log(`updateSystemLog`)),
        catchError(this.handleError<any>('newOrgData'))
      ).subscribe({next:(data: []) => {
        console.log(JSON.stringify(data));
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }});

   }

  // getChildFoodCatByID(catId:string): Observable<any> {
  //   // console.log('Call backend: ' +myGlobal.api_endpoint_2 + 'patient_byphone:' + telephone ) ;
  //    return this.http.get(myGlobal.api_endpoint + 'food_cat/' + catId, this.getHttpOptions()).pipe(
  //      //tap(_ => console.log(`Call patient-byphone`)),
  //      map(this.extractData)
  //      );
  //  }


  
  getAllCatList(type:number): Observable<any>{
    //const params = new HttpParams({fromString: 'name=term'});
    console.debug("Call:getAllCatList");
    return this.http.request('GET', myGlobal.api_endpoint + 'cat/' + type , {responseType:'json'});
  }

  getChildCatByID(catId: string): Observable<any>{
    //const params = new HttpParams({fromString: 'name=term'});
    console.debug("Call:getChildCatByID");
    return this.http.request('GET', myGlobal.api_endpoint + 'cat_children/' + catId, {responseType:'json'});
  }

  getFoodListByCatId(catId: string): Observable<any>{
    //const params = new HttpParams({fromString: 'name=term'});
    console.debug("Call:getFoodListByCatId");
    return this.http.request('GET', myGlobal.api_endpoint + 'foodlst_bycatid/' + catId , {responseType:'json'});
  }

  getUserFoodList(catId: string): Observable<any>{
    //const params = new HttpParams({fromString: 'name=term'});
    console.debug("Call:getUserFoodList");
    return this.http.request('GET', myGlobal.api_endpoint + 'userfoodlst' , {responseType:'json'});
  }
  
  checkCatHasChild(catId: string): Observable<any>{
    //const params = new HttpParams({fromString: 'name=term'});
    console.debug("Call:checkCatHasChild");
    //return this.http.request('GET', myGlobal.api_endpoint + 'cat_haschild/' + catId , {responseType:'json'});
    return this.http.request('GET', myGlobal.api_endpoint + 'cat_children/' + catId , {responseType:'json'});
  }
  
  delCatById(catId: string): Observable<any>{
    //const params = new HttpParams({fromString: 'name=term'});
    console.debug("Call:delCatById");
    return this.http.delete( myGlobal.api_endpoint + 'cat/' + catId , {responseType:'json'});
  }

  addCat(body: any): Observable<any> {
    console.debug("Call:addCat");
    return this.http.post(myGlobal.api_endpoint + 'entity/category' , body, {responseType:'json'});
  }

  updateCat(catId: string, body: any): Observable<any> {
    console.debug("Call:updateCat");
    return this.http.patch(myGlobal.api_endpoint + 'entity/category/' + catId , body,{responseType:'json'});
  }

  getImgGallery(searchText: string): Observable<any> {    
    console.debug("Call:getImgGallery");
    return this.http.request('GET', myGlobal.api_endpoint + 'img_gallery/' + searchText , {responseType:'json'});
  }

  getCatDataByID(catId: string): Observable<any>{
    //const params = new HttpParams({fromString: 'name=term'});
    console.debug("Call:getCatDataByID");
    return this.http.request('GET', myGlobal.api_endpoint + 'catinfo/' + catId, {responseType:'json'});
  }

  getFoodDetailById(foodId: string): Observable<any> {
    console.debug("Call:getFoodDetailById");
    return this.http.request('GET', myGlobal.api_endpoint + 'food/' + foodId , {responseType:'json'});
  }

  addFoodData(body: any): Observable<any> {
    console.debug("Call:addFoodData");
    return this.http.post(myGlobal.api_endpoint + 'entity/food' , body, {responseType:'json'});
  }

  updateFoodData(foodId: string, body: any): Observable<any> {
    console.debug("Call:updateFoodData");
    return this.http.patch(myGlobal.api_endpoint + 'entity/food/' + foodId , body,{responseType:'json'});
  }

  delFoodById(foodId: string): Observable<any> {
    console.debug("Call:delFoodById");
    return this.http.delete(myGlobal.api_endpoint + 'food/' + foodId);
  }

  delUserFoodById(foodId: string): Observable<any> {
    console.debug("Call:delUserFoodById");
    return this.http.delete(myGlobal.api_endpoint + 'entity/usr_favor_food/' + foodId);
  }

  patchUserFoodById(foodId: string, body:any): Observable<any> {
    console.debug("Call:delUserFoodById");
    return this.http.patch(myGlobal.api_endpoint + 'entity/usr_favor_food/' + foodId, body, {responseType:'json'});
  }

  
  //-------------------------------------------------------
  getDrugList(filterType:string): Observable<any> {
    console.debug('Call getDrugList: ' +myGlobal.api_endpoint + 'drug' ) ;
    return this.http.get(myGlobal.api_endpoint + 'drug' ,{responseType:'json'});
  }

  getDrugById(questId:string): Observable<any> {
    console.debug('Call getDrugById: ' +myGlobal.api_endpoint + 'drug' ) ;
    return this.http.get(myGlobal.api_endpoint + 'drug/' + questId,{responseType:'json'});
  }

  addDrug(body:any): Observable<any> {
    console.debug('Call addDrug: ' +myGlobal.api_endpoint + 'drug' ) ;
    return this.http.post(myGlobal.api_endpoint + 'entity/drug' , body,{responseType:'json'});
  }

  updateDrug(questId:string,body:any): Observable<any> {
    console.debug('Call updateDrug: ' +myGlobal.api_endpoint + 'drug/' + questId) ;
    return this.http.patch(myGlobal.api_endpoint + 'entity/drug/' + questId , body,{responseType:'json'});
  }

  deleteDrug(drugId:string): Observable<any> {
    console.debug('Call deleteDrug: ' +myGlobal.api_endpoint + 'drug/' + drugId) ;
    return this.http.delete(myGlobal.api_endpoint + 'drug/' + drugId ,{responseType:'json'});
  }

  getDrugListByCatId(catId: string): Observable<any>{
    //const params = new HttpParams({fromString: 'name=term'});
    console.debug("Call:getDrugListByCatId");
    return this.http.request('GET', myGlobal.api_endpoint + 'druglst_bycatid/' + catId , {responseType:'json'});
  }

  //-------------------------------------------------------
  getFilterQuestionList(filterType:string): Observable<any> {
      console.debug('Call getFilterQuestionList: ' +myGlobal.api_endpoint + 'question' ) ;
      return this.http.get(myGlobal.api_endpoint + 'question' ,{responseType:'json'});
  }
  
  getFilterQuestionById(questId:string): Observable<any> {
    console.debug('Call getFilterQuestionById: ' +myGlobal.api_endpoint + 'question' ) ;
    return this.http.get(myGlobal.api_endpoint + 'question/' + questId,{responseType:'json'});
  }

  addFilterQuestion(body:any): Observable<any> {
    console.debug('Call addFilterQuestion: ' +myGlobal.api_endpoint + 'question' ) ;
    return this.http.post(myGlobal.api_endpoint + 'entity/question' , body,{responseType:'json'});
  }

  updateFilterQuestion(questId:string,body:any): Observable<any> {
    console.debug('Call updateFilterQuestion: ' +myGlobal.api_endpoint + 'question/' + questId) ;
    return this.http.patch(myGlobal.api_endpoint + 'entity/question/' + questId , body,{responseType:'json'});
  }

  deleteFilterQuestion(questId:string): Observable<any> {
    console.debug('Call deleteFilterQuestion: ' +myGlobal.api_endpoint + 'question/' + questId) ;
    return this.http.delete(myGlobal.api_endpoint + 'question/' + questId ,{responseType:'json'});
  }

  
  getCommentQuest(): Observable<any> {
    console.debug('Call getCommentQuest: ' +myGlobal.api_endpoint + 'entity/comment_question' ) ;
    return this.http.get(myGlobal.api_endpoint + 'entity/comment_question' ,{responseType:'json'});
  }

  updateCommentQuest(questId:string,body:any): Observable<any> {
    console.debug('Call updateCommentQuest: ' +myGlobal.api_endpoint + 'entity/comment_question/' + questId) ;
    return this.http.patch(myGlobal.api_endpoint + 'entity/comment_question/' + questId , body,{responseType:'json'});
  }

  approveCommentQuestion(questId:string,body:any): Observable<any> {
    console.debug('Call approveCommentQuestion: ' +myGlobal.api_endpoint + 'approveCommentQuestion/' + questId) ;
    return this.http.patch(myGlobal.api_endpoint + 'approveCommentQuestion/' + questId , body,{responseType:'json'});
  }

  getNotifies(): Observable<any> {
    console.debug('Call getNotifies: ' +myGlobal.api_endpoint + 'entity/notify' ) ;
    return this.http.get(myGlobal.api_endpoint + 'entity/notify' ,{responseType:'json'});
  }

  approveNotify(notifyId:string,body:any): Observable<any> {
    console.debug('Call approveNotify: ' +myGlobal.api_endpoint + 'approveNotify/' + notifyId) ;
    return this.http.patch(myGlobal.api_endpoint + 'approveNotify/' + notifyId , body,{responseType:'json'});
  }
  
  addNotify(body:any): Observable<any> {
    console.debug('Call addNotify: ' +myGlobal.api_endpoint + 'notify' ) ;
    return this.http.post(myGlobal.api_endpoint + 'entity/notify' , body,{responseType:'json'});
  }

  updateNotify(notifyId:string,body:any): Observable<any> {
    console.debug('Call updateNotify: ' +myGlobal.api_endpoint + 'entity/notify/' + notifyId) ;
    return this.http.patch(myGlobal.api_endpoint + 'entity/notify/' + notifyId , body,{responseType:'json'});
  }

  getNewsCategory(): Observable<any> {
    console.debug('Call getNewsCategory: ' +myGlobal.api_endpoint + 'NewsCategory' ) ;
    return this.http.get(myGlobal.api_endpoint + 'entity/news_category' ,{responseType:'json'});
  }
  addNews(body:any): Observable<any> {
    console.debug('Call addNews: ' +myGlobal.api_endpoint + 'News' ) ;
    return this.http.post(myGlobal.api_endpoint + 'entity/news' , body,{responseType:'json'});
  }
  updateNews(newsId:string,body:any): Observable<any> {
    console.debug('Call updateNews: ' +myGlobal.api_endpoint + 'News/' + newsId) ;
    return this.http.patch(myGlobal.api_endpoint + 'entity/news/' + newsId , body,{responseType:'json'});
  }
 
  getNewsList(): Observable<any> {
    console.debug('Call getNewsList: ' +myGlobal.api_endpoint + 'getNewsList' ) ;
    return this.http.get(myGlobal.api_endpoint + 'entity/news' ,{responseType:'json'});
  }


  getUserProfile(id:string): Observable<any> {
    console.debug('Call getUserProfile: ' +myGlobal.api_endpoint + 'user_profile' ) ;
    return this.http.get(myGlobal.api_endpoint + 'user_profile/' + id ,{responseType:'json'});
  }

  deleteUserProfile(id:string): Observable<any> {
    console.debug('Call deleteUserProfile: ' +myGlobal.api_endpoint + 'user_profile' ) ;
    return this.http.delete(myGlobal.api_endpoint + 'user_profile/' + id ,{responseType:'json'});
  }

  getUserList(): Observable<any> {
      console.debug('Call getUserList: ' +myGlobal.api_endpoint + 'users' ) ;
      return this.http.get(myGlobal.api_endpoint + 'users?_sort=username');
    }
  
  
  getUserInfo(userId:string): Observable<any> {
    console.debug('Call getUserInfo: ' +myGlobal.api_endpoint + 'users' ) ;
    return this.http.get(myGlobal.api_endpoint + 'users/' + userId);
  }
  
  deleteUserById(userId:string): Observable<any> {
    console.debug('Call deleteUserById: ' +myGlobal.api_endpoint + 'users' ) ;
    return this.http.delete(myGlobal.api_endpoint + 'users/' + userId);
  }

  updateUserInfo(userId:string, data:any): Observable<any> {
    console.debug('Call updateUserInfo: ' +myGlobal.api_endpoint + 'users' ) ;
    return this.http.patch(myGlobal.api_endpoint + 'entity/users/' + userId, data );
  }

  createUser( data:any): Observable<any> {
    console.debug('Call createUser: ' +myGlobal.api_endpoint + 'users' ) ;
    return this.http.post(myGlobal.api_endpoint + 'entity/users', data );
  }

  getSystemLog(filterType:any): Observable<any> {
    console.debug('Call getSystemLog: ' +myGlobal.api_endpoint + 'systemLog' ) ;
    return this.http.get(myGlobal.api_endpoint + 'systemLog');
  }

  updateUserProfile (id:string, profileData:any): Observable<any> {
    console.debug('Call updateUserProfile: ' +myGlobal.api_endpoint + 'update_profile' ) ;
    return this.http.put(myGlobal.api_endpoint + 'update_profile/' + id, profileData
    );
  }

  modifyUserProfile (id:string, profileData:any): Observable<any> {
    console.debug('Call modifyUserProfile: ' +myGlobal.api_endpoint + 'modify_profile' ) ;
    return this.http.put(myGlobal.api_endpoint + 'user/modify_profile/' + id, profileData
    );
  }
  getNewsListWithCat(filterType:string): Observable<any> {
    console.debug('Call getNewsListWithCat: ' +myGlobal.api_endpoint + 'news' ) ;
    return this.http.get(myGlobal.api_endpoint + 'news_list/'+ filterType,{responseType:'json'});
  }

  getUserCount(): Observable<any>{
    //const params = new HttpParams({fromString: 'name=term'});
    
    console.debug("Call:getUserCount");
    return this.http.get(myGlobal.api_endpoint + 'drug' ,{responseType:'json'});
    //return this.http.request('GET', myGlobal.api_endpoint + 'rpt.usercount' , {responseType:'json'});
  }

  delEntryById(tablename:string ,rowId: string): Observable<any>{
    //const params = new HttpParams({fromString: 'name=term'});
    console.debug("Call:delEntryById");
    return this.http.delete( myGlobal.api_endpoint + 'entity/' + tablename +  '/' + rowId , {responseType:'json'});
  }

  addImageGalery(body: any): Observable<any> {
    console.debug("Call:addImageGalery");
    return this.http.post(myGlobal.api_endpoint + 'entity/img_gallery' , body, {responseType:'json'});
  }
  
}
