import { Component, OnInit } from '@angular/core';
import { DbconnectService } from 'src/app/dbconnect.service';
import { XlsExportService } from 'src/app/utils/xls-export.service';

@Component({
  selector: 'app-exp-daily',
  templateUrl: './exp-daily.component.html',
  styleUrls: ['./exp-daily.component.scss']
})
export class ExpDailyComponent implements OnInit {

  constructor(
    private dbConnect:DbconnectService,
    private exportService: XlsExportService) { }

  ngOnInit(): void {
  }

  export() {
    this.dbConnect.sysLog('ExpDailyComponent','Export','Template=' +  'DailyReport');
    this.exportService.exportToExcel("ExportTable", 'DailyReport');    
  }


}
