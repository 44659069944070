<div class="picture-preview">
    <!--img *ngIf="url" width=300 height=300 src="{{url}}" / -->
  <img *ngIf="img_data && url" width=300 height=200 src="data:image/jpeg;base64,{{img_data}}">
  
  <label  for="uploader-input" ng2FileDrop [ngClass]="{'drag-over': hasDragOver}" (fileOver)="fileOver($event)" [uploader]="uploader">
      <div class="infotext" *ngIf="!img_data"><span  >Drop your picture<br>or click to upload</span></div>
    </label>
    <input  type="file" ng2FileSelect [uploader]="uploader" id="uploader-input" style="display:none" />

  </div>
  