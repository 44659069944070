import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rpt-drug-usage',
  templateUrl: './rpt-drug-usage.component.html',
  styleUrls: ['./rpt-drug-usage.component.scss']
})
export class RptDrugUsageComponent implements OnInit {

  drug_time = [
    {
      "name": "Trước ăn sáng",
      "value": 2.82
    },
    {
      "name": "Sau ăn sáng",
      "value": 2.64
    },
    {
      "name": "Trước ăn trưa",
      "value": 2.18
    },
    {
      "name": "Sau ăn trưa",
      "value": 2.64
    },
    {
      "name": "Trước ăn tối",
      "value": 3.36
    },
    {
      "name": "Sau ăn tối",
      "value": 2.45
    },
    {
      "name": "Trước khi đi ngủ",
      "value": 3
    }
  ];

  drug_multi = [
    {
      "name": "Trước ăn sáng",
      "series": [
        {
          "name": "Có",
          "value": 53.33
        },
        {
          "name": "Không",
          "value": 46.67
        }, 
       
      ]
    },
  
    {
      "name": "Sau ăn sáng",
      "series": [
        {
          "name": "Có",
          "value": 20
        },
        {
          "name": "Không",
          "value": 80
        },
       
      ]
    },
  
    {
      "name": "Trước ăn trưa",
      "series": [
        {
          "name": "Có",
          "value": 73
        },
        {
          "name": "Không",
          "value": 27
        },
       
      ]
    },
    {
      "name": "Sau ăn trưa",
      "series": [
        {
          "name": "Có",
          "value": 75
        },
        {
          "name": "Không",
          "value": 25
        }
      ]
    }
  ];
  

  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Thời điểm';
  showYAxisLabel = true;
  yAxisLabel = 'Tỷ lệ';

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  colorScheme1 = {    
    domain: ['#0d6efd', '#6610f2', '#d63384',
          '#dc3545', '#fd7e14', '#ffc107', '#198754', '#20c997', '#0dcaf0', '#adb5bd','#000']
  };

  colorScheme2 = {    
    domain: ['#0d6efd', '#6610f2', '#6f42c1', '#d63384',
          '#dc3545', '#fd7e14', '#ffc107', '#198754', '#20c997', '#0dcaf0', '#adb5bd','#000']
  };

  constructor() { }

  ngOnInit(): void {
  }

}
