<div class="container-md">
    <h4 class="text-primary">Change Password</h4>
    <form [formGroup]="userForm" >
        <div class="row">  
            <strong>For user name: {{userName}} (ID :{{userId}})</strong>
        </div>
        <div class="row"> 
            <div class="form-group" [ngClass]="{ 'has-danger': invalidPassword() }"> 
                <label for="passwd" class="form-control-label">Password </label>
                <input type="password" formControlName="passwd" class="form-control" [ngClass]="{ 'is-invalid': invalidPassword() }" id="passwd" name="passwd" placeholder="Nhập mật khẩu">
               
              </div>
        </div>
            <div class="row"> 
                <div class="form-group" [ngClass]="{ 'has-danger': invalidRePassword() }"> 
                <label for="reenter_passwd" class="form-control-label">Re-enter Password</label>
                <input type="password" formControlName="reenter_passwd" class="form-control" [ngClass]="{ 'is-invalid': invalidRePassword() }" id="reenter_passwd" name="reenter_passwd" placeholder="Nhập lại mật khẩu">
                
                </div>
            </div>
            <br>
              <button type="submit" class="btn btn-primary" (click)="onSubmit()">
                <i class="fa fa-check"></i> Cập nhật</button>   &nbsp; 
                <button class="btn btn-success" (click)="onGoBack()">
                    <i class="fa fa-mail-reply"></i> Quay lại</button>   &nbsp; 

        
    </form>
</div>

