<!-- <jqxGrid
    [width]="getWidth()" [source]="dataAdapter" [columns]="columns"
    [pageable]="true" [autoheight]="true" [sortable]="true" 
    [altrows]="true"  [enabletooltips]="true"  [editable]="true" 
    [selectionmode]="'multiplecellsadvanced'" [columngroups]="columngroups">
</jqxGrid> -->

<jqxGrid #jqxgrid
            (onRowselect)="rowselect($event)"
            (onCellendedit)="onCellEndEdit($event)"
            [height]="'100%'" [width]="'100%'"
            [source]="gridsource"
            [columnsresize]="true"
            [showtoolbar]="true"
            [rendertoolbar]="createButtonsContainers"
            [pageable]="true" 
            [autoheight]="false" 
            [sortable]="true" 
            [filterable]="true"
            [columns]="gridcolumns"
            [pagesize]="50"
            [editable]="true"
            [enabletooltips]="true"
            [pagesizeoptions]="['50', '100', '200']">
            </jqxGrid>