import {Component, OnInit, ViewChild} from '@angular/core';
//import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { DbconnectService } from 'src/app/dbconnect.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-system-log',
  templateUrl: './system-log.component.html',
  styleUrls: ['./system-log.component.scss']
})



export class SystemLogComponent implements OnInit {
  displayedColumns: string[] = ["position","usr_id","user_name","menu","action","content","created_on"];

  systemlog!: any;


  dataSource = new MatTableDataSource<PeriodicElement>(this.systemlog);


  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  expandedElement: any;

  constructor(
    private dbConnect:DbconnectService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getSystemLog();
    this.dataSource.sort = this.sort;

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
   }


   getSystemLog() {
    this.systemlog = [];

    var filterType = '0';
    
    this.dbConnect.getSystemLog(filterType).subscribe({next:(data: []) => {

    
      //console.log('DAT:' + JSON.stringify(data));
      //this.lastaccess = data;
      
      data.forEach(e => {
        var obj= new PeriodicElement();

        obj = e;
        
   
        obj.position = e['systemlog_id'];
        
        this.systemlog.push(obj);

       // console.log(JSON.stringify(obj));
       
      });

      this.dataSource.data = this.systemlog ;//= new MatTableDataSource<PeriodicElement>(this.lastaccess); 
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;   
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}


export class PeriodicElement {
  position!: number;
  user_id !: string;
  user_name!: string;
  action!: string;
  menu!: string;
  created_on!: string;  
}
