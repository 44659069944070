import { Component, OnInit,ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import { Observable, of } from 'rxjs';
import { DbconnectService } from 'src/app/dbconnect.service';
import { DrugData } from 'src/app/models/drug';

import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

import { jqxInputComponent } from 'jqwidgets-ng/jqxinput';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxMenuComponent } from 'jqwidgets-ng/jqxmenu';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxSliderComponent } from 'jqwidgets-ng/jqxslider';

import {MatSort} from '@angular/material/sort';
import {animate, state, style, transition, trigger} from '@angular/animations';
import * as myGlobal from '../../global';
import { CatNode} from '../../models/food-category';

import { Subject } from 'rxjs';


import { MsgDialogComponent } from 'src/app/utils/msg-dialog/msg-dialog.component';


//import { jsonpFactory } from '@angular/http/src/http_module';

@Component({
  selector: 'app-drug-man',
  templateUrl: './drug-man.component.html',
  styleUrls: ['./drug-man.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class DrugManComponent implements OnInit {

  hasData: boolean = true;

  drug_list: any = [];
  loadData: any;
  dataSource = new MatTableDataSource<DrugData>(this.drug_list);
  allDrugCat: any[] = [];  // Chua tat ca cac category

  currDrugId: string = '0'; // gia tri id cua drug hien tai
  currDrugData: any;
  currCatId: number = -1;

  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  @ViewChild('jqxgrid', { static: false }) jqxgrid!: jqxGridComponent;

  resetFormSubject: Subject<boolean> = new Subject<boolean>();

  drugList: DrugData[] = [];
  gridsource: any = {
    datatype: 'json',
    datafields: [
        { name: 'drug_id', type: 'string' },
        { name: 'drug_name', type: 'string' },
        { name: 'ingredient', type: 'string' },
        { name: 'form', type: 'string' },
        { name: 'dosage', type: 'string' },
        { name: 'using_time', type: 'string' },
        { name: 'duration', type: 'string' },
        { name: 'type', type: 'string' },
        { name: 'remark', type: 'string' },
        { name: 'drug_data', type: 'string' },
        { name: 'created_date', type: 'string' },
        { name: 'created_by', type: 'string' },
        { name: 'category_name', type: 'string' },
    ],
    localdata: this.drugList,
    // updaterow: (rowid, rowdata, commit): void => {
    //     commit(true);
    // }
  };
  gridcolumns: any[] = [
    { text: 'Id', datafield: 'drug_id', width: 100 },
    { text: 'Tên thuốc', datafield: 'drug_name', width: 150 },
    { text: 'Thành phần', datafield: 'ingredient', width: 180 },
    { text: 'Dạng viên', datafield: 'form', width: 120 },
    { text: 'Liều dùng', datafield: 'dosage', width: 120 },
    { text: 'Thời gian dùng', datafield: 'using_time', width: 250 },
    { text: 'Chu kỳ', datafield: 'duration', width: 150 },
    { text: 'Kiểu', datafield: 'type', width: 180 },
    { text: 'Ghi chú', datafield: 'remark', width: 120 },
    { text: 'Thông tin khác', datafield: 'drug_data' },
    { text: 'Ngày tạo', datafield: 'created_date' },
    { text: 'Tạo bởi', datafield: 'created_by' },
    { text: 'Phân nhóm', datafield: 'category_name' }
  ];
  griddataAdapter: any = new jqx.dataAdapter(this.gridsource);

  constructor(
    private dbConnect: DbconnectService,
    private route: ActivatedRoute,
    private _location: Location,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {

  }

  ngAfterViewInit(): void
  {

    this.dbConnect
    .getAllCatList(myGlobal.CAT_TYPE_DRUG)
    .subscribe({next:(data: []) => {
      this.allDrugCat = data;
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});

    this.getDrugList();
    this.createButtons();

  }

  getWidth(): any {
		return window.innerWidth - 20;
	}

  getHeight(): any {
		return window.innerHeight - 50;
	}

  resetChildForm(){ // Update child view -> DrugDetailComponent

  }

  saveClick(): void {

  };

  rowselect(event: any): void {
    // event arguments.
    let args = event.args;
    // selected row.
    let row = event.args.row;
    this.currDrugId = row.drug_id;
  }

  createToolbar(statusbar: any): void {
    let buttonsContainer = document.createElement('div');
    buttonsContainer.style.cssText = 'overflow: hidden; position: relative; margin: 5px;';
    let addButtonContainer = document.createElement('div');
    let deleteButtonContainer = document.createElement('div');
    let reloadButtonContainer = document.createElement('div');
    let searchButtonContainer = document.createElement('div');
    addButtonContainer.id = 'addButton';
    deleteButtonContainer.id = 'deleteButton';
    reloadButtonContainer.id = 'reloadButton';
    searchButtonContainer.id = 'searchButton';
    addButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
    deleteButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
    reloadButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
    searchButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
    buttonsContainer.appendChild(addButtonContainer);
    buttonsContainer.appendChild(deleteButtonContainer);
    buttonsContainer.appendChild(reloadButtonContainer);
    buttonsContainer.appendChild(searchButtonContainer);
    statusbar[0].appendChild(buttonsContainer);
  }

  // ----- old -----------

  getDrugList() {
    this.drug_list = [];

    var filterType = '0';

    this.dbConnect.getDrugList(filterType).subscribe({next:(data: []) => {

      // if (!checkTimeOut(data,this.router)) {
      //     return;
      // }
      //console.log('DAT:' + JSON.stringify(data));
      //this.lastaccess = data;

      console.debug('Data ', JSON.stringify(data));
      var idx: any = 0;

      this.loadData = data;

      data.forEach(e => {

        idx ++;
        var obj: any= new DrugData();

        obj = e;
       // obj['idx'] = idx;
        obj.pos = 0; // Xac dinh vi tri de an nut up/down
        obj.ids = [];


        if (obj.parent_id=='0') {
          console.debug('parent:' + JSON.stringify(obj));
          this.drug_list.push(obj);
        }

      });

      // Xu ly cau hoi con
      data.forEach(e => {

        var obj: any= new DrugData();
        obj = e;
        if (obj.parent_id!='0') {
        //  console.debug('child:' + JSON.stringify(obj));
          let parent = this.drug_list.find((e: any) => e.drug_id == obj.parent_id );
          if (parent) {
            parent.ids.push(obj);
          }
        }
      });

      this.drug_list.forEach((e: any) => {
        if (e.ids.length > 0) {
          e.ids.sort((a: { idx: number; },b: { idx: number; }) => (a.idx > b.idx) ? 1 : ((b.idx > a.idx) ? -1 : 0));

          if (e.ids && e.ids.length>1) {
            e.ids[0].pos = 1;
            e.ids[e.ids.length-1].pos = 2;
          }
        }
      });

      this.drug_list.sort((a: { idx: number; },b: { idx: number; }) => (a.idx > b.idx) ? 1 : ((b.idx > a.idx) ? -1 : 0))

      if (this.drug_list && this.drug_list.length>1)  {
        this.drug_list[0].pos = 1;
        this.drug_list[this.drug_list.length-1].pos = 2;
      }

      this.dataSource.data = this.drug_list ;
      this.dataSource.sort = this.sort;

    }, error:(err: string) => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});
  }

  onDrugEdit(drug: any, parent: any) {
    if (parent==null) {
      this.editDrug(drug.drug_id, 0,0);  // Edit Question
    } else {
      this.editDrug(drug.drug_id, parent.drug_id,0); // Edit SubQuestion
    }
  }



  onDrugDelete(drug: any,subquest: any) {
    const dialogRef = this.dialog.open(MsgDialogComponent, {
      width: '350px',
      data:'Có chắc chắn muốn xoá câu hỏi này không ?'
    });
    dialogRef.afterClosed().subscribe({next:(result) => {
      if(result) {

        var idx =  drug.ids.indexOf(subquest);
        if (idx > -1 ) {
          drug.ids.splice(idx,1);

          this.dbConnect.deleteDrug(subquest.drug_id)
            .subscribe({next:(data: any) => {
               alert('Xoá thành công!');
               this.dbConnect.sysLog('DrugManComponent','Delete','Delete Drug Data= ' +  subquest.drug_id);
            }, error:(err: string) => {
              console.error('Observer got an error: ' + err);
              alert('Lỗi kết nối mạng! ');
            }});
        }

        //alert('Xoa thanh cong');
      }
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});
  }

  onDrugUp(drug: any,subquest: any) {
    var idx =  drug.ids.indexOf(subquest);
    if ( idx ==0 ) return;

    var tmp = drug.ids[idx - 1];
    var pos = tmp.pos;
    drug.ids[idx - 1] = subquest;
    drug.ids[idx] = tmp;
    tmp.pos = subquest.pos;
    subquest.pos = pos;

    this.reOrderDrug( drug.ids);
  }


  onDrugDown(drug: any,subquest: any) {
    var idx =  drug.ids.indexOf(subquest);
    if ( idx >= drug.ids.length -1 ) return;

    var tmp =  drug.ids[idx + 1];
    var pos = tmp.pos;
    drug.ids[idx + 1] = subquest;
    drug.ids[idx] = tmp;

    tmp.pos = subquest.pos;
    subquest.pos = pos;

    this.reOrderDrug( drug.ids);
  }

  onDrugAdd(drug: any,subquest: any) {

    if (subquest) {
      this.editDrug(0, drug.drug_id,subquest.idx); // Edit SubQuestion
    } else {
      this.editDrug(0, drug.drug_id,0);
    }

  }

  onRemoveDrugGroup(drug: any) {
    const dialogRef = this.dialog.open(MsgDialogComponent, {
      width: '350px',
      data: 'Có chắc chắn muốn xóa loại thuốc này không ?'
    });
    dialogRef.afterClosed().subscribe({next:(result) => {
      if(result) {
        var idx =  this.drug_list.indexOf(drug);
        if (idx > -1 ) {
          this.drug_list.splice(idx,1);

          this.dbConnect.deleteDrug(drug.drug_id)
            .subscribe({next:(data: any) => {
              this.dbConnect.sysLog('DrugManComponent','Delete','Delete Drug Data= ' +  drug.drug_id);
               alert('Xoá thành công!');
            }, error:(err: string) => {
              console.error('Observer got an error: ' + err);
              alert('Lỗi kết nối mạng! ');
            }});

        }


      }
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});

  }

  onInsertDrugGroup(drug: any) {
    alert('onInsertSession ' +  JSON.stringify(drug));
  }

  onMoveUpDrugGroup(drug: any) {
    var idx =  this.drug_list.indexOf(drug);
    if ( idx ==0 ) return;
    var tmp = this.drug_list[idx - 1];
    var  pos = tmp.pos;
    this.drug_list[idx - 1] = drug;
    this.drug_list[idx] = tmp;

    tmp.pos = drug.pos;
    drug.pos = pos;

    this.reOrderDrug(this.drug_list);
    //alert("onMoveUpSession " +  idx);
  }

  onMoveDownDrugGroup(drug: any) {
    var idx =  this.drug_list.indexOf(drug);
    if ( idx >= this.drug_list.length -1 ) return;

    var tmp = this.drug_list[idx + 1];
    var  pos = tmp.pos;
    this.drug_list[idx + 1] = drug;
    this.drug_list[idx] = tmp;

    tmp.pos = drug.pos;
    drug.pos = pos;

    this.reOrderDrug(this.drug_list);
    //alert("onMoveDownSession " +  idx);

  }

  onAddNewDrugGroup() {
    this.editDrug(0, 0,0); // Add new Session
  }

  reOrderDrug(list: any) {
    let idx = 1;
    list.forEach((oriElement: any) => {
      oriElement.idx = idx;
      //clone element
      let element: any = Object.assign({}, oriElement);
      //remove attribute not needed
     // delete element.idx;
      delete element.pos;
      //end
    //  console.log("DEBUG: " + JSON.stringify(element));
      this.dbConnect.updateDrug(element.drug_id, element)
        .subscribe({next:(data: any) => {
          //console.log("DEBUG: Update OK " + element.id);

        }, error:(err: string) => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }});

      idx = idx + 1;
    });
  }

  editDrug(id: any,parent_id: any,idsIndex: any) {
    this.router.navigateByUrl('/drug/' + id + '/' + parent_id + '/' + idsIndex);
  }

  treeOnSelect(category_id: any): void {
     let currCat = this.allDrugCat.find(e => e.category_id == category_id );

     if (currCat) {
       this.currCatId = category_id;
       this.reloadGridData(category_id,currCat.category_name);

     } else {
       this.currCatId = -1;
       this.drugList = [];
       this.hasData =false;
     }

   };

  addNewItemCatId(category_id: any): void {
    this.router.navigateByUrl('/drug/' + category_id + '/-1');
  }

  reloadGridData(catid: string,catName: string) {
    this.drugList = [];
    this.hasData =false;
  //  console.debug("Call:reloadGridData");
    this.dbConnect
        .getDrugListByCatId(catid)
        .subscribe({next:(data: []) => {
                this.drugList = [];
                //console.debug("Get data from back end:" + JSON.stringify(data))
                data.forEach((e: any) => {
                    let item: DrugData = {
                        drug_id: e.drug_id , //+ (" (id:" + e["food_id"] + ")"),
                        drug_name: e.drug_name,
                        ingredient: e.ingredient,
                        form: e.form,
                        dosage: e.dosage,
                        using_time: e.using_time,
                        duration: e.duration,
                        index: e.index,
                        type: e.type,
                        remark: e.remark,
                        drug_data: e.drug_data,
                        created_date: e.created_date,
                        created_by: e.created_by,
                        category_name : catName,
                        category_id : catid,
                    } ;


                    this.drugList.push(item );
                    this.hasData = true;

                    this.currDrugId = '-1';
                });

                this.gridsource.localdata = this.drugList;
                //console.debug("FoodList: " + JSON.stringify(this.foodList));


                this.jqxgrid.updatebounddata();
            }, error:err => {
            console.error('Observer got an error: ' + err);
            alert('Lỗi kết nối mạng! ');
        }});
  }

  createButtons(): void {

    let addButtonOptions = {
        width: 80, height: 25, value: 'Add',
        imgSrc: '/assets/media/icons/add.png',
        imgPosition: 'center', textPosition: 'center',
        textImageRelation: 'imageBeforeText'
    }
    let addButton = jqwidgets.createInstance('#addButton', 'jqxButton', addButtonOptions);
    let deleteButtonOptions = {
        width: 80, height: 25, value: 'Delete',
        imgSrc: '/assets/media/icons/close.png',
        imgPosition: 'center', textPosition: 'center',
        textImageRelation: 'imageBeforeText'
    }
    let deleteButton = jqwidgets.createInstance('#deleteButton', 'jqxButton', deleteButtonOptions);
    let reloadButtonOptions = {
        width: 80, height: 25, value: 'Reload',
        imgSrc: '/assets/media/icons/refresh.png',
        imgPosition: 'center', textPosition: 'center',
        textImageRelation: 'imageBeforeText'
    }
    let reloadButton = jqwidgets.createInstance('#reloadButton', 'jqxButton', reloadButtonOptions);

    // let searchButtonOptions = {
    //     width: 80, height: 25, value: 'Find',
    //     imgSrc: '/assets/media/icons/search.png',
    //     imgPosition: 'center', textPosition: 'center',
    //     textImageRelation: 'imageBeforeText'
    // }
    // let searchButton = jqwidgets.createInstance('#searchButton', 'jqxButton', searchButtonOptions);
    // add new row.
    addButton.addEventHandler('click', (event: any): void => {
        this.addNewItemCatId(this.currCatId);
    });
    // delete selected row.
    deleteButton.addEventHandler('click', (event: any): void => {
        let selectedrowindex = this.jqxgrid.getselectedrowindex();
        let row = this.jqxgrid.getdatainformation().rowscount;
        let id = this.jqxgrid.getrowid(selectedrowindex);

        if ( this.currDrugId=='-1') return;
        this.openDialog('Xác nhận','Có chắc chắn muốn xóa loại thuốc này? (id=' + this.currDrugId + ')').subscribe(result => {
          if (result=='1') {
            this.dbConnect.deleteDrug(this.currDrugId).subscribe({next:(data: []) => {
                   // alert("Ok, done!");
                    //this.jqxTree.refresh();
                     this.ngAfterViewInit();
                     this.router.navigateByUrl('/drug-man', { skipLocationChange: false });
                    this.jqxgrid.refreshdata();
                    alert('Xóa dữ liệu thành công!');
                    this.dbConnect.sysLog('DrugManComponent','Delete','Delete Drug Data= ' +  this.currDrugId);
                    this.jqxgrid.deleterow(id);
                  }, error:err => {
                    console.error('Observer got an error: ' + err);
                    alert('Lỗi kết nối mạng! ');
                  }});
          }
        });

    });

    // reload grid data.
    reloadButton.addEventHandler('click', (event: any): void => {
        //this.jqxgrid.source(this.getAdapter());
        let currCat = this.allDrugCat.find(e => e.category_id == this.currCatId );

        if (currCat) {

          this.reloadGridData( this.currCatId+'' ,currCat.category_name);
        }

    });
    // search for a record.
    // searchButton.addEventHandler('click', (event: any): void => {
    //     //  this.findWindow.open();
    //     //  this.findWindow.move(220, 120);
    // });
  }

  openDialog(title: string, msg: string): Observable<any>{
    const dialogRef = this.dialog.open(MsgDialogComponent, {
      width: '250px',
      data: {title, msg}
    });
    return dialogRef.afterClosed();
  }
}

