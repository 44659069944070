<h1 mat-dialog-title class="text-primary">Quản lý ảnh</h1>
<jqxGrid #jqxgrid   
    (onRowselect)="rowselect($event)" 
    (onCelldoubleclick)="Celldoubleclick($event)"
    (onCellendedit)="onCellEndEdit($event)"
    [width]="'100%'" 
    [columnsresize]="true" 
    [showtoolbar]="true" 
    [rendertoolbar]="createToolbar" 
    [pageable]="true"
    [autoheight]="true" 
    [sortable]="true" 
    [columns]="columns" 
    [source]="griddataAdapter" 
    [rowsheight]="50"
    [pagesize]="8"
    [editable]="true"
    [enabletooltips]="true"
    [pagesizeoptions]="['8', '10', '20']"
></jqxGrid>
