import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication.service';
import { DbconnectService } from 'src/app/dbconnect.service';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit {
  userForm!: UntypedFormGroup;

  public up4RegisterEnabled:boolean = false;
  public editEnabled = true;
  public smallsize = true;
  public picurl!: string;

  canSubmit = false;
  fileName:string = "";
  currentUser: any ;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
   // private _location: Location,
    private dbConnect:DbconnectService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService
    
  ) { }

  ngOnInit(): void {

    this.currentUser = this.authenticationService.currentUserValue;

    this.userForm = this.formBuilder.group({
      title: ['', Validators.required],
      desc: ['', Validators.required],
    });
  }

  onTitleChange(event: any) {
    this.canSubmit = !(this.userForm.controls.title.value==null || this.userForm.controls.title.value==="") && (this.fileName!=="");    
  }

  onUploadImage(fileName:string) {
    this.fileName = fileName;
    this.canSubmit = !(this.userForm.controls.title.value==null || this.userForm.controls.title.value==="") && (this.fileName!=="");   
  }

  invalidTitle() {return false; }
  invalidDesc() {return false; }

  onSubmit()
  {
    var body = {
      img_name: this.fileName,
      img_title: this.userForm.controls.title.value,
      upload_by: this.currentUser.user_name,
      remark: this.userForm.controls.desc.value,
      category: "system",
      img_path: this.fileName,
      file_name:  this.fileName,
    } 

    this.dbConnect.addImageGalery(
      body
      ).subscribe({next:(result) => {
                  
          alert('Thêm ảnh thành công! ' );
         // this._location.back();
         this.dbConnect.sysLog('ImageUploadComponent','submit','Add Image= ' +  body.img_title + " (" +this.fileName + ")");
      }, error:err => {
        console.error('Observer got an error: ' + err);
       // alert('Lỗi kết nối addNewsCategory! ');
      }});

  }

}
