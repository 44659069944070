import { Component, OnInit, ViewChild,AfterViewInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { SwiperComponent, SwiperDirective } from 'ngx-swiper-wrapper';
import { SwiperOptions } from 'swiper';
import { PaginationOptions } from 'swiper/types/components/pagination';
import { ScrollbarOptions } from 'swiper/types/components/scrollbar';
import { MsgDialogComponent } from 'src/app/utils/msg-dialog/msg-dialog.component';
import { DbconnectService } from 'src/app/dbconnect.service';

import * as myGlobal from '../../global';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxInputComponent } from 'jqwidgets-ng/jqxinput';
import { Observable } from 'rxjs';
import { ImageQuickViewerComponent } from '../image-quick-viewer/image-quick-viewer.component';



@Component({
  selector: 'app-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.scss']
})
export class ImageSelectorComponent implements OnInit,AfterViewInit {
  @ViewChild('jqxgrid', { static: false }) jqxgrid!: jqxGridComponent;
  @ViewChild('filter', { static: false }) jqxInputFilter!: jqxInputComponent;

  public selectImage : string = "";
  
  public imageList! :any ;
  searchText : string = "*";

  imagerenderer = (row: number, datafield: string, value: string): string => {
    if (value == null || value == "") value="/assets/bp-acid-reflux-icon-fill.png";
  
    //if (this.imageList[row]["news_type"]=="1") {
      return '<img style="margin-left: 5px;" height="50" width="80" src="' + value + '"/>';
    //} else {
     // return '<iframe width="240" height="120" src="' + value + '" frameborder="0" allowfullscreen></iframe>';
      // return '<div class="video"> \
      //   <video controls #videoPlayer> \
      //    <source src="' + value + '" " /> \
      //   Browser not supported \
      // </video> \
      // </div>';
    //}
  }

  columns: any[] =
  [
    { text: 'ID', datafield: 'img_id', width: "5%" ,editable:false},
    { text: 'Ảnh đại diện', datafield: 'show_img_path', width: 100, cellsrenderer: this.imagerenderer ,editable:false},       
    { text: 'Tiêu đề', datafield: 'img_title' ,width: 180,editable:true, columntype: 'textarea',},
    { text: 'Mô tả', datafield: 'remark' ,width: 140,editable:true, columntype: 'textbox',} ,
    { text: 'Ngày tạo', datafield: 'created_date' ,width: 100,editable:false, columntype: 'textbox',} ,
    // { text: 'Image path', datafield: 'img_path' ,width: 280,editable:false, columntype: 'textbox',} ,
    // { text: 'File Name', datafield: 'file_name' ,width: 100,editable:false, columntype: 'textbox',} ,
    
  ];

  gridsource: any = {
    datatype: "json",
    datafields: [
        { name: 'img_id', type: 'number' },
        { name: 'show_img_path', type: 'string' },   // for render    
        { name: 'img_name', type: 'string' },   
        { name: 'img_path', type: 'string' },  
        { name: 'img_title', type: 'string' },
        { name: 'remark', type: 'string' } ,    
        { name: 'created_date', type: 'string' }   , 
        { name: 'file_name', type: 'string' }   , 
        
    ],
    localdata: this.imageList,
    // updaterow: (rowid, rowdata, commit): void => {
    //     commit(true);
    // }
  };
  griddataAdapter: any = new jqx.dataAdapter(this.gridsource);
  //slides: any;
  directiveRef: any;

  constructor(
    private dbConnect : DbconnectService,
    private route: ActivatedRoute,
    private _location: Location,
    private router: Router,
    public dialog: MatDialog
    ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    
    this.reloadGridData();
  
  }

  Celldoubleclick(event: any): void 
  {
    //  this.openImageSelectorDialog();

    let rowidx = this.jqxgrid.selectedrowindex();
    if (rowidx==-1) return;

    let data = this.jqxgrid.getrowdata(rowidx);

    this.selectImage = data["img_path"];

    const dialogRef = this.dialog.open(ImageQuickViewerComponent, {
        width: '650px',height: '360px',
        data: {imgUrl:data["show_img_path"],imgTitle:""}
    });
    dialogRef.afterClosed();
  }
 

  reloadGridData() {
    this.imageList = [];
  
    this.dbConnect
      .getImgGallery(this.searchText)
      .subscribe({next:(data: []) => {
        this.imageList = [];
        this.imageList = data;
        data.forEach((e:any) => {
          e['show_img_path'] = myGlobal.image_lib_base + e['img_path'] ; 
          //+'?' + this.randomString(40,this.randSeed);
          
        });
        //console.debug("Gallery length: " + this.imageList.length);
        this.gridsource.localdata = this.imageList;
        this.jqxgrid.updatebounddata();
        //console.log(this.swiperComp);
        setTimeout(() => {
          this.directiveRef?.update();
        }, 2000);      
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }});  
  }

  rowselect(event: any): void {
    let row = event.args.row;
    this.selectImage = row["img_path"];
  }

  onFilterClick() {    
    this.searchText = this.jqxInputFilter.val();
    if (this.searchText==="") 
      this.searchText ="*";
    
    this.reloadGridData();

  }

}
