<table style="margin-top: 20px; width: 90%;margin-left: 10px;">
    <tr width="100%" style="margin-top: 10px;margin-bottom: 10px;">
      <td style="text-align: left; width: 100%; margin-left: 20px;" colspan="2">
        <jqxButton #submitButton [width]='150' [height]='30' (click)="onAddNotify()">
          Thêm thông báo
        </jqxButton> &nbsp;&nbsp;&nbsp;
        <jqxButton #submitButton [width]='150' [height]='30'>
          Cập nhật thông báo
        </jqxButton>&nbsp;&nbsp;&nbsp;
        <jqxButton #submitButton [width]='150' [height]='30'>
          Xóa thông  báo
        </jqxButton>
      </td>

    </tr>
    <tr width="100%">
      <td style="text-align: left; width: 10%; margin-left: 20px;"> <b>Tiêu đề:</b></td>
      <td style="text-align: left;width: 80%;">
        <jqxInput #notifyTitle [height]="30" width="100%" [placeHolder]="'Nhập nội dung tiêu đề'" [minLength]="1">
        </jqxInput>
      </td>
    </tr>
    <tr width="100%">
      <td style="text-align: left; width: 10%; margin-left: 20px;"> <b>Thông  báo:</b></td>
      <td style="text-align: left;width: 80%;">
        <jqxInput #notifyDes [height]="30" width="100%" [placeHolder]="'Nhập nội dung thông  báo'" [minLength]="2">
        </jqxInput>
      </td>
    </tr>
    
  </table>
