import { Component, OnInit, ViewChild,AfterViewInit } from '@angular/core';
import { jqxEditorComponent } from 'jqwidgets-ng/jqxeditor';
import { jqxDropDownListComponent  } from 'jqwidgets-ng/jqxdropdownlist';
import {UntypedFormBuilder, FormGroup, Validators} from '@angular/forms';
import { DbconnectService } from 'src/app/dbconnect.service';
import {Location} from '@angular/common';
import { jqxInputComponent } from 'jqwidgets-ng/jqxinput';
import { NewsData } from 'src/app/models/news';
import * as myGlobal from '../../global';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-news-editor',
  templateUrl: './news-editor.component.html',
  styleUrls: ['./news-editor.component.scss']
})
export class NewsEditorComponent implements OnInit ,AfterViewInit{
  valueUrl!: String;
  
  createNew = true;
  newsId : string = "0";
  catType : number = myGlobal.CAT_TYPE_NEWS ;
  catId : string = "";

  @ViewChild('newsTitle', { static: false }) newsTitle!: jqxInputComponent;
  @ViewChild('newsLinks', { static: false }) newsLinks!: jqxInputComponent;
  @ViewChild('newsTag', { static: false }) newsTag!: jqxInputComponent;
  @ViewChild('newsDes', { static: false }) newsDes!: jqxInputComponent;   
  @ViewChild('newsCategoryDownList', { static: false }) newsCategoryDownList!: jqxDropDownListComponent;
  @ViewChild('typeNewsComboBox', { static: false }) typeNewsComboBox!: jqxDropDownListComponent;
  
  constructor(  
    private dbConnect: DbconnectService,
    private route: ActivatedRoute,
    private _location: Location,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog
    ) { }

  hasData : boolean = true;
  newsList : NewsData[] = [];
  dataAdapter : any[] = [];
  allNewsCat : any[] = [];  // Chua tat ca cac category
  currNewsId : string = "0"; // gia tri id cua drug hien tai
  currNewsData : any;
  currCatId : number = -1;
  new_list:any = [];
  loadData  :any;
  //dataSource = new MatTableDataSource<NewsData>(this.new_list);

  ngOnInit(): void {

    this.catType = Number(this.route.snapshot.paramMap.get('catType')) ;
    this.catId = this.route.snapshot.paramMap.get('catId') +"";
    this.newsId = this.route.snapshot.paramMap.get('newsId') + '';

    this.dbConnect.getAllCatList(this.catType).subscribe({next:(data: []) => {
      
      let DataNewsCat = new Array();
      let i=0;
      data.forEach((e) => {
        let row = {};       
        DataNewsCat[i]={value: e["category_id"], label: e["category_name"]};
        i++;
      });

      this.newsCategorySource = DataNewsCat;
      
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết DB getNewsCategory! ');
    }});

  }
  getWidth() : any {
    // if (document.body.offsetWidth < 850) {
    // 	return '90%';
    // }		
    return window.innerWidth - 20;
  }
  
  getHeight() : any {
    // if (document.body.offsetWidth < 850) {
    // 	return '90%';
    // }		
    return window.innerHeight - 50;
  }
  treeOnSelect(category_id: any): void {   
    let currCat = this.allNewsCat.find(e => e.category_id == category_id );
    
    if (currCat) {   
      this.currCatId = category_id;
      //this.reloadGridData(category_id,currCat.category_name);

    } else {
      this.currCatId = -1;
      this.newsList = [];
      this.hasData =false;
    }
  
  }; 
  
  comboBoxTypeNewsSource= [{ value: '1', label: 'Bài viết' },{value: '2', label: 'Video'}];

  newsCategorySource : any[] = [];
  treedataAdapter :any;
  treerecords :any;
  
  ngAfterViewInit(): void 
  {

    if (this.catType == myGlobal.CAT_TYPE_NEWS) {
        this.typeNewsComboBox.val("1");        
    } else {
      this.typeNewsComboBox.val("2");      
    }

    setTimeout(() => {
      if (this.catId!=="-1") {        
        this.newsCategoryDownList.val(this.catId);
      }
    }, 500);
    
  
  }
  
  onAddNews(){
    //alert("21432132");
    var body =  {
      title: this.newsTitle.val(),
      link: this.newsLinks.val(),
      tags: this.newsTag.val(),      
         // Luu y doi ten truong
      news_type: this.typeNewsComboBox.val(),
      category_id: this.newsCategoryDownList.val(),
      status: 1,
      descriptions:this.newsDes.val()
      
    };

    if (this.newsTitle.val()==="") {
      alert("Phải nhập tiêu đề tin tức");
      return;
    }
  
    if (this.createNew) { 
      this.dbConnect.addNews(
      body
      ).subscribe({next:(result) => {
                  
          alert('Thêm bài viết thành công! ' );
         // this._location.back();
         this.dbConnect.sysLog('NewsEditorComponent','submit','Add News/Video Data= ' +  this.newsTitle.val());
         this._location.back();
      }, error:err => {
        console.error('Observer got an error: ' + err);
       // alert('Lỗi kết nối addNewsCategory! ');
      }});
      //this.userProfileService.sysLog('FilterQuestionComponent','submit','Add Quest= ' +  this.userForm.controls.quest.value);
    } else {
      this.dbConnect.updateNews(
        this.newsId,
        body
        ).subscribe({next:(result) => { 
                    
            alert('Cập nhật bài viết thành công ');
            this._location.back();
            this.dbConnect.sysLog('NewsEditorComponent','submit','Update News/Video Data= ' +  this.newsTitle.val());
            this._location.back();
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối addNewsCategory! ');
        }});
        //this.dbConnect.sysLog('FilterQuestionComponent','submit','Update Quest= ' +  this.userForm.controls.category_name.value);
    }
  }

  addNewItemCatId(category_id: any): void {   
    //this.router.navigateByUrl('/drug/' + category_id + "/-1");
  }

  viewContentOnChange( ) {
    alert("OK!");

  }

  onGoBack()
  {
    this._location.back();
  }

}
