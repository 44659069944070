import { Component,Inject, ViewChild, ElementRef, AfterViewInit,OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import { Observable, of } from 'rxjs';

import{ jqxSplitterComponent } from 'jqwidgets-ng/jqxsplitter';
import { jqxExpanderComponent } from 'jqwidgets-ng/jqxexpander';
import { jqxTreeComponent } from 'jqwidgets-ng/jqxtree';
import { jqxPanelComponent } from 'jqwidgets-ng/jqxpanel';
import { jqxListBoxComponent } from 'jqwidgets-ng/jqxlistbox';
import { jqxInputComponent } from 'jqwidgets-ng/jqxinput';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxMenuComponent } from 'jqwidgets-ng/jqxmenu';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxSliderComponent } from 'jqwidgets-ng/jqxslider';

import { DbconnectService } from 'src/app/dbconnect.service';

import * as myGlobal from '../../global';
import {FoodData, CatNode} from '../../models/food-category';
import { MsgDialogComponent } from 'src/app/utils/msg-dialog/msg-dialog.component';
//import { stringify } from '@angular/compiler/src/util';
import { CatSelectorComponent } from 'src/app/utils/cat-selector/cat-selector.component';
import { ImageGalleryComponent } from 'src/app/utils/image-gallery/image-gallery.component';
import { ImageSelectorComponent } from 'src/app/utils/image-selector/image-selector.component';
//import { MessageDialog } from 'src/app/utils/message-dialog/message-dialog';


const USER_FOOD_CATID = "16";

@Component({
  selector: 'app-food-man',
  templateUrl: './food-man.component.html',
  styleUrls: ['./food-man.component.scss']
})
export class FoodManComponent implements OnInit,AfterViewInit  {
   @ViewChild('jqxTree', { static: true }) jqxTree!: jqxTreeComponent;
   @ViewChild('myDropDownList', { static: false }) myDropDownList!: jqxDropDownListComponent;
   @ViewChild('myInput', { static: false }) myInput!: jqxInputComponent;
   @ViewChild('findWindow', { static: false }) findWindow!: jqxWindowComponent;
   @ViewChild('treeMenu', { static: false }) treeMenu!: jqxMenuComponent;
   @ViewChild('ContentPanel', { static: false }) ContentPanel!: ElementRef;
   @ViewChild('jqxgrid', { static: false }) jqxgrid!: jqxGridComponent;
  
  @ViewChild('FoodName', { static: false }) FoodName!: jqxInputComponent;
  @ViewChild('FoodDesc', { static: false }) FoodDesc!: jqxInputComponent;
  //@ViewChild('FoodType', { static: false }) FoodType!: jqxInputComponent;
  @ViewChild('FoodCat', { static: false }) FoodCat!: jqxInputComponent;  
  @ViewChild('CreatedBy', { static: false }) CreatedBy!: jqxInputComponent;
  @ViewChild('CreatedDate', { static: false }) CreatedDate!: jqxInputComponent;
 // @ViewChild('typeSlider', { static: false }) typeSlider!: jqxSliderComponent;
  @ViewChild('foodTypeComboBox', { static: false }) foodTypeComboBox!: jqxDropDownListComponent;

  foodCatTree:any[] = [];  // Cay food category 
  allFoodCat : any[] = [];  // Chua tat ca cac category

  foodList : FoodData[] = [];

  imgUrl! : string;
  hasData : boolean = true;
  currFoodId : string = "0"; // gia tri id cua food hien tai
  currFoodData : any;
  currFoodCatId : number = -1;
  foodType : number = 1;

  isUserFood : boolean = false;




  treesource:any = {
    datatype: 'json',
    datafields: [
        { name: 'id', type: 'string' },
        { name: 'category_name' , type: 'string' },
        { name: 'remark' , type: 'string' },
        { name: 'parentid' , type: 'string' },
        { name: 'image' , type: 'string' },
        { name: 'icon' , type: 'string' },
    ],
    id: 'id',
    localdata: this.foodCatTree
  };
  treedataAdapter :any;
  treerecords :any;  

  dropDownSource: string[] = ['First Name', 'Last Name', 'Product', 'Quantity', 'Price'];

  comboBoxFoodTypeSource= [{ value: '1', label: '1 - Nên dùng' },{value: '2', label: '2 - Trung lập'},{value: '3', label: '3 - Nên tránh'}];
 
  
  gridsource: any = {
    datatype: "json",
    datafields: [
        { name: 'food_id', type: 'string' },
        { name: 'food_name', type: 'string' },
        { name: 'food_desc', type: 'string' },
        { name: 'food_type', type: 'string' },      
        { name: 'food_image', type: 'string' },              
        { name: 'created_date', type: 'string' },
        { name: 'state', type: 'string' },
        { name: 'created_by', type: 'string' },
        { name: 'user_id', type: 'string' },
        { name: 'votes', type: 'string' },
        { name: 'category_name', type: 'string' },
    ],
    localdata: this.foodList,
    // updaterow: (rowid, rowdata, commit): void => {
    //     commit(true);
    // }
  };

  gridcolumns: any[] = [
    { text: 'Id', datafield: 'food_id', width: 100 },
    { text: 'Tên', datafield: 'food_name', width: 150 },
    { text: 'Mô tả', datafield: 'food_desc', width: 180 },
    { text: 'Phân loại', datafield: 'food_type', width: 120 },      
    { text: 'Ngày tạo', datafield: 'created_date', width: 250 },
    { text: 'Trạng thái', datafield: 'state', width: 150 },
    { text: 'Tạo bởi', datafield: 'created_by', width: 180 },
    { text: 'Người cập nhật', datafield: 'user_id', width: 120 },
    { text: 'Số votes', datafield: 'votes' },
    { text: 'Nhóm', datafield: 'category_name' },
    { text: 'Link ảnh', datafield: 'food_image' }
  ];
  griddataAdapter: any = new jqx.dataAdapter(this.gridsource);

  constructor(
    private dbConnect : DbconnectService,
    private route: ActivatedRoute,
    private _location: Location,
    private router: Router,
    public dialog: MatDialog
  ) {
    
    
  }

  ngOnInit(): void  {

  }
  ngAfterViewInit(): void 
  {
      //Set Styling on the components
     // this.jqxTree.elementRef.nativeElement.firstChild.style.border = 'none';

  //  this.createButtons();

    //  this.getFeed("sciencedaily");
    this.dbConnect
    .getAllCatList(myGlobal.CAT_TYPE_FOOD)
    .subscribe({next:(data: []) => {
      this.allFoodCat = data;
      data.forEach((e) => {
       //     console.debug(e);
         // if (e["parent_id"] == "-1") {
              let item: CatNode = {
                  category_name: e["category_name"] ,//+ (" (id:" + e["category_id"] + ")"),
                  id: e["category_id"],  
                  parentid: e["parent_id"],
                  remark: e["remark"],
                  image: e["image"],   
                  icon: e["image"],
                  locked: e["locked"] 
                } ;
       
              this.foodCatTree.push(item );
          //}
      });
      
        this.treesource.localdata = this.foodCatTree;
        this.treedataAdapter = new jqx.dataAdapter(this.treesource);
        this.treedataAdapter.dataBind();

        this.treerecords = this.treedataAdapter.getRecordsHierarchy('id', 'parentid', null, 
            [{ name: 'category_name', map: 'label' }]);
            this.jqxTree.expandAll();
        
        this.createButtons();
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});
//this.dataSource.data = files;
//this.dataSource.data = this.foodCatTree;

    // this.jqxTree.selectItem(document.getElementById('14'));
    // this.jqxTree.expandItem(document.getElementById('15'));
    // document.addEventListener('contextmenu', event => {
    //     console.debug("Event:" + JSON.stringify(event));
    //     event.preventDefault();
    //   //  console.debug(JSON.stringify(event));
    //     // if (event.target) {       
    //     //   if (event.target.classList.contains('jqx-tree-item')) {
    //     //       this.jqxTree.selectItem((<HTMLElement>event.target).parentNode);
    //     //       let scrollTop = window.scrollY;
    //     //       let scrollLeft = window.scrollX;
    //     //       this.treeMenu.open(event.clientX + 5 + scrollLeft, event.clientY + 5 + scrollTop);
    //     //       return false;
    //     //   } else {
    //     //       this.treeMenu.close();
    //     //       return true;
    //     //   }
    //     // }
    //     return false;    

    // });

  }

  findBtnOnClick(): void {
    this.jqxgrid.clearfilters();
    let searchColumnIndex = this.myDropDownList.selectedIndex();
    let datafield = '';
    switch (searchColumnIndex) {
        case 0:
            datafield = 'firstname';
            break;
        case 1:
            datafield = 'lastname';
            break;
        case 2:
            datafield = 'productname';
            break;
        case 3:
            datafield = 'quantity';
            break;
        case 4:
            datafield = 'price';
            break;
    }
    let searchText = this.myInput.val();
    let filtergroup = new jqx.filter();
    let filter_or_operator = 1;
    let filtervalue = searchText;
    let filtercondition = 'contains';
    let filter = filtergroup.createfilter('stringfilter', filtervalue, filtercondition);
    filtergroup.addfilter(filter_or_operator, filter);
    this.jqxgrid.addfilter(datafield, filtergroup);
    // apply the filters.
    this.jqxgrid.applyfilters();
}
clearBtnOnClick(): void {
    this.jqxgrid.clearfilters();
}

  getWidth() : any {
		// if (document.body.offsetWidth < 850) {
		// 	return '90%';
		// }		
		return window.innerWidth - 20;
	}

  getHeight() : any {
		// if (document.body.offsetWidth < 850) {
		// 	return '90%';
		// }		
		return window.innerHeight - 50;
	}


  onTreeRightClick(event: any): boolean {
    event.preventDefault();
    console.debug("Event:" + JSON.stringify(event));
    this.jqxTree.selectItem((<HTMLElement>event.target).parentNode);
    let scrollTop = window.scrollY;
    let scrollLeft = window.scrollX;
    this.treeMenu.open(event.clientX + 5 + scrollLeft, event.clientY + 5 + scrollTop);

    return true;
  }



  treeOnSelect(event: any): void {
   // this.ContentPanel.nativeElement.innerHTML = '<div style="margin: 10px;">' + event.args.element.id + '</div>';    
    console.log("Select: " +event.args.element.id );

    let selectedItem = this.jqxTree.getSelectedItem();
    if (selectedItem != null) {
        this.jqxTree.expandItem(selectedItem.element);
    }
   // this.jqxTree.expandAll();
   // console.debug(this.allFoodCat);
    let currCat = this.allFoodCat.find(e => e.category_id == event.args.element.id );
  //  console.debug(currCat);
    if (currCat) {
      this.currFoodCatId = event.args.element.id;
     // alert(currCat.category_id);
      if (currCat.category_id !=USER_FOOD_CATID) {
          this.reloadGridData(event.args.element.id,currCat.category_name);
          this.isUserFood = false;
      } else {
          this.reloadGridUserData(event.args.element.id,currCat.category_name);
          this.isUserFood = true;
      }

    } else {
      this.currFoodCatId = -1;
      this.foodList = [];
      this.hasData =false;
    }
  
  }; 

  treeMenuOnItemClick(event: any): void {
        let item = event.args.innerText;
        
        let selectedItem: any = this.jqxTree.getSelectedItem();
        if (selectedItem == null) return;
        let currCat = this.allFoodCat.find(e => e.category_id == selectedItem.id );

        console.debug(JSON.stringify(item));
        item = item.trim();
        switch (item) {
            case "Add SubItem":
               // selectedItem = this.jqxTree.getSelectedItem();
                console.debug(selectedItem); 
                if (currCat.locked!="1") {
                 // this.router.navigateByUrl('/food-cat-detail/' + selectedItem.id + '/' + selectedItem.parentId );
                  this.router.navigateByUrl('/food-detail/'  + selectedItem.id + "/-1");
                }
                    //this.jqxTree.addTo({ label: 'Item',icon :'/assets/media/icons/folder.png' }, selectedItem.element);
                
                break;
            case "Remove Item":
                //selectedItem = this.jqxTree.getSelectedItem();
                
                this.jqxTree.removeItem(selectedItem.element);
                
                break;
            case "Edit Category":                
                console.log(selectedItem);
                if (currCat.locked!="1") {
                  this.router.navigateByUrl('/cat-detail/' + myGlobal.CAT_TYPE_FOOD +"/"+ selectedItem.id + '/' + selectedItem.parentId );
                }
                
                break;
            case "New Category":                
                console.log(selectedItem);
               
                if (selectedItem != null) {
                  let currCat = this.allFoodCat.find(e => e.category_id == selectedItem.id );
                  //if (currCat.locked!="1") {
                  this.router.navigateByUrl('/cat-detail/' + myGlobal.CAT_TYPE_FOOD + '/-1/' + selectedItem.id );
                  //}
                }
                break;
            case "Move Up": 
                this.doMoveUpFoodCat(selectedItem);
                break;
            case "Move Down":
                this.doMoveDownFoodCat(selectedItem);
                break;
            case "Move In":
                this.doMoveIn(selectedItem);
                break;
            case "Move Out":
                this.doMoveOut(selectedItem);
                break;
            case "Delete Category":                
                console.log(selectedItem);
               
                if (selectedItem != null) {
                  if (selectedItem ==  USER_FOOD_CATID) {
                    break;
                  }
                  let currCat = this.allFoodCat.find(e => e.category_id == selectedItem.id );
                  console.debug(currCat);
                  this.openDialog("Xác nhận","Có chắc chắn muốn xóa dữ liệu? ").subscribe(result => {
                      if (result=="1") {
                        this.dbConnect.checkCatHasChild(selectedItem.id).subscribe({next:(data: []) => {
                            if (data.length==0) {
                              this.dbConnect.delCatById(selectedItem.id).subscribe({next:(data: []) => {
                               // alert("Ok, done!");
                                //this.jqxTree.refresh();
                                 this.ngAfterViewInit();
                                 this.jqxTree.refresh();
                                 this.dbConnect.sysLog('FoodManComponent','tree','Delete Category ID= ' +  selectedItem.id);
                                this.router.navigateByUrl('/food-man', { skipLocationChange: false });
                              }, error:err => {
                                console.error('Observer got an error: ' + err);
                                alert('Lỗi kết nối mạng! ');
                              }});                              
                            } else {
                              alert("Không xóa được! Có thể đang có đối tượng bên trong hoặc bị đánh dấu không xóa được!");
                            }
                        }, error:err => {
                          console.error('Observer got an error: ' + err);
                          alert('Lỗi kết nối mạng! ');
                        }});
                      }                  
                  });
                }
                break;
        }
    }
  
  // Tim theo thuoc tinh
  findWithAttr(array: any, attr: any, value: any) {
      for(var i = 0; i < array.length; i += 1) {
          if(array[i][attr] == value) {
              return i;
          }
      }
      return -1;
  }

  // Tim phan tu lien ke phia truoc - cha hoac anh em
  findLastBotherItem(currCat: any):number {    
    console.debug("Me Id:" + currCat['id'])
    let last = -1;
    for (let i= 0; i<this.foodCatTree.length; i++ ) {
      if (this.foodCatTree[i]['id']==currCat['parentid']) { // Tim thay ong bo
        last = i;
        console.debug("Father:" + this.foodCatTree[i]['id'])
        continue;
      }
      if (this.foodCatTree[i]['parentid']==currCat['parentid'] &&
          this.foodCatTree[i]['id']!=currCat['id']) { // Tim thay anh em
        last = i;
        console.debug("Brother:" + this.foodCatTree[i]['id'])
        continue;
      }
      if (this.foodCatTree[i]['id']==currCat['id']) { // Tim thay chinh minh        
        break;
      }
    }
    return last;
  }

  findNextBotherItem(currCat: any):number {    
    console.debug("Me Id:" + currCat['id'])
    let me = -1;
    for (let i= 0; i<this.foodCatTree.length; i++ ) {
      if (this.foodCatTree[i]['id']==currCat['id']) { // Tim thay chinh minh  
        me = i;      
        continue;
      }    
      if (this.foodCatTree[i]['parentid']==currCat['parentid'] &&
          me !=-1 ) { // Tim thay anh em       
        console.debug("Small Brother:" + this.foodCatTree[i]['id'])
        return i;
      }
      
    }
    return -1;
  }

  // Tim phan tu lien ke phia truoc - cha hoac anh em
  findFatherItem(currCat: any):any {        
    for (let i= 0; i<this.foodCatTree.length; i++ ) {
      if (this.foodCatTree[i]['id']==currCat['parentid']) { // Tim thay ong bo
        return i;        
      }      
    }
    return -1;
  }

  reorderAndRefreshTree() {

    for (let i= 0; i<this.foodCatTree.length; i++ ) {
      this.foodCatTree[i]['idx'] = i+1;

      // Hien cho cap nhat toan bo cat list, do chua danh dau item nao da doi parent & so luong cat it
      var body =  {       
        parent_id: this.foodCatTree[i]['parentid'],     // Luu y doi ten truong
        idx:  i+1
      };
      this.dbConnect.updateCat(
          this.foodCatTree[i]['id'],
          body
        ).subscribe({next:(result) => {                    
          ;        
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
          i = this.foodCatTree.length ; // break
        }});

    }

    // refresh data
    this.treesource.localdata = this.foodCatTree;
    this.treedataAdapter = new jqx.dataAdapter(this.treesource);
    this.treedataAdapter.dataBind();
    this.treerecords = this.treedataAdapter.getRecordsHierarchy('id', 'parentid', null, 
        [{ name: 'category_name', map: 'label' }]);
    this.jqxTree.expandAll();
  }

  // Chuyen item tren tree xuong 1 bac
  doMoveDownFoodCat(selectedItem: any) {
    
    let currCat = this.foodCatTree.find(e => e.id == selectedItem.id );    
    

    let pos = this.findWithAttr(this.foodCatTree,"id",selectedItem.id);    
    if ((pos>this.foodCatTree.length-1) || (pos==-1)) return; // cuoi danh sach
    
    let nextPos = this.findNextBotherItem(currCat);

    if (this.foodCatTree[nextPos]['parentid']==currCat['parentid']) { // Chi move khi cung parent      
      let temp = this.foodCatTree[pos];
      let idx = temp['idx'];      
      this.foodCatTree[pos] = this.foodCatTree[nextPos];      
      this.foodCatTree[nextPos] = temp;      
      
      this.reorderAndRefreshTree();
    }   
  }
  
  // Chuyen item tren tree len 1 bac
  doMoveUpFoodCat(selectedItem: any) {
    
    let currCat = this.foodCatTree.find(e => e.id == selectedItem.id );    
    let pos = this.findWithAttr(this.foodCatTree,"id",selectedItem.id);    
    if (pos<=0)  return; // dau danh sach

    let lastPos = this.findLastBotherItem(currCat);
    if (lastPos==-1) return;

    if (this.foodCatTree[lastPos]['parentid']==currCat['parentid']) { // Chi move khi cung parent      
      let temp = this.foodCatTree[pos];
      let idx = temp['idx'];      
      this.foodCatTree[pos] = this.foodCatTree[lastPos];
      this.foodCatTree[lastPos] = temp;      
         
      this.reorderAndRefreshTree();
    }
  }

  // Chuyen item tren tree len lop ngoai
  doMoveOut(selectedItem: any) {
    let currCat = this.foodCatTree.find(e => e.id == selectedItem.id );    
    let pos = this.findWithAttr(this.foodCatTree,"id",selectedItem.id);    
    if (pos<=0)  return; // dau danh sach

    let lastPos =  this.findFatherItem(currCat);

    if (this.foodCatTree[lastPos]['id']==currCat['parentid'] &&
    this.foodCatTree[lastPos]['parentid']!=-1
      ) { // Chi move khi cung parent      
      
      this.foodCatTree[pos]['parentid'] = this.foodCatTree[lastPos]['parentid'];
      
      this.reorderAndRefreshTree();
    }
  }

  // Chuyen item tren tree len lop trong
  doMoveIn(selectedItem: any) {
    let currCat = this.foodCatTree.find(e => e.id == selectedItem.id );    
    let pos = this.findWithAttr(this.foodCatTree,"id",selectedItem.id);    
    if (pos<=0)  return; // dau danh sach

    let lastPos =  this.findLastBotherItem(currCat);

    if (this.foodCatTree[lastPos]['parentid']==currCat['parentid']) { // Chi move khi cung parent      
      
      this.foodCatTree[pos]['parentid'] = this.foodCatTree[lastPos]['id'];
      
      this.reorderAndRefreshTree();
    }
  }

 
  // Load System Food Data
  reloadGridData(catid: string,catName: string) {
    this.foodList = [];
    this.hasData =false;
  //  console.debug("Call:reloadGridData");
    this.dbConnect
        .getFoodListByCatId(catid)
        .subscribe({next:(data: []) => {
                //console.debug("Get data from back end:" + JSON.stringify(data))
                data.forEach((e) => {
                    let item: FoodData = {
                        food_name: e["food_name"] , //+ (" (id:" + e["food_id"] + ")"),
                        food_id: e["food_id"],  
                        food_desc: e["food_desc"],
                        food_type: e["food_typ_id"],
                        created_date: e["created_date"],
                        food_image: e["food_image"],
                        state: e["state"],
                        created_by: e["created_by"],
                        user_id: e["user_id"],
                        votes: e["votes"],
                        category_name : catName,
                        category_id : catid,
                    } ;
                    
                    //item = this.addChildrenNode(item);
                    
                    this.foodList.push(item );
                    this.hasData = true;

                    this.currFoodId = "-1";
                });

                this.gridsource.localdata = this.foodList;
                //console.debug("FoodList: " + JSON.stringify(this.foodList));

                this.jqxgrid.updatebounddata();
            }, error:err => {
            console.error('Observer got an error: ' + err);
            alert('Lỗi kết nối mạng! ');
        }});
  }

  // Load user upload data
  reloadGridUserData(catid: string,catName: string) {
    this.foodList = [];
    this.hasData =false;
  //  console.debug("Call:reloadGridData");
    this.dbConnect
        .getUserFoodList(catid)
        .subscribe({next:(data: []) => {
                //console.debug("Get data from back end:" + JSON.stringify(data))
                this.foodList = [];
                data.forEach((e) => {
                    let item: FoodData = {
                        food_name: e["food_name"] , //+ (" (id:" + e["food_id"] + ")"),
                        food_id: e["usr_favor_food_id"],  
                        food_desc: e["food_desc"],
                        food_type: e["food_typ_id"],
                        created_date: e["created_date"],
                        food_image: e["food_image"],
                        state: e["state"],
                        created_by: e["created_by"],
                        user_id: e["user_id"],
                        votes: e["votes"],
                        category_name : catName,
                        category_id : catid,
                    } ;
                    
                    //item = this.addChildrenNode(item);

                    console.log(e);
                    
                    this.foodList.push(item );
                    this.hasData = true;

                    this.currFoodId = "-1";
                });

                this.gridsource.localdata = this.foodList;
                //console.debug("FoodList: " + JSON.stringify(this.foodList));

                this.jqxgrid.updatebounddata();
            }, error:err => {
            console.error('Observer got an error: ' + err);
            alert('Lỗi kết nối mạng! ');
        }});
  }
 
// select row cua grid
rowselect(event: any): void {
    // event arguments.
    let args = event.args;
    // selected row.
    let row = event.args.row;
    // update inputs.
    this.FoodName.val(row.food_name);
    this.FoodDesc.val(row.food_desc);
   // this.FoodType.val(row.food_type);
    this.FoodCat.val(row.category_name);
    this.CreatedBy.val(row.created_by);
    this.CreatedDate.val(row.created_date);
    if (row.food_image) {
      this.imgUrl = 
         (row.food_image.slice(0,8)=="https://" ? row.food_image :  myGlobal.image_lib_base +  row.food_image);
    } else {
      this.imgUrl = ""
    }
    this.currFoodId = row.food_id;

    
    this.currFoodData = this.jqxgrid.getrowdata(row.boundindex);
    
    this.foodType = row.food_type;
    //this.typeSlider.setValue(row.food_type);
    this.foodTypeComboBox.selectItem(row.food_type);

   // alert("Data=" + JSON.stringify(row));
}; 

  // Cap nhat thong tin
  saveClick(): void {
    let row = this.jqxgrid.getselectedrowindex();
    
    let rowid = this.jqxgrid.getrowid(row);
    // if (row.food_id != this.currFoodId) {
      
    // }
    //alert(row);
    let data = this.currFoodData;

    //console.debug("Data=" + JSON.stringify(this.currFoodData));
    //alert("Data=" + JSON.stringify(this.currFoodData));


    data.food_name= this.FoodName.val();
    data.food_desc= this.FoodDesc.val();
   // data.food_type= this.FoodType.val();
    data.created_by= this.CreatedBy.val();
    data.created_date= this.CreatedDate.val();
    data.food_type = this.foodTypeComboBox.getSelectedItem()["value"];//this.typeSlider.getValue();
    this.jqxgrid.updaterow(rowid, data);

    // Save to DB
   

    var body =  {
      food_name: this.currFoodData["food_name"],
      food_desc: this.currFoodData["food_desc"],      
      category_id: this.currFoodData["category_id"],     // Luu y doi ten truong
      food_image: this.currFoodData["food_image"],
      food_typ_id: data.food_type,//this.typeSlider.getValue(),
      created_by: 'system',
      votes: 0,
    };


    
    this.dbConnect.updateFoodData(
      this.currFoodData["food_id"],
      body
      ).subscribe({next:(result) => {
          
        console.log("Phe duyet thanh cong");
          console.log(body);
          if (this.isUserFood) {
            alert('Phê duyệt thức ăn thành công ' + this.currFoodData["food_name"] );
          }{
            alert('Cập nhật thức ăn thành công ' + this.currFoodData["food_name"] );
          }
          
          //this._location.back();
          this.dbConnect.sysLog('FoodManComponent','submit','Modify Food Data= ' +  this.currFoodData["food_name"]);

          this.jqxgrid.refreshdata();
      
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }});

  };

  onFoodApprove() {
  //  let data = this.currFoodData;

    var body =  {
      food_name: this.FoodName.val(),
      food_desc: this.FoodDesc.val(),      
      category_id: this.currFoodData["category_id"],     // Luu y doi ten truong
      food_image: this.currFoodData["food_image"],
      food_typ_id: this.foodTypeComboBox.getSelectedItem()["value"],//this.typeSlider.getValue(),
      created_by: 'system',
      votes: 0,
    };

    if (!body.category_id || body.category_id == 0){
      alert('Cần chuyển đổi nhóm trước khi thực hiện phê duyệt! Hãy ấn nút đổi nhóm để chọn nhóm mới');
      return;
    }

    this.dbConnect.addFoodData(
    body
    ).subscribe({next: (result) => {

        
        console.debug(result);

        var body2 = { 
          category_id: result.insertId
        }

        this.dbConnect.patchUserFoodById(this.currFoodId,body2).subscribe({next: (result) => {
          alert('Phê duyệt thức ăn thành công ' +  body.food_name );
        }, error: err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }});

        //this._location.back();
        this.dbConnect.sysLog('FoodManComponent', 'food Approve', ' Food Data= ' +  body.food_name);

    }, error: err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});
     
     
}

  // Doi sang nhom khac
  changeCatClick() {
    if (this.currFoodData) {
      this.openCatSelectorDialog("Đổi nhóm","Hãy chọn nhóm cần chuyển? ").subscribe(result => {
        
        if (result!="0") {
          let selectCat = this.allFoodCat.find(e => e.category_id == result )
         //alert('You select ' + JSON.stringify(selectCat));
         alert("Đổi nhóm thành công!, Ấn nút cập nhật để lưu thông tin!");
          this.FoodCat.val(selectCat.category_name);
          this.currFoodData['category_name'] = selectCat.category_name;
          this.currFoodData['category_id'] = selectCat.category_id;
        }
      });
    }
  }

  expanded(event: any): void {
    this.jqxgrid.refresh();
  };

  openDialog(title:string, msg: string): Observable<any>{
    const dialogRef = this.dialog.open(MsgDialogComponent, {
      width: '250px',
      data: {title, msg}
    });
    return dialogRef.afterClosed();
  }

  openCatSelectorDialog(title:string, msg: string): Observable<any>{
    const dialogRef = this.dialog.open(CatSelectorComponent, {
      width: '350px',height: '480px',
      data: {title, msg, catType: myGlobal.CAT_TYPE_FOOD}
    });
    return dialogRef.afterClosed();
  }

  changeImageClick() {    
    this.openImageSelectorDialog("Chọn ảnh","Hãy chọn nhóm").subscribe(result => {      
      if (result!="0") {        
        //alert(result);
        this.currFoodData['food_image'] = result;
        this.imgUrl = (this.currFoodData['food_image'].slice(0,8)=="https://" ? this.currFoodData['food_image'] :  myGlobal.image_lib_base +  this.currFoodData['food_image']);
          
       // this.currFoodData.controls.image.setValue(this.currFoodData['food_image']);

      }
    });    
  }
  openImageSelectorDialog(title:string, msg: string): Observable<any>{

    

    // const dialogRef = this.dialog.open(ImageGalleryComponent, {
    //   width: '650px',height: '360px',
    //   data: {title, msg}
    // });
    const dialogRef = this.dialog.open(ImageSelectorComponent, {
      width: '700px',height: '480px',
      //data: {title, msg}
    });
    return dialogRef.afterClosed();
  }



typeSliderOnChange(): void {
  //let red = this.fixHex(Math.round(this.typeSlider.value()).toString(16));
  this.foodType = this.foodTypeComboBox.getSelectedItem()["value"];//Math.round(this.typeSlider.value());
  this.currFoodData.food_type = this.foodType ;
  // if (this.currFoodData) {
  //     this.FoodType.val(this.currFoodData.food_type);  
  // }
}

fixHex(hex: any): any {
  return (hex.length < 2) ? '0' + hex : hex;
};

// tooltipFormatFunction: any = (value:any) => {
//   if (this.typeSlider.mode() === 'default') {
//       if (value < 10) return new Number(value.toPrecision(3));
//       if (value > 10 && value < 100) return new Number(value.toPrecision(4));
//       else return new Number(value.toPrecision(5));
//   }
//   else {
//       if (value < 10) return new Number(value.toPrecision(3));
//       if (value > 10 && value < 256) return new Number(value.toPrecision(4));
//   }
//   return "";
// };

tickLabelFormatFunction: any = (value:any) => {
  if (value == 1) return value;
  if (value == 5) return value;
  return "";
};

createButtonsContainers(statusbar: any): void {
  let buttonsContainer = document.createElement('div');
  buttonsContainer.style.cssText = 'overflow: hidden; position: relative; margin: 5px;';
  let addButtonContainer = document.createElement('div');
  let deleteButtonContainer = document.createElement('div');
  let reloadButtonContainer = document.createElement('div');
  let searchButtonContainer = document.createElement('div');
  addButtonContainer.id = 'addButton';
  deleteButtonContainer.id = 'deleteButton';
  reloadButtonContainer.id = 'reloadButton';
  searchButtonContainer.id = 'searchButton';
  addButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
  deleteButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
  reloadButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
  searchButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
  buttonsContainer.appendChild(addButtonContainer);
  buttonsContainer.appendChild(deleteButtonContainer);
  buttonsContainer.appendChild(reloadButtonContainer);
  buttonsContainer.appendChild(searchButtonContainer);
  statusbar[0].appendChild(buttonsContainer);
}
createButtons(): void {
  let addButtonOptions = {
      width: 80, height: 25, value: 'Add',
      imgSrc: '/assets/media/icons/add.png',
      imgPosition: 'center', textPosition: 'center',
      textImageRelation: 'imageBeforeText'
  }
  let addButton = jqwidgets.createInstance('#addButton', 'jqxButton', addButtonOptions);
  let deleteButtonOptions = {
      width: 80, height: 25, value: 'Delete',
      imgSrc: '/assets/media/icons/close.png',
      imgPosition: 'center', textPosition: 'center',
      textImageRelation: 'imageBeforeText'
  }
  let deleteButton = jqwidgets.createInstance('#deleteButton', 'jqxButton', deleteButtonOptions);
  let reloadButtonOptions = {
      width: 80, height: 25, value: 'Reload',
      imgSrc: '/assets/media/icons/refresh.png',
      imgPosition: 'center', textPosition: 'center',
      textImageRelation: 'imageBeforeText'
  }
  let reloadButton = jqwidgets.createInstance('#reloadButton', 'jqxButton', reloadButtonOptions);
  let searchButtonOptions = {
      width: 80, height: 25, value: 'Find',
      imgSrc: '/assets/media/icons/search.png',
      imgPosition: 'center', textPosition: 'center',
      textImageRelation: 'imageBeforeText'
  }
  let searchButton = jqwidgets.createInstance('#searchButton', 'jqxButton', searchButtonOptions);
  // add new row.
  addButton.addEventHandler('click', (event: any): void => {
      // let datarow = generatedata(1);
      // this.jqxgrid.addrow(null, datarow[0]);
      this.router.navigateByUrl('/food-detail/' + this.currFoodCatId + "/-1");
  });
  // delete selected row.
  deleteButton.addEventHandler('click', (event: any): void => {
      let selectedrowindex = this.jqxgrid.getselectedrowindex();
      let row = this.jqxgrid.getdatainformation().rowscount;
      let id = this.jqxgrid.getrowid(selectedrowindex);

      if ( this.currFoodId=="-1") return;
      this.openDialog("Xác nhận","Có chắc chắn muốn xóa thức ăn này? (id=" + this.currFoodId + ")").subscribe(result => {
        if (result=="1") {          
          if (this.isUserFood) {
            this.dbConnect.delUserFoodById(this.currFoodId).subscribe({next:(data: []) => {
              // alert("Ok, done!");
               //this.jqxTree.refresh();
                this.ngAfterViewInit();
                this.jqxTree.refresh();
                 this.router.navigateByUrl('/food-man', { skipLocationChange: false });
               this.jqxgrid.refreshdata();
               alert("Xóa dữ liệu thành công!");
               this.dbConnect.sysLog('FoodManComponent','tree','Delete food ID= ' +  this.currFoodId);
               this.jqxgrid.deleterow(id);
             }, error:err => {
               console.error('Observer got an error: ' + err);
               alert('Lỗi kết nối mạng! ');
             }});
          } else {
            this.dbConnect.delFoodById(this.currFoodId).subscribe({next:(data: []) => {
              // alert("Ok, done!");
               //this.jqxTree.refresh();
                this.ngAfterViewInit();
                this.jqxTree.refresh();
                 this.router.navigateByUrl('/food-man', { skipLocationChange: false });
               this.jqxgrid.refreshdata();
               alert("Xóa dữ liệu thành công!");
               this.dbConnect.sysLog('FoodManComponent','tree','Delete food ID= ' +  this.currFoodId);
               this.jqxgrid.deleterow(id);
             }, error:err => {
               console.error('Observer got an error: ' + err);
               alert('Lỗi kết nối mạng! ');
             }});
          }
                                        
        }               
      });
      
  });
  // reload grid data.
  reloadButton.addEventHandler('click', (event: any): void => {
      //this.jqxgrid.source(this.getAdapter());
  });
  // search for a record.
  searchButton.addEventHandler('click', (event: any): void => {
       this.findWindow.open();
       this.findWindow.move(220, 120);
  });

 
}
  // safely handles circular references
  JSONsafeStringify (obj:any, indent = 2)  {
    let cache:any = [];
    const retVal = JSON.stringify(
      obj,
      (key, value) =>
        typeof value === "object" && value !== null
          ? cache.includes(value)
            ? undefined // Duplicate reference found, discard key
            : cache.push(value) && value // Store value in our collection
          : value,
      indent
    );
    cache = null;
    return retVal;
  };
   
}

//======================

