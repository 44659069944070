<h1 >Image selector</h1>
<div class="content-container" fxLayout="column" fxLayoutAlign="stretch" fxFlex="auto">
<swiper #swiperComp class="swiper-container" 
        fxFlex="auto" [config]="config" [disabled]="disabled"
         (indexChange)="onIndexChange($event)" (swiperTransitionStart)="onSwiperEvent('transitionStart')"
          (swiperTransitionEnd)="onSwiperEvent('transitionEnd')" (click)="onSelectPicture($event)">
    <div *ngFor="let slide of slides" class="swiper-slide">
      <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
        <img src="{{slide.show_img_path}}" alt="{{slide.img_title}}" width="200" height="160" name="{{slide.img_path}}">
        <p>&nbsp;</p>
      </div>
    </div>
  </swiper >

</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="0" >Cancel</button>
  <button mat-button [mat-dialog-close]="selectImage" cdkFocusInitial>Select</button>
</div>
