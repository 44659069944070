export class DrugData {
    drug_id!: number;
    index! : number;  // Luu y phai khac voi truong trong DB idx
    drug_name! : string;
    ingredient!: string;
    form!: string;
    dosage!: string;
    using_time!: string;
    duration!: string;
    type!: number;    
  //  drug_cat_id! : number;
 //   ids!: string;  
    remark! : string;
    drug_data! : string;
    created_date!: Date;
    created_by! : string;
    category_id! :string;
    category_name! : string;
  }



//   `drug_id` bigint(20) NOT NULL AUTO_INCREMENT,
//   `idx` int(11) NOT NULL COMMENT 'Thu tu thuoc',
//   `drug_name` varchar(128) NOT NULL COMMENT 'Ten thuoc',
//   `ingredient` varchar(128) NOT NULL COMMENT 'Thanh phan thuoc',
//   `form` varchar(128)  COMMENT 'Dang vien thuoc',
//   dosage  varchar(128) COMMENT 'Lieu dung',
//   using_time varchar(128)  COMMENT 'Thoi gian dung',
//  duration varchar(128)  COMMENT 'Thoi diem dung',
//   `type` int(11) NOT NULL  DEFAULT '0' COMMENT '0 - nhom thuoc, 1 - ten thuoc',
//   `parent_id` bigint(20) NOT NULL DEFAULT '0' COMMENT 'Link den nhom cha',
//   `remark` text,
//   drug_data text  COMMENT 'Thong tin bo sung neu co',
//   `created_date` timestamp NULL DEFAULT NULL,
//   `created_by` varchar(45) DEFAULT NULL,