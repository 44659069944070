<form [formGroup]="userForm" (ngSubmit)="onSubmit()">
<div class="container bootstrap snippet">
    <div class="row">
        <div class="col-sm-12"><h1 class="text-primary">Image upload</h1>
         </div>        
  </div>
    <div class="row">        
  		    <div class="col-lg-6"><!--left col-->              
                <div class="form-group" >
                        <!-- label for="image" class="form-control-label">Click to image to change</label -->
                        <app-image-upload-preview                           
                            id="imgPreview" 
                            (fileName)="onUploadImage($event)"
                        ></app-image-upload-preview>
                </div>                          
            </div><!--/col-4-->
    	    <div class="col-lg-6">                                
                    <div class="form-group" [ngClass]="{ 'has-danger': invalidTitle() }"> <!-- Userr Name -->
                        <label for="title" class="form-control-label">Title *</label>
                        <input type="text" formControlName="title" class="form-control" 
                                [ngClass]="{ 'is-invalid': invalidTitle() }" id="title" name="title" placeholder="Tiêu đề ảnh"
                                (ngModelChange)="onTitleChange($event)">
                        <p *ngIf="invalidTitle()" id="title_error" class="invalid-feedback">Invalid image title.</p>
                    </div>

                    <div class="form-group" [ngClass]="{ 'has-danger': invalidDesc() }"> <!-- Telephone -->
                        <label for="desc" class="form-control-label">Description</label>
                        <input type="text" formControlName="desc" class="form-control" [ngClass]="{ 'is-invalid': invalidDesc() }" id="desc" name="desc" placeholder="Mô tả">
                        <p *ngIf="invalidDesc()" id="desc_error" class="invalid-feedback">Invalid description.</p>
                    </div>
                    <div class="form-group has-error">
                        <div class="col-xs-12">
                            <hr>
                            <button mat-button [mat-dialog-close]="1" 
                                    cdkFocusInitial class="btn-success" type="submit"
                                    [disabled]="!canSubmit">Submit</button>
                            &nbsp;
                            <button mat-button [mat-dialog-close]="0" class="bg-danger text-white" >Cancel</button>
                        </div>
                    </div>
              </div>
        </div>
    </div>
</form>
    