import { Component, OnInit , Inject} from '@angular/core';
import { DbconnectService } from 'src/app/dbconnect.service';
import {Location} from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import * as myGlobal from '../../global'; 
import { CdkStepperNext } from '@angular/cdk/stepper';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/authentication.service';
import { User } from 'src/app/models/user';

export interface DialogData {
  first_name: string;
  last_name: string;
}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})

export class UserProfileComponent implements OnInit {

  img_data! : string ;
  orgData! : object;
  userId : any;
  userData  = new User();

  currentUser!:User | null;

  debug_data! : String;

  public up4RegisterEnabled:boolean = false;
  public editEnabled = true;
  public smallsize = true;
  public picurl!: string;

  userForm!: UntypedFormGroup;
  submitted = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private dbConnect:DbconnectService,
    private formBuilder: UntypedFormBuilder,        
      public dialog: MatDialog,
      private authenticationService: AuthenticationService
    ) { }

  ngOnInit() {

    //this.currentUser = JSON.parse(localStorage.getItem('currentUser')+"") + "";
    //this.userId = this.route.snapshot.paramMap.get('userId');
    //this.getUserAvatar(this.userId);
    this.currentUser = this.authenticationService.currentUserValue;
    if (!this.currentUser) return;
    this.userId = this.currentUser.user_id;
    console.log(JSON.stringify(this.currentUser));
    
    this.userForm = this.formBuilder.group({
      org_id: ['Hoang Long Clinic', Validators.required],
     
      username: [this.currentUser.user_name, Validators.required],
  		// first_name: ['', Validators.required],
  		// last_name: ['', Validators.required],
      //email: ['', [Validators.required, Validators.email]]      
      telephone: [this.currentUser.telephone, Validators.required],
      email : [this.currentUser.email,null],
      webAppRoles: [{
        value: this.currentUser.roles,
        disabled: true
      }, Validators.required],
      oldpassword: ['', Validators.required],
      password: ['', Validators.required],
      repassword: ['', Validators.required],
    });
  }

  modifyUserProfile(userId:string, profileData:any): void {
    this.dbConnect.modifyUserProfile(userId,profileData)
        .subscribe({next:(result) => {          
          console.log('modifyUserProfile return ' + JSON.stringify(result));     
          if (result['errorCode']=="0") {
            this.openDialog( JSON.stringify(result));    
          } else {
            alert("Invalid password");
          }
          
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }
        });
  }

  getUserAvatar(userId:string): void {
    // this.usrProfileService.getUserProfile(userId)
    //     .subscribe(data => {
    //       this.orgData = data;
    //       this.img_data = data['imgdata'];
    //       console.log('getUserAvatar return data');
    //       //console.log('getUserAvatar return'+ JSON.stringify(data));
    //       this.userData.first_name = this.orgData['firstName'];
    //       this.userData.last_name = this.orgData['lastName'];
    //       this.userData.telephone = this.orgData['telephone'];
    //       this.userData.email = this.orgData['email'];
    //       this.userData.ext_id = this.orgData['extId'];
         
    //       this.userForm.controls.org_id.setValue( this.orgData['orgId']);
    //       this.userForm.controls.first_name.setValue(  this.orgData['firstName']);
    //       this.userForm.controls.last_name.setValue(  this.orgData['lastName']);
    //       this.userForm.controls.telephone.setValue(  this.orgData['telephone']);
    //       this.userForm.controls.email.setValue(  this.orgData['email']);
    //       this.userForm.controls.ext_id.setValue(  this.orgData['extId']);
          
          
    //     });
  }

  //--------------------------
  onAvatarIdChanged(avatarId: number) {
   // this.userId = avatarId;
   
    
  }

  invalidAvatar()
  {
  	return ( this.userId==0);
  }

  invalidRoles ()
  {
    return false;
  }

  invalidUserName ()
  {
    return false;
  }

  invalidOldPassword ()
  {
    return false;
  }
  invalidPassword ()
  {
    return false;
  }

  invalidRePassword ()
  {
    return false;
  }


  invalidOrgId()
  {
    return (this.submitted && this.userForm.controls.org_id.errors != null);
  }

  invalidExtId()
  {
    return (this.submitted && this.userForm.controls.ext_id.errors != null);
  }

  invalidFirstName()
  {
    return (this.submitted && this.userForm.controls.first_name.errors != null);
  }

  invalidLastName()
  {
  	return (this.submitted && this.userForm.controls.last_name.errors != null);
  }

  invalidTelephone()
  {
  	return (this.submitted && this.userForm.controls.telephone.errors != null);
  }

  invalidEmail()
  {
  	return (this.submitted && this.userForm.controls.email.errors != null);
  }

  onSubmit()
  {  

    this.submitted = true;
    if (this.userForm.controls.password.value!="") {
        if (this.userForm.controls.password.value != this.userForm.controls.repassword.value) {
          alert("Password không khớp");
          return ;
        }
    }
        
  	// if(this.userForm.invalid == true || this.userId==0)
  	// {
    //   this.debug_data = 'Invalid data!'
    //   alert("Invalid data");
  	// 	return;
  	// }
  	// else
  	// {
      var profileData:any = {}
      profileData['user_id'] = this.userId;
      // profileData['orgId'] = this.userForm.controls.org_id.value;
      // profileData['extId'] = this.userForm.controls.ext_id.value;
      profileData['user_name'] = this.userForm.controls.username.value;
      // profileData['lastName'] = this.userForm.controls.last_name.value;
      profileData['telephone'] = this.userForm.controls.telephone.value;
      profileData['email'] = this.userForm.controls.email.value;

      profileData['oldPassword'] = this.userForm.controls.oldpassword.value;
      profileData['newPassword'] = this.userForm.controls.password.value;
     
      this.debug_data = 'Submited!'
     // console.log("profileData:" + JSON.stringify(profileData));
      this.modifyUserProfile(this.userId,profileData);
     // this.openDialog();
    // }
  }

  openDialog(message: string): void {
    const dialogRef = this.dialog.open(FinishModifyDialog, {
      width: '350px',
      data: {first_name: message, 
              last_name: this.userForm.controls.username.value }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this._location.back();
     
    });
  }
}


@Component({
  selector: 'finish-register-dialog',
  templateUrl: 'finish-modify-usrprofile-dlg.html',
})

export class FinishModifyDialog {

  constructor(
    public dialogRef: MatDialogRef<FinishModifyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}