
<nav class="navbar navbar-expand-xl  navbar-dark bg-primary" >
    &nbsp;<a class="navbar-brand" href="#"><i class="fas fa-hospital-user"></i> GERD Admin</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent" >
      <ul class="navbar-nav mr-auto">

        <li class="nav-item dropdown" *ngIf="editor">
          <a class="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Báo cáo thống kê
          </a>
          <div class="dropdown-menu shadow p-2 mb-3 bg-white rounded" aria-labelledby="navbarDropdown">
            <a class="dropdown-item " href="./rpt-common"> <i class="fas fa-user-clock"></i>&nbsp; Báo cáo thông tin chung</a>
            <a class="dropdown-item " href="./rpt-gerdq"> <i class="fas fa-user-clock"></i>&nbsp; Báo cáo GERDQ</a>
            <a class="dropdown-item " href="./rpt-sympton"> <i class="fas fa-user-clock"></i>&nbsp; Báo cáo triệu chứng</a>
            <a class="dropdown-item " href="./rpt-drug"> <i class="fas fa-user-clock"></i>&nbsp; Báo cáo theo dõi dùng thuốc</a>
            <!-- div class="dropdown-divider"></div>
            <a class="dropdown-item " href="./message-man"><i class="fas fa-file-alt"></i>&nbsp; Báo cáo các loại</a -->     
            <div class="dropdown-divider"></div>
       
            <a class="dropdown-item " href="./exp-gerd"><i class="fas fa-file-excel"></i>&nbsp; Export GERD Q</a>  
            <a class="dropdown-item " href="./exp-daily"><i class="fas fa-file-excel"></i>&nbsp; Export Daily symptoms</a> 
            <a class="dropdown-item " href="./exp-food"><i class="fas fa-file-excel"></i>&nbsp; Export Food</a> 
            <a class="dropdown-item " href="./exp-drug"><i class="fas fa-file-excel"></i>&nbsp; Export Đơn thuốc</a>  
     

          </div>      
        </li>

        <li class="nav-item dropdown" *ngIf="sysadmin">
          <a class="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Quản lý bệnh nhân
          </a>
          <div class="dropdown-menu shadow p-2 mb-3 bg-white rounded" aria-labelledby="navbarDropdown">
            <!-- a class="dropdown-item " href="./patient-man"><i class="fa fa-plus-circle"></i>&nbsp; Quản lý bệnh nhân</a -->                        
            <a class="dropdown-item " href="./questanswer-man"><i class="fa fa-medkit"></i>&nbsp; Quản lý giải đáp của bệnh nhân</a>
          </div>      
        </li>
     
  
        <li class="nav-item dropdown" *ngIf="sysadmin">
          <a class="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Quản lý nội dung
          </a>
          <div class="dropdown-menu shadow p-2 mb-3 bg-white rounded" aria-labelledby="navbarDropdown">
            <a class="dropdown-item " href="./drug-man"><i class="fa fa-plus-circle"></i>&nbsp; Quản lý thuốc</a>            
            <a class="dropdown-item " href="./filterquest-man"><i class="fa fa-heartbeat"></i>&nbsp; Quản lý câu hỏi</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item " href="./food-man"> 	<i class="fas fa-utensils"></i>&nbsp; Quản lý thức ăn</a>
            <a class="dropdown-item " href="./img-man"> 	<i class="fas fa-images" ></i>&nbsp; Quản lý ảnh</a>
            
            
          </div>      
        </li>

        <li class="nav-item dropdown" *ngIf="editor">
          <a class="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Quản lý thông tin
          </a>
          <div class="dropdown-menu shadow p-2 mb-3 bg-white rounded" aria-labelledby="navbarDropdown">  
            <a class="dropdown-item " href="./news-list"> <i class="fas fa-file-alt"></i>&nbsp; Quản lý bài viết</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item " href="./video-list"><i class="fas fa-file-video"></i>&nbsp; Quản lý Video</a>    
            <div class="dropdown-divider"></div>
            <a class="dropdown-item " href="./notify-list"><i class="far fa-bell"></i>&nbsp; Quản lý thông báo</a>          
          </div>      
        </li>

        <!-- li class="nav-item dropdown" *ngIf="editor">
          <a class="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Báo cáo thống kê
          </a>
          <div class="dropdown-menu shadow p-2 mb-3 bg-white rounded" aria-labelledby="navbarDropdown">
            <a class="dropdown-item " href="./rpt-common"> <i class="fas fa-user-clock"></i>&nbsp; Báo cáo thông tin chung</a>
            <a class="dropdown-item " href="./rpt-gerdq"> <i class="fas fa-user-clock"></i>&nbsp; Báo cáo GERDQ</a>
            <a class="dropdown-item " href="./rpt-sympton"> <i class="fas fa-user-clock"></i>&nbsp; Báo cáo triệu chứng</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item " href="./message-man"><i class="fas fa-file-alt"></i>&nbsp; Báo cáo các loại</a>     
            <div class="dropdown-divider"></div>
            <a class="dropdown-item " href="./data-export"><i class="fas fa-file-excel"></i>&nbsp; Export số  liệu</a>       
          </div>      
        </li -->
  
        <li class="nav-item dropdown" *ngIf="sysadmin">
          <a class="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Quản trị hệ thống
          </a>
          <div class="dropdown-menu  shadow p-2 mb-3 bg-white rounded" aria-labelledby="navbarDropdown">
            <a class="dropdown-item " href="./system-log"><i class="fa fa-wrench"></i>&nbsp; Nhật ký hệ thống</a>    
            <div class="dropdown-divider"></div>
            <a class="dropdown-item " href="./user-man"><i class="fa fa-users"></i>&nbsp; Quản trị người dùng</a>     
          </div>      
        </li>
  
        <li class="nav-item active" *ngIf="currentUser">
          <a class="nav-link " href="./user-profile" tabindex="-1" aria-disabled="false">
            <img *ngIf="img_data" class="avatarimg"  src="data:image/jpeg;base64,{{img_data}}" alt="Người dùng"/>
            </a>
        </li>
  
        <li class="nav-item active" *ngIf="currentUser">
          <a class="nav-link" (click)="logout()"  tabindex="-1" aria-disabled="false">
            <i class="fa fa-sign-out"></i>  Thoát
          </a>
        </li>
  
        <li class="nav-item active" *ngIf="!currentUser">
          <a class="nav-link" (click)="login()"  tabindex="-1" aria-disabled="false">
            <i class="fa fa-sign-in"></i>  Đăng nhập
          </a>
        </li>
  
      </ul>
      <form class="form-inline my-2 my-lg-0 text-nowrap" [formGroup]="userForm">      
        <div class="input-group mb-3">
        <input type="text" formControlName="searchData" class="form-control mr-sm-2" id="searchData" name="searchData" placeholder="Search">  
        <div class="input-group-append">
        <button class="text-nowrap btn btn-outline-white my-2 my-sm-0"  type="submit" (click)="doSearch()"><i class="fa fa-search"></i></button>
      </div>
      </div>
      </form>
      
  
      
      
    </div>
  </nav>
  <!------------------------------------------->
  <router-outlet>
  </router-outlet>
  <app-size-detector></app-size-detector>

  <!-- jqxGrid 
	[selectionmode]="'checkbox'" 
	[source]="source" 
	[theme]="'material'" 
	[autoheight]="true" 
	[pageable]="true" 
	[altrows]="true" 
	[filterable]="true" 
	[sortable]="true" 
	[columns]="columns" 
#grid>
</jqxGrid --> 
 