import { Component,Inject, ViewChild, ElementRef, AfterViewInit,OnInit , Input, Output, EventEmitter } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import { Observable, of } from 'rxjs';

import{ jqxSplitterComponent } from 'jqwidgets-ng/jqxsplitter';
import { jqxExpanderComponent } from 'jqwidgets-ng/jqxexpander';
import { jqxTreeComponent } from 'jqwidgets-ng/jqxtree';
import { jqxPanelComponent } from 'jqwidgets-ng/jqxpanel';
import { jqxListBoxComponent } from 'jqwidgets-ng/jqxlistbox';
import { jqxInputComponent } from 'jqwidgets-ng/jqxinput';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxMenuComponent } from 'jqwidgets-ng/jqxmenu';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxSliderComponent } from 'jqwidgets-ng/jqxslider';

import { DbconnectService } from 'src/app/dbconnect.service';

import * as myGlobal from '../../global';
import {FoodData, CatNode} from '../../models/food-category';
import { MsgDialogComponent } from 'src/app/utils/msg-dialog/msg-dialog.component';
//import { stringify } from '@angular/compiler/src/util';
import { CatSelectorComponent } from 'src/app/utils/cat-selector/cat-selector.component';
import { ImageGalleryComponent } from 'src/app/utils/image-gallery/image-gallery.component';
//import { MessageDialog } from 'src/app/utils/message-dialog/message-dialog';


@Component({
  selector: 'app-cat-tree',
  templateUrl: './cat-tree.component.html',
  styleUrls: ['./cat-tree.component.scss']
})

export class CatTreeComponent  implements OnInit,AfterViewInit  {

  @ViewChild('jqxTree', { static: true }) jqxTree!: jqxTreeComponent;
  @ViewChild('treeMenu', { static: false }) treeMenu!: jqxMenuComponent;

  catTree:any[] = [];  // Cay food category  //catTree
  allCat : any[] = [];  // Chua tat ca cac category  //allCat

  @Input() catType!: number ;
  @Output() selectCatId = new EventEmitter<string>();
  @Output() addNewItemCatId = new EventEmitter<string>();

  treesource:any = {
    datatype: 'json',
    datafields: [
        { name: 'id', type: 'string' },
        { name: 'category_name' , type: 'string' },
        { name: 'remark' , type: 'string' },
        { name: 'parentid' , type: 'string' },
        { name: 'image' , type: 'string' },
        { name: 'icon' , type: 'string' },
    ],
    id: 'id',
    localdata: this.catTree
  };
  treedataAdapter :any;
  treerecords :any;  

  constructor(
    private dbConnect : DbconnectService,
    private route: ActivatedRoute,
    private _location: Location,
    private router: Router,
    public dialog: MatDialog
  ) {
    
   // this.catType = myGlobal.CAT_TYPE_DRUG;
    
  }

  ngOnInit(): void  {
    console.debug("catType=",this.catType);
  }

  ngAfterViewInit(): void 
  {

    //  this.getFeed("sciencedaily");
   // console.debug("catType=",this.catType);
    this.dbConnect
    .getAllCatList(this.catType)
    .subscribe({next:(data: []) => {
      this.allCat = data;
      data.forEach((e) => {
         // if (e["parent_id"] == "-1") {
              let item: CatNode = {
                  category_name: e["category_name"], // + (" (" + e["category_id"] + ")"),
                  id: e["category_id"],  
                  parentid: e["parent_id"],
                  remark: e["remark"],
                  image: e["image"],   
                  icon: e["image"],
                  locked: e["locked"] 
                } ;
       
              this.catTree.push(item );
          //}
      });
      
        this.treesource.localdata = this.catTree;
        this.treedataAdapter = new jqx.dataAdapter(this.treesource);
        this.treedataAdapter.dataBind();

        this.treerecords = this.treedataAdapter.getRecordsHierarchy('id', 'parentid', null, 
            [{ name: 'category_name', map: 'label' }]);
            this.jqxTree.expandAll();
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});

  }

  
  onTreeRightClick(event: any): boolean {
    event.preventDefault();
    console.debug("Event:" + JSON.stringify(event));
    this.jqxTree.selectItem((<HTMLElement>event.target).parentNode);
    let scrollTop = window.scrollY;
    let scrollLeft = window.scrollX;
    this.treeMenu.open(event.clientX + 5 + scrollLeft, event.clientY + 5 + scrollTop);

    return true;
  }



  treeOnSelect(event: any): void {
   // this.ContentPanel.nativeElement.innerHTML = '<div style="margin: 10px;">' + event.args.element.id + '</div>';    
    console.log("Select: " +event.args.element.id );

    let selectedItem = this.jqxTree.getSelectedItem();
    if (selectedItem != null) {
        this.jqxTree.expandItem(selectedItem.element);
    }

    
   // this.jqxTree.expandAll();
   // console.debug(this.allCat);
    let currCat = this.allCat.find(e => e.category_id == event.args.element.id );
  //  console.debug(currCat);
    if (currCat) {
      // this.currFoodCatId = event.args.element.id;
      // this.reloadGridData(event.args.element.id,currCat.category_name);
      this.selectCatId.emit(currCat.category_id);
    } else {
      // this.currFoodCatId = -1;
      // this.foodList = [];
      // this.hasData =false;
    }
  
  }; 

  treeMenuOnItemClick(event: any): void {
        let item = event.args.innerText;
        
        let selectedItem: any = this.jqxTree.getSelectedItem();
        if (selectedItem == null) return;
        let currCat = this.allCat.find(e => e.category_id == selectedItem.id );

        console.debug(JSON.stringify(item));
        item = item.trim();
        switch (item) {
            case "Add SubItem":
               // selectedItem = this.jqxTree.getSelectedItem();
                console.debug(selectedItem); 
                if (currCat.locked!="1") {
                 // this.router.navigateByUrl('/food-cat-detail/' + selectedItem.id + '/' + selectedItem.parentId );
                 // this.router.navigateByUrl('/food-detail/' + selectedItem.id + "/-1");
                  this.addNewItemCatId.emit(selectedItem.id);
                }
                    //this.jqxTree.addTo({ label: 'Item',icon :'/assets/media/icons/folder.png' }, selectedItem.element);
                
                break;
            case "Remove Item":
                //selectedItem = this.jqxTree.getSelectedItem();
                
                this.jqxTree.removeItem(selectedItem.element);
                
                break;
            case "Edit Category":                
                console.log(selectedItem);
                if (currCat.locked!="1") {
                  this.router.navigateByUrl('/cat-detail/' + this.catType + '/' + selectedItem.id + '/' + selectedItem.parentId );
                }
                
                break;
            case "New Category":                
                console.log(selectedItem);
               
                if (selectedItem != null) {
                  let currCat = this.allCat.find(e => e.category_id == selectedItem.id );
                  //if (currCat.locked!="1") {
                  this.router.navigateByUrl('/cat-detail/' + this.catType + '/-1/' + selectedItem.id );
                  //}
                }
                break;
            case "Move Up": 
                this.doMoveUpFoodCat(selectedItem);
                break;
            case "Move Down":
                this.doMoveDownFoodCat(selectedItem);
                break;
            case "Move In":
                this.doMoveIn(selectedItem);
                break;
            case "Move Out":
                this.doMoveOut(selectedItem);
                break;
            case "Delete Category":                
                console.log(selectedItem);
               
                if (selectedItem != null) {
                  let currCat = this.allCat.find(e => e.category_id == selectedItem.id );
                  console.debug(currCat);
                  this.openDialog("Xác nhận","Có chắc chắn muốn xóa dữ liệu? ").subscribe(result => {
                      if (result=="1") {
                        this.dbConnect.checkCatHasChild(selectedItem.id).subscribe({next:(data: []) => {
                            if (data.length==0) {
                              this.dbConnect.delCatById(selectedItem.id).subscribe({next:(data: []) => {
                               // alert("Ok, done!");
                                //this.jqxTree.refresh();
                                 this.ngAfterViewInit();
                                 this.jqxTree.refresh();
                                this.router.navigateByUrl('/food-man', { skipLocationChange: false });
                              }, error:err => {
                                console.error('Observer got an error: ' + err);
                                alert('Lỗi kết nối mạng! ');
                              }});                              
                            } else {
                              alert("Không xóa được! Có thể đang có đối tượng bên trong hoặc bị đánh dấu không xóa được!");
                            }
                        }, error:err => {
                          console.error('Observer got an error: ' + err);
                          alert('Lỗi kết nối mạng! ');
                        }});
                      }                  
                  });
                }
                break;
        }
    }

    // Chuyen item tren tree xuong 1 bac
  doMoveDownFoodCat(selectedItem: any) {
    
    let currCat = this.catTree.find(e => e.id == selectedItem.id );    
    

    let pos = this.findWithAttr(this.catTree,"id",selectedItem.id);    
    if ((pos>this.catTree.length-1) || (pos==-1)) return; // cuoi danh sach
    
    let nextPos = this.findNextBotherItem(currCat);

    if (this.catTree[nextPos]['parentid']==currCat['parentid']) { // Chi move khi cung parent      
      let temp = this.catTree[pos];
      let idx = temp['idx'];      
      this.catTree[pos] = this.catTree[nextPos];      
      this.catTree[nextPos] = temp;      
      
      this.reorderAndRefreshTree();
    }   
  }
  
  // Chuyen item tren tree len 1 bac
  doMoveUpFoodCat(selectedItem: any) {
    
    let currCat = this.catTree.find(e => e.id == selectedItem.id );    
    let pos = this.findWithAttr(this.catTree,"id",selectedItem.id);    
    if (pos<=0)  return; // dau danh sach

    let lastPos = this.findLastBotherItem(currCat);
    if (lastPos==-1) return;

    if (this.catTree[lastPos]['parentid']==currCat['parentid']) { // Chi move khi cung parent      
      let temp = this.catTree[pos];
      let idx = temp['idx'];      
      this.catTree[pos] = this.catTree[lastPos];
      this.catTree[lastPos] = temp;      
         
      this.reorderAndRefreshTree();
    }
  }

  // Chuyen item tren tree len lop ngoai
  doMoveOut(selectedItem: any) {
    let currCat = this.catTree.find(e => e.id == selectedItem.id );    
    let pos = this.findWithAttr(this.catTree,"id",selectedItem.id);    
    if (pos<=0)  return; // dau danh sach

    let lastPos =  this.findFatherItem(currCat);

    if (this.catTree[lastPos]['id']==currCat['parentid'] &&
    this.catTree[lastPos]['parentid']!=-1
      ) { // Chi move khi cung parent      
      
      this.catTree[pos]['parentid'] = this.catTree[lastPos]['parentid'];
      
      this.reorderAndRefreshTree();
    }
  }

  // Chuyen item tren tree len lop trong
  doMoveIn(selectedItem: any) {
    let currCat = this.catTree.find(e => e.id == selectedItem.id );    
    let pos = this.findWithAttr(this.catTree,"id",selectedItem.id);    
    if (pos<=0)  return; // dau danh sach

    let lastPos =  this.findLastBotherItem(currCat);

    if (this.catTree[lastPos]['parentid']==currCat['parentid']) { // Chi move khi cung parent      
      
      this.catTree[pos]['parentid'] = this.catTree[lastPos]['id'];
      
      this.reorderAndRefreshTree();
    }
  }

  // Tim theo thuoc tinh
  findWithAttr(array: any, attr: any, value: any) {
    for(var i = 0; i < array.length; i += 1) {
        if(array[i][attr] == value) {
            return i;
        }
    }
    return -1;
}

// Tim phan tu lien ke phia truoc - cha hoac anh em
findLastBotherItem(currCat: any):number {    
  console.debug("Me Id:" + currCat['id'])
  let last = -1;
  for (let i= 0; i<this.catTree.length; i++ ) {
    if (this.catTree[i]['id']==currCat['parentid']) { // Tim thay ong bo
      last = i;
      console.debug("Father:" + this.catTree[i]['id'])
      continue;
    }
    if (this.catTree[i]['parentid']==currCat['parentid'] &&
        this.catTree[i]['id']!=currCat['id']) { // Tim thay anh em
      last = i;
      console.debug("Brother:" + this.catTree[i]['id'])
      continue;
    }
    if (this.catTree[i]['id']==currCat['id']) { // Tim thay chinh minh        
      break;
    }
  }
  return last;
}

findNextBotherItem(currCat: any):number {    
  console.debug("Me Id:" + currCat['id'])
  let me = -1;
  for (let i= 0; i<this.catTree.length; i++ ) {
    if (this.catTree[i]['id']==currCat['id']) { // Tim thay chinh minh  
      me = i;      
      continue;
    }    
    if (this.catTree[i]['parentid']==currCat['parentid'] &&
        me !=-1 ) { // Tim thay anh em       
      console.debug("Small Brother:" + this.catTree[i]['id'])
      return i;
    }
    
  }
  return -1;
}

// Tim phan tu lien ke phia truoc - cha hoac anh em
findFatherItem(currCat: any):any {        
  for (let i= 0; i<this.catTree.length; i++ ) {
    if (this.catTree[i]['id']==currCat['parentid']) { // Tim thay ong bo
      return i;        
    }      
  }
  return -1;
}

reorderAndRefreshTree() {

  for (let i= 0; i<this.catTree.length; i++ ) {
    this.catTree[i]['idx'] = i+1;

    // Hien cho cap nhat toan bo cat list, do chua danh dau item nao da doi parent & so luong cat it
    var body =  {       
      parent_id: this.catTree[i]['parentid'],     // Luu y doi ten truong
      idx:  i+1
    };
    this.dbConnect.updateCat(
        this.catTree[i]['id'],
        body
      ).subscribe({next:(result) => {                    
        ;        
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
        i = this.catTree.length ; // break
      }});

  }

  // refresh data
  this.treesource.localdata = this.catTree;
  this.treedataAdapter = new jqx.dataAdapter(this.treesource);
  this.treedataAdapter.dataBind();
  this.treerecords = this.treedataAdapter.getRecordsHierarchy('id', 'parentid', null, 
      [{ name: 'category_name', map: 'label' }]);
  this.jqxTree.expandAll();
}

  openDialog(title:string, msg: string): Observable<any>{
    const dialogRef = this.dialog.open(MsgDialogComponent, {
      width: '250px',
      data: {title, msg}
    });
    return dialogRef.afterClosed();
  }
  
}

