import {Component, OnInit, Input, Output} from '@angular/core';
import { DbconnectService } from 'src/app/dbconnect.service';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';

import {FilterQuestionElement} from '../../models/question';
import { DrugData } from 'src/app/models/drug';
import * as myGlobal from '../../global'
import { CatNode } from 'src/app/models/food-category';
import { Subject } from "rxjs";

@Component({
  selector: 'app-drug-detail',
  templateUrl: './drug-detail.component.html',
  styleUrls: ['./drug-detail.component.scss']
})
export class DrugDetailComponent implements OnInit {

  drug_list:any = []; // Lay toan bo danh sach de tinh index cho de
  allDrugCat : any[] = [];  // Chua tat ca cac category
  catData! : any;

  category_id : any = '0';
  idsIndex : any  = '0';

  currDrugId : any  = '0';
  currDrugData:any = [];
  userForm!: UntypedFormGroup;

 // @Input() drugId: string;

  createNew = false;

  parentObj:any = null;

  //@Input() resetFormSubject: Subject<boolean> = new Subject<boolean>();
  @Input() drugId!: string;
  @Input() catId!: number;

  callFromDrugMan:boolean = false;

  // Time for drug
  t4drugCtl = new UntypedFormControl();
  t4drugWhen: number = 0;
  t4drugList: string[] = ['Bữa sáng', 'Bữa trưa', 'Bữa tối', 'Trước khi ngủ'];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private dbConnect:DbconnectService,
    private formBuilder: UntypedFormBuilder,        
      public dialog: MatDialog
  ) { }

  ngOnInit() {

    if (!this.drugId) {
      this.category_id = this.route.snapshot.paramMap.get('category_id');
      this.idsIndex = this.route.snapshot.paramMap.get('idsIndex');
      this.currDrugId = this.route.snapshot.paramMap.get('drugId');

      if (this.category_id=='0' || (Object.keys(this.category_id).length === 0)) { // Chuan hoa parentId
        this.category_id = null;
      }
    } else {
      this.currDrugId = this.drugId;
      this.category_id = this.catId;
      this.callFromDrugMan = true;
    }

    this.createNew = this.currDrugId=='0' || this.currDrugId=='-1';
    console.debug("drugId:" + this.currDrugId +" category_id = " + this.category_id)
    
    this.userForm = this.formBuilder.group({      
      drug_name: ['', Validators.required],
      ingredient: ['', Validators.required],
      form: ['', Validators.nullValidator],
      dosage: ['', Validators.nullValidator],
      using_time: ['', Validators.nullValidator],
      duration: ['', Validators.nullValidator],
      remark: ['', Validators.nullValidator],
      t4drugWhen: ['', Validators.nullValidator],
     // index: ['', Validators.nullValidator],
     // created_date: ['', Validators.nullValidator],
    });

    this.getAllCatList();  

    if (!this.createNew) {      
       this.getDrugById(this.currDrugId );
    } 
    
  }

  ngOnChanges() {    
    this.currDrugId = this.drugId;
    this.category_id = this.catId;
    this.createNew = (this.drugId=="0") || (this.drugId=="-1");
    this.getDrugById(this.currDrugId );
  }   
 
  getDrugById(drugId: any ) {
    if (drugId=="-1" || drugId=="0")
      {return}
    console.debug("getDrugById:" + drugId);
    this.currDrugData = [];
    this.dbConnect.getDrugById(drugId).subscribe((data: any[]) => {
      this.currDrugData = data[0];     
      
      console.log("getDrugById get:" + JSON.stringify(this.currDrugData));
      
      this.userForm.controls.drug_name.setValue(  this.currDrugData['drug_name']);
   //   this.userForm.controls.index.setValue(  this.currDrugData['idx']);      
      this.userForm.controls.ingredient.setValue(  this.currDrugData['ingredient']);
      this.userForm.controls.form.setValue(  this.currDrugData['form']);
      this.userForm.controls.dosage.setValue(  this.currDrugData['dosage']);

     // this.userForm.controls.using_time.setValue(  this.currDrugData['using_time']);
     // this.userForm.controls.duration.setValue(  this.currDrugData['duration']);      
      this.userForm.controls.remark.setValue(  this.currDrugData['remark']);
    //  this.userForm.controls.created_date.setValue(  this.currDrugData['created_date']);
      this.updateUsingTime(this.currDrugData);
       
    });   
  }

  getAllCatList() {
    this.drug_list = [];

    var filterType = '0';  

    this.dbConnect
      .getAllCatList(myGlobal.CAT_TYPE_DRUG)
      .subscribe({next:(data: []) => {
        this.allDrugCat = data;  
      
        this.catData = this.allDrugCat.find(e => e.category_id == this.category_id );
      
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});
  }

  invalidDrugName() { return false};
  invalidIngredient() { return false};
  invalidDrugForm() { return false};
  invalidDosage() { return false};
  invalidUsingTime() { return false};
  invalidDuration() { return false};
  invalidRemark() { return false};
  invalidIndex() { return false};
  invalidCreated_date() { return false};

  

  onGoBack()
  {  
    this._location.back();
  }

  // Update form from data come from backend
  updateUsingTime(data: any) {
    
    let using_time = JSON.parse(data["using_time"]);
    if (using_time.length!=1) {
      return;
    }
    this.userForm.controls.t4drugWhen.setValue(using_time[0]["when"]+"");
    this.t4drugCtl.setValue(using_time[0]["time"]);
  }

  // Build using time from data come from dialog
  buildUsingTime() { 
    let when = this.userForm.controls.t4drugWhen.value;
    let ret={"time":this.t4drugCtl.value,
            "when":when};
    return JSON.stringify([ret]);
  }

  onSubmit() {

   // alert(this.buildUsingTime());
    
    var body =  {
      idx: 0 ,//this.userForm.controls.index.value,
      drug_name: this.userForm.controls.drug_name.value,            
      ingredient: this.userForm.controls.ingredient.value,    
      form: this.userForm.controls.form.value,    
      dosage: this.userForm.controls.dosage.value,    
      using_time: this.buildUsingTime() , //this.userForm.controls.using_time.value,    
      duration: this.userForm.controls.duration.value,
      remark: this.userForm.controls.remark.value,
      category_id: this.category_id,  
    };

    if (this.createNew) { 
      this.dbConnect.addDrug(
      body
      ).subscribe({next:(result) => {
                  
          alert('Thêm thành công thuốc ' + this.userForm.controls.drug_name.value );

          if (!this.callFromDrugMan) {
            this._location.back();
          }
          this.dbConnect.sysLog('DrugDetailComponent','Submit','Add Drug Data= ' +  this.userForm.controls.drug_name.value);
      
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }});
      this.dbConnect.sysLog('DrugDetailComponent','submit','Add Drug= ' +  this.userForm.controls.drug_name.value);
    } else {
      this.dbConnect.updateDrug(
        this.currDrugId,
        body
        ).subscribe({next:(result) => {
                    
            alert('Cập nhật thành công thuốc ' + this.userForm.controls.drug_name.value );
            if (!this.callFromDrugMan) {
              this._location.back();
            }

            this.dbConnect.sysLog('DrugDetailComponent','Submit','Update Drug Data= ' +  this.userForm.controls.drug_name.value);
        
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }});
        this.dbConnect.sysLog('DrugDetailComponent','submit','Update Drug= ' +  this.userForm.controls.drug_name.value);
    }

  }
}



