import { Component, OnInit,ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import { Observable, of } from 'rxjs';
import { DbconnectService } from 'src/app/dbconnect.service';
import {FilterQuestionElement} from '../../models/question';

import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

import {MatSort} from '@angular/material/sort';
import {animate, state, style, transition, trigger} from '@angular/animations';
//import {checkTimeOut} from '../utils';

import { MsgDialogComponent } from 'src/app/utils/msg-dialog/msg-dialog.component';

//import { jsonpFactory } from '@angular/http/src/http_module';

@Component({
  selector: 'app-filter-question-man',
  templateUrl: './filter-question-man.component.html',
  styleUrls: ['./filter-question-man.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class FilterQuestionManComponent implements OnInit {

  quest_list:any = [];
  loadData  :any;
  dataSource = new MatTableDataSource<FilterQuestionElement>(this.quest_list);

  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  constructor(
    private dbConnect : DbconnectService,
    private route: ActivatedRoute,
    private _location: Location,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getFilterQuestList();
  }


  getFilterQuestList() {
    this.quest_list = [];

    var filterType = '0';

    this.dbConnect.getFilterQuestionList(filterType).subscribe({next:(data: []) => {

      // if (!checkTimeOut(data,this.router)) {
      //     return;
      // }
      //console.log('DAT:' + JSON.stringify(data));
      //this.lastaccess = data;

      console.debug("Data ", JSON.stringify(data));
      var idx:any = 0;

      this.loadData = data;

      data.forEach(e => {        

        idx ++;
        var obj:any= new FilterQuestionElement();

        obj = e;
       // obj['idx'] = idx;
        obj['pos'] = 0; // Xac dinh vi tri de an nut up/down
        obj['ids'] = [];

        // if (!obj.parentId || (Object.keys(obj.parentId).length === 0)) {
        //   obj.ids.sort((a: { idx: number; },b: { index: number; }) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));

        //   if (obj.ids && obj.ids.length>1) {
        //     obj.ids[0]['pos'] = 1;
        //     obj.ids[obj.ids.length-1]['pos'] = 2;
        //   }
        if (obj.parent_id=="0") {
          console.debug('parent:' + JSON.stringify(obj));
          this.quest_list.push(obj);
        }
        
      });

      // Xu ly cau hoi con
      data.forEach(e => {  
        
        var obj:any= new FilterQuestionElement();
        obj = e;
        if (obj.parent_id!="0") {   
          console.debug('child:' + JSON.stringify(obj));       
          let parent = this.quest_list.find((e:any) => e.quest_id == obj.parent_id );
          if (parent) {
            parent['ids'].push(obj);
          }
        }
      });

      this.quest_list.forEach((e:any) => {
        if (e.ids.length > 0) {
          e.ids.sort((a: { idx: number; },b: { idx: number; }) => (a.idx > b.idx) ? 1 : ((b.idx > a.idx) ? -1 : 0));

          if (e.ids && e.ids.length>1) {
            e.ids[0]['pos'] = 1;
            e.ids[e.ids.length-1]['pos'] = 2;
          }
        }
      });

      this.quest_list.sort((a: { idx: number; },b: { idx: number; }) => (a.idx > b.idx) ? 1 : ((b.idx > a.idx) ? -1 : 0))

      if (this.quest_list && this.quest_list.length>1)  {
        this.quest_list[0]['pos'] = 1;
        this.quest_list[this.quest_list.length-1]['pos'] = 2;
      }

      this.dataSource.data = this.quest_list ;
      this.dataSource.sort = this.sort;

    }, error:(err: string) => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});
  }

  onSubQuestEdit(quest:any, parent:any) {
    if (parent==null) {
      this.editQuest(quest.quest_id, 0,0);  // Edit Question
    } else {
      this.editQuest(quest.quest_id, parent.quest_id,0); // Edit SubQuestion
    }
  }



  onSubQuestDelete(quest:any,subquest:any) {
    const dialogRef = this.dialog.open(MsgDialogComponent, {
      width: '350px',
      data:"Có chắc chắn muốn xoá câu hỏi này không ?"
    });
    dialogRef.afterClosed().subscribe({next:(result) => {
      if(result) {

        var idx =  quest.ids.indexOf(subquest);
        if (idx > -1 ) {
          quest.ids.splice(idx,1);

          this.dbConnect.deleteFilterQuestion(subquest.quest_id)
            .subscribe({next:(data:any) => {
               alert("Xoá thành công!");

               this.dbConnect.sysLog('FilterQuestionManComponent','Submit','Delete Question= ' +  subquest.quest_id);

            }, error:(err: string) => {
              console.error('Observer got an error: ' + err);
              alert('Lỗi kết nối mạng! ');
            }});
        }

        //alert('Xoa thanh cong');
      }
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});
  }

  onSubQuestUp(quest:any,subquest:any) {
    var idx =  quest.ids.indexOf(subquest);
    if ( idx ==0 ) return;

    var tmp = quest.ids[idx - 1];
    var pos = tmp['pos'];
    quest.ids[idx - 1] = subquest;
    quest.ids[idx] = tmp;
    tmp['pos'] = subquest['pos'];
    subquest['pos'] = pos;

    this.reOrderQuests( quest.ids);
  }


  onSubQuestDown(quest:any,subquest:any) {
    var idx =  quest.ids.indexOf(subquest);
    if ( idx >= quest.ids.length -1 ) return;

    var tmp =  quest.ids[idx + 1];
    var pos = tmp['pos'];
    quest.ids[idx + 1] = subquest;
    quest.ids[idx] = tmp;

    tmp['pos'] = subquest['pos'];
    subquest['pos'] = pos;

    this.reOrderQuests( quest.ids);
  }

  onSubQuestAdd(quest:any,subquest:any) {

    if (subquest) {
      this.editQuest(0, quest.quest_id,subquest.idx); // Edit SubQuestion
    } else {
      this.editQuest(0, quest.quest_id,0);
    }

  }

  onRemoveSession(quest:any) {
    const dialogRef = this.dialog.open(MsgDialogComponent, {
      width: '350px',
      data: "Có chắc chắn muốn xóa các câu hỏi này không ?"
    });
    dialogRef.afterClosed().subscribe({next:(result) => {
      if(result) {
        var idx =  this.quest_list.indexOf(quest);
        if (idx > -1 ) {
          this.quest_list.splice(idx,1);

          this.dbConnect.deleteFilterQuestion(quest.quest_id)
            .subscribe({next:(data: any) => {
               alert("Xoá thành công!");
               this.dbConnect.sysLog('FilterQuestionManComponent','Submit','Delete Session= ' +  quest.quest_id);
            }, error:(err: string) => {
              console.error('Observer got an error: ' + err);
              alert('Lỗi kết nối mạng! ');
            }});

        }


      }
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});

  }

  onInsertSession(quest:any) {
    alert("onInsertSession " +  JSON.stringify(quest));
  }

  onMoveUpSession(quest:any) {
    var idx =  this.quest_list.indexOf(quest);
    if ( idx ==0 ) return;
    var tmp = this.quest_list[idx - 1];
    var  pos = tmp['pos'];
    this.quest_list[idx - 1] = quest;
    this.quest_list[idx] = tmp;

    tmp['pos'] = quest['pos'];
    quest['pos'] = pos;

    this.reOrderQuests(this.quest_list);
    //alert("onMoveUpSession " +  idx);
  }

  onMoveDownSession(quest:any) {
    var idx =  this.quest_list.indexOf(quest);
    if ( idx >= this.quest_list.length -1 ) return;

    var tmp = this.quest_list[idx + 1];
    var  pos = tmp['pos'];
    this.quest_list[idx + 1] = quest;
    this.quest_list[idx] = tmp;

    tmp['pos'] = quest['pos'];
    quest['pos'] = pos;

    this.reOrderQuests(this.quest_list);
    //alert("onMoveDownSession " +  idx);

  }

  onAddNewSession() {
    this.editQuest(0, 0,0); // Add new Session
  }

  reOrderQuests(list:any) {
    let idx = 1;
    list.forEach((oriElement: any) => {
      oriElement.idx = idx;
      //clone element
      let element:any = Object.assign({}, oriElement);
      //remove attribute not needed
     // delete element.idx;
      delete element.pos;
      //end
     // console.log("DEBUG: " + JSON.stringify(element));
      this.dbConnect.updateFilterQuestion(element.quest_id, element)
        .subscribe({next:(data: any) => {
          //console.log("DEBUG: Update OK " + element.id);
        }, error:(err: string) => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }});

      idx = idx + 1;
    });
  }

  editQuest(id:any,parent_id:any,idsIndex:any) {
    this.router.navigateByUrl('/filterquest/' + id + '/' + parent_id + '/' + idsIndex);
  }
}

