<div class="container-lg">
    <div class="row">
        <h2 class="text-primary">Theo dõi tuân thủ uống thuốc</h2>
    </div> 
    <div class="row">
        <div class="col-6">
            <ngx-charts-bar-vertical
            [view]="[700, 400]"
            [scheme]="colorScheme2"
            [results]="drug_time"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            legendTitle='Chú thích'
           >
            </ngx-charts-bar-vertical>
        </div>
        <div class="col-6">
            
        </div>
    </div>
    <hr>
    <div class="row">
        <h2 class="text-primary">Theo dõi tuân thủ thuốc</h2>
    </div> 
    <div class="row">
        <div class="col-6">
            <ngx-charts-bar-vertical-normalized
                [view]="[700, 400]"
                [scheme]="colorScheme1"
                [results]="drug_multi"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                legendTitle='Chú thích'
                >
            </ngx-charts-bar-vertical-normalized>
        </div>
        <div class="col-6">
            
        </div>
    </div>

    
</div>




