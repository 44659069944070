import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-man',
  templateUrl: './patient-man.component.html',
  styleUrls: ['./patient-man.component.scss']
})
export class PatientManComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}


