<div class="container-lg">
    <div class="row">
        <h2 class="text-primary">Báo cáo GERDQ</h2>
    </div>

    <div class="row">
        <div class="col-6">
            <ngx-charts-pie-chart
                [view]="[700, 400]"
                [scheme]="colorScheme1"
                [results]="gerdq_start"
                [gradient]="gradient"
                [legend]="showLegend"
                [labels]="showLabels"
                [doughnut]="isDoughnut"  
                legendTitle='Lần đầu tiên'
                [legendPosition]="legendPosition"
                >
                </ngx-charts-pie-chart>
        </div>
        <div class="col-6">
            <ngx-charts-pie-chart
                [view]="[700, 400]"
                [scheme]="colorScheme2"
                [results]="gerdq_lasttime"
                [gradient]="gradient"
                [legend]="showLegend"
                [labels]="showLabels"
                [doughnut]="isDoughnut"  
                legendTitle='Lần cuối'
                [legendPosition]="legendPosition"
                >
                </ngx-charts-pie-chart>
        </div>
    </div>
</div>

