<jqxSplitter #splitter
             [width]="getWidth()" [height]="getHeight()"
             [panels]="[{ size: 250 }]">
  <div>
    <jqxTree #jqxTree  [source]="treerecords" height="100%" (onSelect)="treeOnSelect($event)" (contextmenu)="onTreeRightClick($event)" >

    </jqxTree>
      <jqxMenu #treeMenu (onItemclick)="treeMenuOnItemClick($event)"
        [width]="160" [height]="220" [autoOpenPopup]="false" [mode]="'popup'" class="shadow p-2 mb-3 bg-white rounded">
          <ul>
              <li><i class="fa fa-plus text-primary"></i> Add SubItem</li>
              <li type="separator"></li>
              <li><i class="fa fa-folder text-primary"></i> New Category</li>      
              <li><i class="fa fa-cog text-primary"></i> Edit Category</li>   
              <li type="separator"></li>                   
              <li><i class="fa fa-arrow-up text-success" ></i > Move Up</li>
              <li><i class="fa fa-arrow-down text-success" ></i> Move Down</li>
              <li><i class="fa fa-arrow-right text-success"></i> Move In</li>
              <li><i class="fa fa-arrow-left text-success" ></i> Move Out</li>
              <li type="separator"></li>
              <li><i class="fa fa-eraser text-danger"></i> Delete Category</li>
          </ul>
      </jqxMenu>
      <jqxWindow #findWindow [width]="210" [height]="180" [autoOpen]="false" [resizable]="false">
        <div>Find Record</div>
        <div style="overflow: hidden">
            <div>Find what:</div>
            <div style="margin-top: 5px">
                <jqxInput #myInput [width]="194" [height]="23"></jqxInput>
            </div>
            <div style="margin-top: 7px; clear: both">Look in:</div>
            <div style="margin-top: 5px">
                <jqxDropDownList #myDropDownList
                                 [width]="200" [height]="23" [selectedIndex]="0"
                                 [source]="dropDownSource" [autoDropDownHeight]="true">
                </jqxDropDownList>
            </div>
            <div>
                <jqxButton style="margin-top: 15px; margin-left: 50px; float: left"
                           (onClick)="findBtnOnClick()" [width]="70">
                    Find
                </jqxButton>
                <jqxButton style="margin-left: 5px; margin-top: 15px; float: left"
                           (onClick)="clearBtnOnClick()" [width]="70">
                    Clear
                </jqxButton>
            </div>
        </div>
    </jqxWindow>
     </div>
  <div>
    <jqxSplitter #secondNested class="jqx-splitter-nested"
    [width]="'100%'" [height]="'100%'" [orientation]="'horizontal'"
    [panels]="[{ size: '50%' }, { size: '50%' }]">
        <div  #GridPanel>
            <jqxGrid #jqxgrid
            (onRowselect)="rowselect($event)"
            [height]="'100%'" [width]="'100%'"
            [source]="gridsource"
            [columnsresize]="true"
            [showtoolbar]="true"
            [rendertoolbar]="createButtonsContainers"
            [pageable]="true" 
            [autoheight]="false" 
            [sortable]="true" 
            [columns]="gridcolumns">
            </jqxGrid>
            
        </div>
        <div  #ContentPanel>
            <form #Form>
              <div class="container">
                <div class="row">
                  <div class="col-sm-4 ">
                    <button *ngIf="imgUrl" mat-raised-button class="btn btn btn-info" (click)="changeImageClick()"><i class="fa fa-check"></i> Chọn ảnh</button>
                    <img class="foodImg" src="{{imgUrl}}" alt="Picture Preview" title="{{imgUrl}}">      
                    {{imgUrl}} 
                  
                  </div>
                  <div class="col-sm-8">
                <table style="margin-top: 20px; width: 100%;">
                  <tr>
                    <td style="text-align: right;">Tên đồ ăn:</td>
                    <td style="text-align: left;">
                      <jqxInput #FoodName [height]="23"></jqxInput>            
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: right;">Mô tả:</td>
                    <td style="text-align: left;"><jqxInput #FoodDesc [height]="23"></jqxInput> </td>
                  </tr>
                  <!-- <tr>
                    <td style="text-align: right;">Phân loại:</td>
                    <td style="text-align: left;">
                      <jqxInput #FoodType [height]="23"></jqxInput>
                    </td>
                  </tr> -->

                  <tr>
                    <td style="text-align: right;">Phân loại:</td>
                    <td style="text-align: left;">      
                      <jqxDropDownList  #foodTypeComboBox [width]='250' [height]='30' [selectedIndex]='0' [source]="comboBoxFoodTypeSource" [autoDropDownHeight]="true">
                      </jqxDropDownList >
                    </td>
                  </tr>
                  

                  <tr>
                    <td style="text-align: right;">Nhóm:</td>
                    <td style="text-align: left;"><jqxInput #FoodCat [height]="23" [disabled]="true"></jqxInput></td>
                  </tr>
                  <tr>
                    <td style="text-align: right;">Người tạo:</td>
                    <td style="text-align: left;"><jqxInput #CreatedBy [height]="23"></jqxInput></td>
                  </tr>
                  <tr>
                    <td style="text-align: right;">Ngày tạo:</td>
                    <td style="text-align: left;"><jqxInput #CreatedDate [height]="23"></jqxInput></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style="padding-left: 35px; text-align: left;">
                      
                      <jqxButton *ngIf="!isUserFood" #Save
                                 (onClick)="saveClick()"
                                 class="bg-success text-white"
                                 >Cập nhật</jqxButton>

                      <jqxButton *ngIf="isUserFood" #Save
                                 (onClick)="onFoodApprove()"
                                 class="bg-success text-white"
                                 >Phê duyệt</jqxButton>
                      <jqxButton #ChangeCat
                                 (onClick)="changeCatClick()" 
                                 class="bg-primary text-white"
                                >
                                 Đổi nhóm</jqxButton>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
              </div>
              </form>
        </div>
        </jqxSplitter>
  </div>
</jqxSplitter>


