

<head>
    <meta http-equiv=Content-Type content="text/html; charset=utf-8">
    <meta name=ProgId content=Excel.Sheet>
    <meta name=Generator content="Microsoft Excel 15">
    <link rel=File-List href="exp_drug.fld/filelist.xml">
    <style id="exp_drug_20855_Styles">
    <!--table
        {mso-displayed-decimal-separator:"\,";
        mso-displayed-thousand-separator:"\.";}
    @page
        {margin:.75in .7in .75in .7in;
        mso-header-margin:0in;
        mso-footer-margin:0in;}
    tr
        {mso-height-source:auto;}
    col
        {mso-width-source:auto;}
    br
        {mso-data-placement:same-cell;}
    .style0
        {mso-number-format:General;
        text-align:general;
        vertical-align:bottom;
        white-space:nowrap;
        mso-rotate:0;
        mso-background-source:auto;
        mso-pattern:auto;
        color:black;
        font-size:11.0pt;
        font-weight:400;
        font-style:normal;
        text-decoration:none;
        font-family:Arial;
        mso-generic-font-family:auto;
        mso-font-charset:0;
        border:none;
        mso-protection:locked visible;
        mso-style-name:Normal;
        mso-style-id:0;}
    td
        {mso-style-parent:style0;
        padding-top:1px;
        padding-right:1px;
        padding-left:1px;
        mso-ignore:padding;
        color:black;
        font-size:11.0pt;
        font-weight:400;
        font-style:normal;
        text-decoration:none;
        font-family:Arial;
        mso-generic-font-family:auto;
        mso-font-charset:0;
        mso-number-format:General;
        text-align:general;
        vertical-align:bottom;
        border:none;
        mso-background-source:auto;
        mso-pattern:auto;
        mso-protection:locked visible;
        white-space:nowrap;
        mso-rotate:0;}
    .xl65
        {mso-style-parent:style0;
        font-weight:700;
        font-family:Arial, sans-serif;
        mso-font-charset:0;}
    .xl66
        {mso-style-parent:style0;
        font-weight:700;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        text-align:center;
        border:.5pt solid windowtext;
        background:yellow;
        mso-pattern:yellow none;}
    .xl67
        {mso-style-parent:style0;
        color:windowtext;
        font-family:Arial, sans-serif;
        mso-font-charset:0;
        border:.5pt solid windowtext;}
    .xl68
        {mso-style-parent:style0;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        border:.5pt solid windowtext;}
    .xl69
        {mso-style-parent:style0;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        vertical-align:middle;
        border:.5pt solid windowtext;
        white-space:normal;}
    .xl70
        {mso-style-parent:style0;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        mso-number-format:"\[ENG\]\[$-409\]d\\-mmm\\-yy";
        vertical-align:middle;
        border:.5pt solid windowtext;}
    .xl71
        {mso-style-parent:style0;
        color:blue;
        text-decoration:underline;
        text-underline-style:single;
        font-family:Arial, sans-serif;
        mso-font-charset:0;
        vertical-align:middle;
        border:.5pt solid windowtext;}
    .xl72
        {mso-style-parent:style0;
        font-weight:700;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        text-align:center;
        border-top:.5pt solid windowtext;
        border-right:none;
        border-bottom:.5pt solid windowtext;
        border-left:.5pt solid windowtext;
        background:#FABF8F;
        mso-pattern:#FABF8F none;}
    .xl73
        {mso-style-parent:style0;
        font-weight:700;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        text-align:center;
        border-top:.5pt solid windowtext;
        border-right:none;
        border-bottom:.5pt solid windowtext;
        border-left:none;
        background:#FABF8F;
        mso-pattern:#FABF8F none;}
    .xl74
        {mso-style-parent:style0;
        font-weight:700;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        text-align:center;
        border-top:.5pt solid windowtext;
        border-right:.5pt solid windowtext;
        border-bottom:.5pt solid windowtext;
        border-left:none;
        background:#FABF8F;
        mso-pattern:#FABF8F none;}
    .xl75
        {mso-style-parent:style0;
        font-weight:700;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        text-align:center;
        border-top:.5pt solid windowtext;
        border-right:none;
        border-bottom:.5pt solid windowtext;
        border-left:.5pt solid windowtext;
        background:#B6DDE8;
        mso-pattern:#B6DDE8 none;}
    .xl76
        {mso-style-parent:style0;
        font-weight:700;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        text-align:center;
        border-top:.5pt solid windowtext;
        border-right:none;
        border-bottom:.5pt solid windowtext;
        border-left:none;
        background:#B6DDE8;
        mso-pattern:#B6DDE8 none;}
    .xl77
        {mso-style-parent:style0;
        font-weight:700;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        text-align:center;
        border-top:.5pt solid windowtext;
        border-right:.5pt solid windowtext;
        border-bottom:.5pt solid windowtext;
        border-left:none;
        background:#B6DDE8;
        mso-pattern:#B6DDE8 none;}
    -->
    </style>
    </head>
    
    <body link=blue vlink=blue>

    <button type="button" (click)="export()" class="btn btn-success"> <i class="far fa-file-excel"  ></i> Export</button>

    <!--[if !excel]>&nbsp;&nbsp;<![endif]-->
    <!--The following information was generated by Microsoft Excel's Publish as Web
    Page wizard.-->
    <!--If the same item is republished from Excel, all information between the DIV
    tags will be replaced.-->
    <!----------------------------->
    <!--START OF OUTPUT FROM EXCEL PUBLISH AS WEB PAGE WIZARD -->
    <!----------------------------->
    
    <div id="exp_drug_20855" align=center x:publishsource="Excel">
    
    <table border=0 cellpadding=0 cellspacing=0 width=5151 style='border-collapse:
     collapse;table-layout:fixed;width:3874pt' id="ExportTable">
     <col width=104 style='mso-width-source:userset;mso-width-alt:3328;width:78pt'>
     <col width=125 style='mso-width-source:userset;mso-width-alt:4010;width:94pt'>
     <col width=109 style='mso-width-source:userset;mso-width-alt:3498;width:82pt'>
     <col width=120 style='mso-width-source:userset;mso-width-alt:3840;width:90pt'>
     <col width=121 style='mso-width-source:userset;mso-width-alt:3882;width:91pt'>
     <col width=136 style='mso-width-source:userset;mso-width-alt:4352;width:102pt'>
     <col width=131 style='mso-width-source:userset;mso-width-alt:4181;width:98pt'>
     <col width=105 span=41 style='mso-width-source:userset;mso-width-alt:3370;
     width:79pt'>
     <tr height=20 style='mso-height-source:userset;height:15.0pt'>
      <td height=20 width=104 style='height:15.0pt;width:78pt'></td>
      <td width=125 style='width:94pt'></td>
      <td width=109 style='width:82pt'></td>
      <td width=120 style='width:90pt'></td>
      <td width=121 style='width:91pt'></td>
      <td class=xl65 width=136 style='width:102pt'>DRUG REPORT</td>
      <td width=131 style='width:98pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
     </tr>
     <tr height=20 style='height:15.0pt'>
      <td height=20 colspan=48 style='height:15.0pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td colspan=6 height=19 class=xl66 style='height:14.25pt'>Thông tin cá nhân</td>
      <td colspan=35 class=xl75 style='border-right:.5pt solid black;border-left:
      none'>Đơn thuốc</td>
      <td colspan=7 class=xl72 style='border-right:.5pt solid black;border-left:
      none'>Tuân thủ uống thuốc</td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 class=xl68 style='height:14.25pt;border-top:none'>ID</td>
      <td class=xl68 style='border-top:none;border-left:none'>Họ và tên</td>
      <td class=xl68 style='border-top:none;border-left:none'>Email</td>
      <td class=xl68 style='border-top:none;border-left:none'>Năm sinh</td>
      <td class=xl68 style='border-top:none;border-left:none'>Giới tính</td>
      <td class=xl68 style='border-top:none;border-left:none'>Ngày khảo sát</td>
      <td class=xl68 style='border-top:none;border-left:none'>Time_điều trị (ngày)</td>
      <td class=xl68 style='border-top:none;border-left:none'>drug1-Name</td>
      <td class=xl68 style='border-top:none;border-left:none'>drug1-Thời điểm <span
      style='display:none'>UT</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug1-Time_UT/<span
      style='display:none'>Bữa sáng</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug1-Time_UT/<span
      style='display:none'>Bữa tối</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug1-Time_UT/<span
      style='display:none'>Bữa trưa</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug1-Time_UT/T<span
      style='display:none'>rước khi đi ngủ</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug2-Name</td>
      <td class=xl68 style='border-top:none;border-left:none'>drug2-Thời điểm <span
      style='display:none'>UT</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug2-Time_UT/<span
      style='display:none'>Bữa sáng</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug2-Time_UT/<span
      style='display:none'>Bữa tối</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug2-Time_UT/<span
      style='display:none'>Bữa trưa</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug2-Time_UT/T<span
      style='display:none'>rước khi đi ngủ</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug3-Name</td>
      <td class=xl68 style='border-top:none;border-left:none'>drug3-Thời điểm <span
      style='display:none'>UT</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug3-Time_UT/<span
      style='display:none'>Bữa sáng</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug3-Time_UT/<span
      style='display:none'>Bữa tối</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug3-Time_UT/<span
      style='display:none'>Bữa trưa</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug3-Time_UT/T<span
      style='display:none'>rước khi đi ngủ</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug4-Name</td>
      <td class=xl68 style='border-top:none;border-left:none'>drug4-Thời điểm <span
      style='display:none'>UT</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug4-Time_UT/<span
      style='display:none'>Bữa sáng</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug4-Time_UT/<span
      style='display:none'>Bữa tối</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug4-Time_UT/<span
      style='display:none'>Bữa trưa</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug4-Time_UT/T<span
      style='display:none'>rước khi đi ngủ</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug5-Name</td>
      <td class=xl68 style='border-top:none;border-left:none'>drug5-Thời điểm <span
      style='display:none'>UT</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug5-Time_UT/<span
      style='display:none'>Bữa tối</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug5-Time_UT/<span
      style='display:none'>Bữa trưa</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug5-Time_UT/T<span
      style='display:none'>rước khi đi ngủ</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug6-Name</td>
      <td class=xl68 style='border-top:none;border-left:none'>drug6-Thời điểm <span
      style='display:none'>UT</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug6-Time_UT/<span
      style='display:none'>Bữa tối</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug6-Time_UT/<span
      style='display:none'>Bữa trưa</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>drug6-Time_UT/T<span
      style='display:none'>rước khi đi ngủ</span></td>
      <td class=xl68 style='border-top:none;border-left:none'>Trước ăn sáng</td>
      <td class=xl68 style='border-top:none;border-left:none'>Sau ăn sáng</td>
      <td class=xl68 style='border-top:none;border-left:none'>Trước ăn trưa</td>
      <td class=xl68 style='border-top:none;border-left:none'>Sau ăn trưa</td>
      <td class=xl68 style='border-top:none;border-left:none'>Trước ăn tối</td>
      <td class=xl68 style='border-top:none;border-left:none'>Sau ăn tối</td>
      <td class=xl68 style='border-top:none;border-left:none'>Trước khi đi ngủ</td>
     </tr>
     <tr height=20 style='height:15.0pt'>
      <td height=20 class=xl69 width=104 style='height:15.0pt;border-top:none;
      width:78pt'>&nbsp;</td>
      <td class=xl69 width=125 style='border-top:none;border-left:none;width:94pt'>&nbsp;</td>
      <td class=xl69 width=109 style='border-top:none;border-left:none;width:82pt'>&nbsp;</td>
      <td class=xl69 width=120 style='border-top:none;border-left:none;width:90pt'>&nbsp;</td>
      <td class=xl69 width=121 style='border-top:none;border-left:none;width:91pt'>&nbsp;</td>
      <td class=xl70 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl71 style='border-top:none;border-left:none'><u style='visibility:
      hidden;mso-ignore:visibility'>&nbsp;</u></td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
      <td class=xl67 style='border-top:none;border-left:none'>&nbsp;</td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=48 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=48 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=48 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=48 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=48 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=48 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=48 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=48 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=48 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=48 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=48 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=48 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=266 style='mso-height-source:userset;height:199.5pt;mso-xlrowspan:
      14'>
      <td height=266 colspan=48 style='height:199.5pt;mso-ignore:colspan'></td>
     </tr>
    
     <tr height=0 style='display:none'>
      <td width=104 style='width:78pt'></td>
      <td width=125 style='width:94pt'></td>
      <td width=109 style='width:82pt'></td>
      <td width=120 style='width:90pt'></td>
      <td width=121 style='width:91pt'></td>
      <td width=136 style='width:102pt'></td>
      <td width=131 style='width:98pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
      <td width=105 style='width:79pt'></td>
     </tr>
    
    </table>
    
    </div>
    
    
    <!----------------------------->
    <!--END OF OUTPUT FROM EXCEL PUBLISH AS WEB PAGE WIZARD-->
    <!----------------------------->
    </body>
    
    
    