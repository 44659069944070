

<head>
    <meta http-equiv=Content-Type content="text/html; charset=utf-8">
    <meta name=ProgId content=Excel.Sheet>
    <meta name=Generator content="Microsoft Excel 15">
    <link rel=File-List href="exp_gerd.fld/filelist.xml">
    <style id="exp_gerd_19354_Styles">
    <!--table
        {mso-displayed-decimal-separator:"\,";
        mso-displayed-thousand-separator:"\.";}
    @page
        {margin:.75in .7in .75in .7in;
        mso-header-margin:0in;
        mso-footer-margin:0in;}
    tr
        {mso-height-source:auto;}
    col
        {mso-width-source:auto;}
    br
        {mso-data-placement:same-cell;}
    .style0
        {mso-number-format:General;
        text-align:general;
        vertical-align:bottom;
        white-space:nowrap;
        mso-rotate:0;
        mso-background-source:auto;
        mso-pattern:auto;
        color:black;
        font-size:11.0pt;
        font-weight:400;
        font-style:normal;
        text-decoration:none;
        font-family:Arial;
        mso-generic-font-family:auto;
        mso-font-charset:0;
        border:none;
        mso-protection:locked visible;
        mso-style-name:Normal;
        mso-style-id:0;}
    td
        {mso-style-parent:style0;
        padding-top:1px;
        padding-right:1px;
        padding-left:1px;
        mso-ignore:padding;
        color:black;
        font-size:11.0pt;
        font-weight:400;
        font-style:normal;
        text-decoration:none;
        font-family:Arial;
        mso-generic-font-family:auto;
        mso-font-charset:0;
        mso-number-format:General;
        text-align:general;
        vertical-align:bottom;
        border:none;
        mso-background-source:auto;
        mso-pattern:auto;
        mso-protection:locked visible;
        white-space:nowrap;
        mso-rotate:0;}
    .xl65
        {mso-style-parent:style0;
        font-weight:700;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;}
    .xl66
        {mso-style-parent:style0;
        font-weight:700;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        text-align:center;}
    .xl67
        {mso-style-parent:style0;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        vertical-align:middle;
        white-space:normal;}
    .xl68
        {mso-style-parent:style0;
        font-weight:700;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        vertical-align:middle;
        border:.5pt solid black;
        white-space:normal;}
    .xl69
        {mso-style-parent:style0;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        vertical-align:middle;
        border:.5pt solid black;
        white-space:normal;}
    .xl70
        {mso-style-parent:style0;
        font-weight:700;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        text-align:center;
        border-top:.5pt solid black;
        border-right:none;
        border-bottom:.5pt solid black;
        border-left:.5pt solid black;
        background:yellow;
        mso-pattern:yellow none;}
    .xl71
        {mso-style-parent:style0;
        color:windowtext;
        font-family:Arial, sans-serif;
        mso-font-charset:0;
        border-top:.5pt solid black;
        border-right:none;
        border-bottom:.5pt solid black;
        border-left:none;}
    .xl72
        {mso-style-parent:style0;
        color:windowtext;
        font-family:Arial, sans-serif;
        mso-font-charset:0;
        border-top:.5pt solid black;
        border-right:.5pt solid black;
        border-bottom:.5pt solid black;
        border-left:none;}
    .xl73
        {mso-style-parent:style0;
        font-weight:700;
        font-family:Calibri, sans-serif;
        mso-font-charset:0;
        text-align:center;
        border-top:.5pt solid black;
        border-right:none;
        border-bottom:.5pt solid black;
        border-left:.5pt solid black;
        background:#FBD4B4;
        mso-pattern:#FBD4B4 none;}
    .xl74
        {mso-style-parent:style0;
        font-weight:700;
        font-family:Arial, sans-serif;
        mso-font-charset:0;}
    -->
    </style>
    </head>
    
    <body link=blue vlink=blue>

   <button type="button" (click)="export()" class="btn btn-success"><i class="far fa-file-excel"  ></i> Export</button>
  
    <!--[if !excel]>&nbsp;&nbsp;<![endif]-->
    <!--The following information was generated by Microsoft Excel's Publish as Web
    Page wizard.-->
    <!--If the same item is republished from Excel, all information between the DIV
    tags will be replaced.-->
    <!----------------------------->
    <!--START OF OUTPUT FROM EXCEL PUBLISH AS WEB PAGE WIZARD -->
    <!----------------------------->
    
    <div id="exp_gerd_19354" align=center x:publishsource="Excel">
    
    <table border=0 cellpadding=0 cellspacing=0 width=2366 style='border-collapse:
     collapse;table-layout:fixed;width:1776pt' id="ExportTable">
     <col width=99 style='mso-width-source:userset;mso-width-alt:3157;width:74pt'>
     <col width=113 style='mso-width-source:userset;mso-width-alt:3626;width:85pt'>
     <col width=104 style='mso-width-source:userset;mso-width-alt:3328;width:78pt'>
     <col width=113 style='mso-width-source:userset;mso-width-alt:3626;width:85pt'>
     <col width=100 style='mso-width-source:userset;mso-width-alt:3200;width:75pt'>
     <col width=136 style='mso-width-source:userset;mso-width-alt:4352;width:102pt'>
     <col width=167 span=3 style='mso-width-source:userset;mso-width-alt:5333;
     width:125pt'>
     <col width=84 style='mso-width-source:userset;mso-width-alt:2688;width:63pt'>
     <col width=167 style='mso-width-source:userset;mso-width-alt:5333;width:125pt'>
     <col width=139 span=2 style='mso-width-source:userset;mso-width-alt:4437;
     width:104pt'>
     <col width=61 span=11 style='mso-width-source:userset;mso-width-alt:1962;
     width:46pt'>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 class=xl66 width=99 style='height:14.25pt;width:74pt'></td>
      <td class=xl66 width=113 style='width:85pt'></td>
      <td class=xl66 width=104 style='width:78pt'></td>
      <td class=xl66 width=113 style='width:85pt'>GERD QUESTION REPORT</td>
      <td class=xl66 width=100 style='width:75pt'></td>
      <td class=xl66 width=136 style='width:102pt'></td>
      <td class=xl66 width=167 style='width:125pt'></td>
      <td class=xl66 width=167 style='width:125pt'></td>
      <td class=xl66 width=167 style='width:125pt'></td>
      <td class=xl66 width=84 style='width:63pt'></td>
      <td class=xl66 width=167 style='width:125pt'></td>
      <td class=xl66 width=139 style='width:104pt'></td>
      <td class=xl66 width=139 style='width:104pt'></td>
      <td class=xl65 width=61 style='width:46pt'></td>
      <td class=xl65 width=61 style='width:46pt'></td>
      <td class=xl65 width=61 style='width:46pt'></td>
      <td class=xl65 width=61 style='width:46pt'></td>
      <td class=xl65 width=61 style='width:46pt'></td>
      <td class=xl65 width=61 style='width:46pt'></td>
      <td class=xl65 width=61 style='width:46pt'></td>
      <td class=xl65 width=61 style='width:46pt'></td>
      <td class=xl65 width=61 style='width:46pt'></td>
      <td class=xl65 width=61 style='width:46pt'></td>
      <td class=xl65 width=61 style='width:46pt'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 class=xl66 style='height:14.25pt'></td>
      <td class=xl66></td>
      <td class=xl66></td>
      <td class=xl66></td>
      <td class=xl66></td>
      <td class=xl66></td>
      <td class=xl66></td>
      <td class=xl66></td>
      <td class=xl66></td>
      <td class=xl66></td>
      <td class=xl66></td>
      <td class=xl66></td>
      <td class=xl66></td>
      <td class=xl65></td>
      <td class=xl65></td>
      <td class=xl65></td>
      <td class=xl65></td>
      <td class=xl65></td>
      <td class=xl65></td>
      <td class=xl65></td>
      <td class=xl65></td>
      <td class=xl65></td>
      <td class=xl65></td>
      <td class=xl65></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td colspan=6 height=19 class=xl70 style='border-right:.5pt solid black;
      height:14.25pt'>Thông tin cá nhân</td>
      <td colspan=7 class=xl73 style='border-right:.5pt solid black;border-left:
      none'>Câu hỏi GERDQ</td>
      <td class=xl65></td>
      <td class=xl65></td>
      <td class=xl65></td>
      <td class=xl65></td>
      <td class=xl65></td>
      <td class=xl65></td>
      <td class=xl65></td>
      <td class=xl65></td>
      <td class=xl65></td>
      <td class=xl65></td>
      <td class=xl65></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 class=xl68 width=99 style='height:14.25pt;border-top:none;
      width:74pt'>ID</td>
      <td class=xl68 width=113 style='border-top:none;border-left:none;width:85pt'>Họ
      và tên</td>
      <td class=xl68 width=104 style='border-top:none;border-left:none;width:78pt'>Email</td>
      <td class=xl68 width=113 style='border-top:none;border-left:none;width:85pt'>Năm
      sinh</td>
      <td class=xl68 width=100 style='border-top:none;border-left:none;width:75pt'>Giới
      tính</td>
      <td class=xl68 width=136 style='border-top:none;border-left:none;width:102pt'>Ngày
      khảo sát</td>
      <td class=xl68 width=167 style='border-top:none;border-left:none;width:125pt'>Cảm
      giác nóng rát sau xương ức (giữa ngực)</td>
      <td class=xl68 width=167 style='border-top:none;border-left:none;width:125pt'>Cảm
      thấy chất lỏng hoặc thức ăn trong dạ dày ợ lên đến họng hoặc miệng</td>
      <td class=xl68 width=167 style='border-top:none;border-left:none;width:125pt'>Đau
      ở vùng thượng vị (khu vực dạ dày)</td>
      <td class=xl68 width=84 style='border-top:none;border-left:none;width:63pt'>Buồn
      nôn</td>
      <td class=xl68 width=167 style='border-top:none;border-left:none;width:125pt'>Ngủ
      không ngon vì cảm giác nóng rát sau xương ức/ợ lên họng miệng</td>
      <td class=xl68 width=139 style='border-top:none;border-left:none;width:104pt'>Phải
      dùng thêm thuốc ngoài những thuốc bác sĩ kê? (Vd, Gaviscon)</td>
      <td class=xl68 width=139 style='border-top:none;border-left:none;width:104pt'>Tổng
      điểm GERDQ</td>
      <td class=xl67 width=61 style='width:46pt'></td>
      <td class=xl67 width=61 style='width:46pt'></td>
      <td class=xl67 width=61 style='width:46pt'></td>
      <td class=xl67 width=61 style='width:46pt'></td>
      <td class=xl67 width=61 style='width:46pt'></td>
      <td class=xl67 width=61 style='width:46pt'></td>
      <td class=xl67 width=61 style='width:46pt'></td>
      <td class=xl67 width=61 style='width:46pt'></td>
      <td class=xl67 width=61 style='width:46pt'></td>
      <td class=xl67 width=61 style='width:46pt'></td>
      <td class=xl67 width=61 style='width:46pt'></td>
     </tr>
     <tr height=43 style='mso-height-source:userset;height:32.25pt'>
      <td height=43 class=xl69 width=99 style='height:32.25pt;border-top:none;
      width:74pt'>&nbsp;</td>
      <td class=xl69 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
      <td class=xl69 width=104 style='border-top:none;border-left:none;width:78pt'>&nbsp;</td>
      <td class=xl68 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
      <td class=xl69 width=100 style='border-top:none;border-left:none;width:75pt'>&nbsp;</td>
      <td class=xl69 width=136 style='border-top:none;border-left:none;width:102pt'>&nbsp;</td>
      <td class=xl69 width=167 style='border-top:none;border-left:none;width:125pt'>&nbsp;</td>
      <td class=xl69 width=167 style='border-top:none;border-left:none;width:125pt'>&nbsp;</td>
      <td class=xl69 width=167 style='border-top:none;border-left:none;width:125pt'>&nbsp;</td>
      <td class=xl69 width=84 style='border-top:none;border-left:none;width:63pt'>&nbsp;</td>
      <td class=xl69 width=167 style='border-top:none;border-left:none;width:125pt'>&nbsp;</td>
      <td class=xl69 width=139 style='border-top:none;border-left:none;width:104pt'>&nbsp;</td>
      <td class=xl69 width=139 style='border-top:none;border-left:none;width:104pt'>&nbsp;</td>
      <td class=xl67 width=61 style='width:46pt'></td>
      <td class=xl67 width=61 style='width:46pt'></td>
      <td class=xl67 width=61 style='width:46pt'></td>
      <td class=xl67 width=61 style='width:46pt'></td>
      <td class=xl67 width=61 style='width:46pt'></td>
      <td class=xl67 width=61 style='width:46pt'></td>
      <td class=xl67 width=61 style='width:46pt'></td>
      <td class=xl67 width=61 style='width:46pt'></td>
      <td class=xl67 width=61 style='width:46pt'></td>
      <td class=xl67 width=61 style='width:46pt'></td>
      <td class=xl67 width=61 style='width:46pt'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=2 style='height:14.25pt;mso-ignore:colspan'></td>
      <td class=xl74></td>
      <td colspan=21 style='mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=19 style='mso-height-source:userset;height:14.25pt'>
      <td height=19 colspan=24 style='height:14.25pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=513 style='mso-height-source:userset;height:384.75pt;mso-xlrowspan:
      27'>
      <td height=513 colspan=24 style='height:384.75pt;mso-ignore:colspan'></td>
     </tr>
     <tr height=475 style='mso-height-source:userset;height:356.25pt;mso-xlrowspan:
      25'>
      <td height=475 colspan=24 style='height:356.25pt;mso-ignore:colspan'></td>
     </tr>
     
     <tr height=0 style='display:none'>
      <td width=99 style='width:74pt'></td>
      <td width=113 style='width:85pt'></td>
      <td width=104 style='width:78pt'></td>
      <td width=113 style='width:85pt'></td>
      <td width=100 style='width:75pt'></td>
      <td width=136 style='width:102pt'></td>
      <td width=167 style='width:125pt'></td>
      <td width=167 style='width:125pt'></td>
      <td width=167 style='width:125pt'></td>
      <td width=84 style='width:63pt'></td>
      <td width=167 style='width:125pt'></td>
      <td width=139 style='width:104pt'></td>
      <td width=139 style='width:104pt'></td>
      <td width=61 style='width:46pt'></td>
      <td width=61 style='width:46pt'></td>
      <td width=61 style='width:46pt'></td>
      <td width=61 style='width:46pt'></td>
      <td width=61 style='width:46pt'></td>
      <td width=61 style='width:46pt'></td>
      <td width=61 style='width:46pt'></td>
      <td width=61 style='width:46pt'></td>
      <td width=61 style='width:46pt'></td>
      <td width=61 style='width:46pt'></td>
      <td width=61 style='width:46pt'></td>
     </tr>

    </table>
    
    </div>
    
    
    <!----------------------------->
    <!--END OF OUTPUT FROM EXCEL PUBLISH AS WEB PAGE WIZARD-->
    <!----------------------------->
    </body>
    
    
    