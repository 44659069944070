import { Component,Inject, ViewChild, ElementRef, AfterViewInit,OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import { Observable, of } from 'rxjs';

import { jqxTreeComponent } from 'jqwidgets-ng/jqxtree';
import { DbconnectService } from 'src/app/dbconnect.service';

import * as myGlobal from '../../global';

import {FoodData, CatNode} from '../../models/food-category';
import { MsgDialogComponent } from 'src/app/utils/msg-dialog/msg-dialog.component';


export interface DialogData {
  title: string;
  msg: string;
  catType:  number;
}

@Component({
  selector: 'app-cat-selector',
  templateUrl: './cat-selector.component.html',
  styleUrls: ['./cat-selector.component.scss']
})
export class CatSelectorComponent implements OnInit, AfterViewInit {

  @ViewChild('jqxTree', { static: true }) jqxTree!: jqxTreeComponent;

  foodCatTree:any[] = [];  // Cay food category 
  allFoodCat : any[] = [];  // Chua tat ca cac category

  treesource:any = {
    datatype: 'json',
    datafields: [
        { name: 'id', type: 'string' },
        { name: 'category_name' , type: 'string' },
        { name: 'remark' , type: 'string' },
        { name: 'parentid' , type: 'string' },
        { name: 'image' , type: 'string' },
        { name: 'icon' , type: 'string' },
    ],
    id: 'id',
    localdata: this.foodCatTree
  };
  treedataAdapter :any;
  treerecords :any;  
  currCat : number = 0;
  catType! : number ;

  constructor(
    public dialogRef: MatDialogRef<DialogData>,
    private dbConnect : DbconnectService,
    private route: ActivatedRoute,
    private _location: Location,
    private router: Router,
   // public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dlgdata: DialogData
  ) { }

 
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.catType =  parseInt(this.route.snapshot.paramMap.get('catType')+"");
  }

  ngAfterViewInit(): void 
  {
     
    this.dbConnect
    .getAllCatList(this.dlgdata.catType)
    .subscribe({next:(data: []) => {
      this.allFoodCat = data;
      data.forEach((e) => {
         // if (e["parent_id"] == "-1") {
              let item: CatNode = {
                  category_name: e["category_name"] + (" (id:" + e["category_id"] + ")"),
                  id: e["category_id"],  
                  parentid: e["parent_id"],
                  remark: e["remark"],
                  image: e["image"],   
                  icon: e["image"],
                  locked: e["locked"] 
                } ;
       
              this.foodCatTree.push(item );
          //}
      });
      
        this.treesource.localdata = this.foodCatTree;
        this.treedataAdapter = new jqx.dataAdapter(this.treesource);
        this.treedataAdapter.dataBind();

        this.treerecords = this.treedataAdapter.getRecordsHierarchy('id', 'parentid', null, 
            [{ name: 'category_name', map: 'label' }]);
            this.jqxTree.expandAll();
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});
  }

  treeOnSelect(event: any): void {
    // this.ContentPanel.nativeElement.innerHTML = '<div style="margin: 10px;">' + event.args.element.id + '</div>';    
     console.log("Select: " +event.args.element.id );
 
     let selectedItem = this.jqxTree.getSelectedItem();
     if (selectedItem != null) {
         this.jqxTree.expandItem(selectedItem.element);
         this.currCat = event.args.element.id;
     }
  }
}
