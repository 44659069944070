import { Component, OnInit } from '@angular/core';
import { DbconnectService } from 'src/app/dbconnect.service';
import { XlsExportService } from 'src/app/utils/xls-export.service';

@Component({
  selector: 'app-exp-food',
  templateUrl: './exp-food.component.html',
  styleUrls: ['./exp-food.component.scss']
})
export class ExpFoodComponent implements OnInit {

  constructor(
    private dbConnect:DbconnectService,
    private exportService: XlsExportService) { }

  ngOnInit(): void {
  }

  export() {
    this.dbConnect.sysLog('ExpFoodComponent','Export','Template=' +  'FoodReport');
    this.exportService.exportToExcel("ExportTable", 'FoodReport');    
  }


}
