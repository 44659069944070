import { Component, OnInit,ViewChild } from '@angular/core';
import { DbconnectService } from 'src/app/dbconnect.service';
import { HttpClient } from '@angular/common/http';
import * as myGlobal from '../../global';
import { NewsData } from 'src/app/models/news';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ActivatedRoute, Router} from '@angular/router';
import { Observable } from 'rxjs';
import { MsgDialogComponent } from 'src/app/utils/msg-dialog/msg-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.scss']
})
export class VideoListComponent implements OnInit {

  constructor(
    private httpClient: HttpClient,
    private dbConnect : DbconnectService,
    private route: ActivatedRoute,
   // private _location: Location,
    private router: Router,
    public dialog: MatDialog,
    ) { }
    
  @ViewChild('jqxgrid', { static: false }) jqxgrid!: jqxGridComponent;
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  hasData : boolean = true;
  videoList : any[] = [];
  dataAdapter : any[] = [];
  allVideoCat : any[] = [];  // Chua tat ca cac category
  currVideoId : string = "0"; // gia tri id cua drug hien tai
  currVideoData : any;
  currCatId : number = -1;
  //new_list:any = [];
  loadData  :any;
  dataSource = new MatTableDataSource<any>(this.videoList);

  ngOnInit(): void {
  }

  treeOnSelect(category_id: any): void {   
    let currCat = this.allVideoCat.find(e => e.category_id == category_id );
    
    if (currCat) {   
      this.currCatId = category_id;
      this.reloadGridData(category_id,currCat.category_name);

    } else {
      this.currCatId = -1;
      this.videoList = [];
      this.hasData =false;
    }
  }; 

  getWidth() : any {
    // if (document.body.offsetWidth < 850) {
    // 	return '90%';
    // }		
    return window.innerWidth - 20;
  }
  
  getHeight() : any {
    // if (document.body.offsetWidth < 850) {
    // 	return '90%';
    // }		
    return window.innerHeight - 50;
  }

  addNewItemCatId(category_id: any): void {   
    this.router.navigateByUrl('/video-editor/' + myGlobal.CAT_TYPE_VIDEO + "/"+ category_id + '/-1');
    
  }

  ngAfterViewInit(): void 
  {
    this.dbConnect
    .getAllCatList(myGlobal.CAT_TYPE_VIDEO)
    .subscribe({next:(data: []) => {
      this.allVideoCat = data;      
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});
    this.createButtons();
    this.getVideoList();
  
  }

  getVideoList() {
    this.videoList = [];
    var filterType = '0';
    this.dbConnect.getDrugList(filterType).subscribe({next:(data: []) => {
      //console.debug("Data ", JSON.stringify(data));
      var idx:any = 0;

      this.loadData = data;

      data.forEach(e => {        

        idx ++;
        var obj:any= new NewsData();

        obj = e;
       // obj['idx'] = idx;
        obj['pos'] = 0; // Xac dinh vi tri de an nut up/down
        obj['ids'] = [];

 
        if (obj.parent_id=="0") {
          console.debug('parent:' + JSON.stringify(obj));
          this.videoList.push(obj);
        }
        
      });

      // Xu ly cau hoi con
      data.forEach(e => {  
        
        var obj:any= new NewsData();
        obj = e;
        if (obj.parent_id!="0") {   
        //  console.debug('child:' + JSON.stringify(obj));       
          let parent = this.videoList.find((e:any) => e.drug_id == obj.parent_id );
          if (parent) {
            parent['ids'].push(obj);
          }
        }
      });

      this.videoList.forEach((e:any) => {
        if (e.ids.length > 0) {
          e.ids.sort((a: { idx: number; },b: { idx: number; }) => (a.idx > b.idx) ? 1 : ((b.idx > a.idx) ? -1 : 0));

          if (e.ids && e.ids.length>1) {
            e.ids[0]['pos'] = 1;
            e.ids[e.ids.length-1]['pos'] = 2;
          }
        }
      });

      this.videoList.sort((a: { idx: number; },b: { idx: number; }) => (a.idx > b.idx) ? 1 : ((b.idx > a.idx) ? -1 : 0))

      if (this.videoList && this.videoList.length>1)  {
        this.videoList[0]['pos'] = 1;
        this.videoList[this.videoList.length-1]['pos'] = 2;
      }

      this.dataSource.data = this.videoList ;
      this.dataSource.sort = this.sort;

    }, error:(err: string) => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});
  }

  reloadGridData(catid: string,catName: string) {
    this.videoList = [];
    this.hasData =false;
 //   alert("Call:reloadGridData " + catid);
    this.dbConnect
        .getNewsListWithCat(catid)
        .subscribe({next:(data: []) => {
                this.videoList = [];
                //console.debug("Get news data from back end:" + JSON.stringify(data))
                data.forEach((e) => {
                    // let item: NewsData = {
                    //   news_id: e["news_id"] , 
                    //   img_url: e["img_url"],
                    //   title: e["title"],  
                    //   descriptions: e["descriptions"]
                    // } ;
                    e['img_url2'] = e['img_url'];
                    
                    this.videoList.push(e );
                    this.hasData = true;

                    this.currVideoId = "-1";
                });

                this.gridsource.localdata = this.videoList;
                //console.debug("FoodList: " + JSON.stringify(this.foodList));

                
                this.jqxgrid.updatebounddata();
            }, error:err => {
            console.error('Observer got an error: ' + err);
            alert('Lỗi kết nối mạng! ');
        }});
  }
  rowselect(event: any): void {
    // event arguments.
    let args = event.args;
    // selected row.
    let row = event.args.row;
    this.currVideoId = row.news_id;
  }
  createToolbar(statusbar: any): void {
    let buttonsContainer = document.createElement('div');
    buttonsContainer.style.cssText = 'overflow: hidden; position: relative; margin: 5px;';
    let addButtonContainer = document.createElement('div');
    let deleteButtonContainer = document.createElement('div');
    let reloadButtonContainer = document.createElement('div');
    let searchButtonContainer = document.createElement('div');
    addButtonContainer.id = 'addButton';
    deleteButtonContainer.id = 'deleteButton';
    reloadButtonContainer.id = 'reloadButton';
    searchButtonContainer.id = 'searchButton';
    addButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
    deleteButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
    reloadButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
    searchButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
    buttonsContainer.appendChild(addButtonContainer);
    buttonsContainer.appendChild(deleteButtonContainer);
    buttonsContainer.appendChild(reloadButtonContainer);
   // buttonsContainer.appendChild(searchButtonContainer);
    statusbar[0].appendChild(buttonsContainer);
  }
  imagerenderer = (row: number, datafield: string, value: string): string => {
    if (value == null || value == "") value="/assets/bp-acid-reflux-icon-fill.png";

    if (this.videoList[row]["news_type"]=="1") {
      return '<img style="margin-left: 5px;" height="50" width="100" src="' + value + '"/>';
    } else {
      return '<iframe width="100" height="50" src="' + value + '" frameborder="0" allowfullscreen></iframe>';
      // return '<div class="video"> \
      //   <video controls #videoPlayer> \
      //    <source src="' + value + '" " /> \
      //   Browser not supported \
      // </video> \
      // </div>';
    }
  }
  columns: any[] =
    [
      { text: 'ID', datafield: 'news_id', width: "5%" ,editable:false},
      { text: 'Ảnh đại diện', datafield: 'img_url', width: 120, cellsrenderer: this.imagerenderer ,editable:false},       
      { text: 'Tiêu đề', datafield: 'title' ,width: 280,editable:true, columntype: 'textarea',},
      { text: 'Mô tả', datafield: 'descriptions' ,width: 180,editable:true, columntype: 'textbox',} ,
      { text: 'link', datafield: 'link' ,width: 280,editable:true, columntype: 'textbox',} ,
      { text: 'Image link', datafield: 'img_url2' ,width: 280,editable:true, columntype: 'textbox',} ,
      { text: 'Tags', datafield: 'tags' ,width: 100,editable:true, columntype: 'textbox',} ,
      { text: 'Ngày tạo', datafield: 'created_date' ,width: 80,editable:false} ,
      { text: 'Ngày cập nhật', datafield: 'updated_date' ,width: 80,editable:false} ,
    ];
  gridsource: any = {
    datatype: "json",
    datafields: [
        { name: 'news_id', type: 'number' },
        { name: 'img_url', type: 'string' },   // for render    
        { name: 'img_url2', type: 'string' },  // for edit
        { name: 'title', type: 'string' },
        { name: 'descriptions', type: 'string' } ,
        { name: 'link', type: 'string' }  ,
        { name: 'tags', type: 'string' }  , 
        { name: 'created_date', type: 'string' }   , 
        { name: 'updated_date', type: 'string' } , 
    ],
    localdata: this.videoList,
    // updaterow: (rowid, rowdata, commit): void => {
    //     commit(true);
    // }
  };
  griddataAdapter: any = new jqx.dataAdapter(this.gridsource);
  createButtons(): void {
   
    let addButtonOptions = {
        width: 80, height: 25, value: 'Add',
        imgSrc: '/assets/media/icons/add.png',
        imgPosition: 'center', textPosition: 'center',
        textImageRelation: 'imageBeforeText'
    }
    let addButton = jqwidgets.createInstance('#addButton', 'jqxButton', addButtonOptions);
    let deleteButtonOptions = {
        width: 80, height: 25, value: 'Delete',
        imgSrc: '/assets/media/icons/close.png',
        imgPosition: 'center', textPosition: 'center',
        textImageRelation: 'imageBeforeText'
    }
    let deleteButton = jqwidgets.createInstance('#deleteButton', 'jqxButton', deleteButtonOptions);
    let reloadButtonOptions = {
        width: 80, height: 25, value: 'Reload',
        imgSrc: '/assets/media/icons/refresh.png',
        imgPosition: 'center', textPosition: 'center',
        textImageRelation: 'imageBeforeText'
    }
    let reloadButton = jqwidgets.createInstance('#reloadButton', 'jqxButton', reloadButtonOptions);
    let searchButtonOptions = {
        width: 80, height: 25, value: 'Find',
        imgSrc: '/assets/media/icons/search.png',
        imgPosition: 'center', textPosition: 'center',
        textImageRelation: 'imageBeforeText'
    }
   // let searchButton = jqwidgets.createInstance('#searchButton', 'jqxButton', searchButtonOptions);
    // add new row.
    addButton.addEventHandler('click', (event: any): void => {
        this.addNewItemCatId(this.currCatId);
    });
    // delete selected row.
    deleteButton.addEventHandler('click', (event: any): void => {
      let selectedrowindex = this.jqxgrid.getselectedrowindex();      
      let rowdata = this.jqxgrid.getrowdata(selectedrowindex);
      let id = this.jqxgrid.getrowid(selectedrowindex);
      
      this.openDialog("Xác nhận","Có chắc chắn muốn xóa mục  này?\n"+ rowdata["title"] + "(id=" + rowdata["news_id"] + ")").subscribe(result => {
        if (result=="1") {
          this.dbConnect.delEntryById("news",rowdata["news_id"]).subscribe({next:(data: []) => {
                 // alert("Ok, done!");
                  //this.jqxTree.refresh();
                   this.ngAfterViewInit();
                   //this.jqxTree.refresh();
                    
                  this.jqxgrid.refreshdata();
                  alert("Xóa dữ liệu thành công!");
                  this.dbConnect.sysLog('VideoListComponent','tree','Delete video: ' + rowdata["title"] + "(id=" + rowdata["news_id"] + ")");
                  //this.jqxgrid.deleterow(id);
                }, error:err => {
                  console.error('Observer got an error: ' + err);
                  alert('Lỗi kết nối mạng! ');
                }});                              
        }               
      });
        
        
    });
  
    // reload grid data.
    reloadButton.addEventHandler('click', (event: any): void => {
        //this.jqxgrid.source(this.getAdapter());
        this.reloadGridData(this.currCatId+"","");
        
    });
    // search for a record.
    // searchButton.addEventHandler('click', (event: any): void => {
    //     //  this.findWindow.open();
    //     //  this.findWindow.move(220, 120);
    // });
  }

  onCellEndEdit(event: any): void {
    
    let args = event.args;
    let columnDataField = args.datafield;
    let rowIndex = args.rowindex;
    let cellValue = args.value;
    let oldValue = args.oldvalue;

    if (rowIndex==="-1") return;
    
    let body :any ={};

    if (columnDataField==='img_url2') {  // Update img_url if changed
      columnDataField= 'img_url';
    }

    body[columnDataField] = cellValue;
    
    console.log(body);
    this.dbConnect.updateNews(
      this.videoList[parseInt(rowIndex)]["news_id"],
      body,
      ).subscribe({next:(result) => {
                  
         // alert('Cập nhật bài viết thành công ');
         // this._location.back();
         //window.location.reload();
         this.reloadGridData(this.currCatId+"","");
      
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối onCellEndEdit! ');
      }});
  }; 

  openDialog(title:string, msg: string): Observable<any>{
    const dialogRef = this.dialog.open(MsgDialogComponent, {
      width: '250px',
      data: {title, msg}
    });
    return dialogRef.afterClosed();
  }

}
