<div class="container-lg">
    <h4 class="text-primary">Danh sách người dùng</h4>

  <div class="row">    
    
    <mat-form-field>
      <mat-label>Lọc tìm kiếm</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Lọc theo">
    </mat-form-field>

    <a routerLink="/user-info/addNew" *ngIf="isSysAdmin"><button type="submit" class="btn btn-primary" ><i class="fa fa-check"></i> Add new User</button> </a><br>
    <table mat-table [dataSource]="dataSource" class="table table-bordred table-striped" matSort multiTemplateDataRows>

      <!-- Position Column -->
      <ng-container matColumnDef="idx">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Số thứ tự </th>
        <td mat-cell *matCellDef="let element"> {{element.idx}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Trạng thái </th>
        <td mat-cell *matCellDef="let element">
          <i *ngIf="!element.blocked" class="fa fa-check text-success"></i> 
          <i *ngIf="element.blocked" class="fa fa-bolt text-danger"></i>                       
        </td>
      </ng-container>
  
      
       <ng-container matColumnDef="user_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> User ID</th>
        <td mat-cell *matCellDef="let element"> {{element.user_id}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> User name </th>
        <td mat-cell *matCellDef="let element"> 
          <img *ngIf="element.avata_img64" class="avatarimg"  src="data:image/jpeg;base64,{{element.avata_img64}}" alt="Người dùng"/>
          &nbsp;{{element.user_name}} 
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>

      <ng-container matColumnDef="telephone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Telephone </th>
        <td mat-cell *matCellDef="let element"> {{element.telephone}} </td>
      </ng-container>
      <ng-container matColumnDef="webAppRoles">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Roles </th>
        <td mat-cell *matCellDef="let element"> {{element.roles}} </td>
      </ng-container>

      <ng-container matColumnDef="blocked">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Blocked </th>
        <td mat-cell *matCellDef="let element"> {{element.blocked}} </td>
      </ng-container>
      
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="8">
          
          <div class="example-element-detail"
               [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">          
               <div class="container">                
               <div class="row">
                <div class="col-sm-6">
                  Username : {{element.user_name}} &nbsp;<br>                  
                  Email : {{element.email}}  &nbsp;<br>  
                  Telephone : {{element.telephone}} &nbsp;<br>
                  Roles : {{element.roles}} &nbsp;<br>
                  Blocked : {{element.blocked}} &nbsp;<br>
                  
              </div>            
              <div class="col-sm-6">
                <a routerLink="/user-info/{{element.user_id}}"><button type="submit" class="btn btn-primary" ><i class="fa fa-check"></i> Update</button> </a><br>                
                <a routerLink="/change-pass/{{element.user_id}}"><button mat-raised-button class="btn btn-warning"><i class="fa fa-key"></i> Change Password</button></a><br> 
                <button mat-raised-button  class="btn btn-danger"  (click)="onDeleteUser(element.user_id)">Xoa user</button>
              </div>
               </div>
            </div>
          </div>

        </td>
      </ng-container>

   
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <!-- tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr -->

      <tr mat-row *matRowDef="let element; columns: displayedColumns;"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

    </table>
  
    <mat-paginator [pageSizeOptions]="[10,20,50]" showFirstLastButtons></mat-paginator>
  
</div>
</div>

